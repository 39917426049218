import { returnScrollTopPosition, saveScrollTop } from './scrollBox';

import { filterFeedItems, showAllFeedItems } from 'store/actions/feed';
import { messageFiltersActions } from 'store/slices/messageFiltersSlice';

import { filterMessagesHelper } from 'helpers/filterMessages';

export function setActiveLeftMenu(activeMenu) {
  return (dispatch) => {
    dispatch(messageFiltersActions.setActiveLeftMenuSuccess({ activeMenu }));
    dispatch(executeFilterMessages());
  };
}

export function executeFilterMessages(scrollBoxRef) {
  return (dispatch, getState) => {
    const { searchConditions, activeMenu } = getState().messageFilters;
    const { currentTab, ...feedState } = getState().feed;
    const messages = feedState[currentTab]?.items;

    if (activeMenu === 'search' || activeMenu === 'favorite') {
      if (activeMenu === 'favorite') dispatch(saveScrollTop(scrollBoxRef));
      // else if (activeMenu === 'search') dispatch(returnScrollTopPosition(scrollBoxRef));
      // else if (activeMenu === 'search') dispatch(returnScrollTopPosition(null));

      const toShow = getFilteredMessages(activeMenu, searchConditions, messages);
      dispatch(filterFeedItems(toShow));
    } else if (activeMenu === 'socials') {
      return;
    } else {
      dispatch(showAllFeedItems());
      dispatch(returnScrollTopPosition(scrollBoxRef));
    }
  };
}

// dont  save in search mode
// dont on change from fav to search
export function setSearchConditions(field, value, scrollBoxRef) {
  return (dispatch, getState) => {
    const { searchConditions, activeMenu } = getState().messageFilters;
    const { currentTab, ...feedState } = getState().feed;
    const messages = feedState[currentTab]?.items;
    if (field) dispatch(messageFiltersActions.setSearchConditionsSuccess({ field, value }));

    const searchConditionsCopy = { ...searchConditions };
    if (field) searchConditionsCopy[field] = value;
    const toShow = getFilteredMessages(activeMenu, searchConditionsCopy, messages);
    // if (toShow?.length < messages?.length) dispatch(saveScrollTop(scrollBoxRef));
    // else dispatch(returnScrollTopPosition(scrollBoxRef));
    dispatch(filterFeedItems(toShow));
  };
}

export function getFilteredMessages(activeMenu, searchConditions, messages) {
  const toShow = [...(messages || [])];
  if (activeMenu === 'favorite') {
    return toShow.filter((el) => !!el.favorite).map((el) => el.id);
  } else if (activeMenu === 'search') {
    return filterMessagesHelper(searchConditions, toShow);
  } else return messages.map((el) => el.id);
}
