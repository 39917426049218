import AddNewInteractiveMenu from './AddNewInteractiveMenu/AddNewInteractiveMenu';
import PropTypes from 'prop-types';
import React, { memo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Avatar, Button, Icon, Menu, SearchInput } from 'components';

import { getIconImage } from 'helpers/image';
import { goTo } from 'helpers/routing';

import styles from './Toolbar.module.css';

const propTypes = {
  createSession: PropTypes.func,
  refreshSessions: PropTypes.func,
  removeSelectedSessions: PropTypes.func,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  isFiltered: PropTypes.bool,
  interactives: PropTypes.array,
  setInteractives: PropTypes.func,
  copySession: PropTypes.func,
  selectedSessionsLength: PropTypes.number
};

const defaulProps = {
  searchText: '',
  isFiltered: false,
  interactives: []
};

function Toolbar({
  createSession,
  refreshSessions,
  removeSelectedSessions,
  searchText,
  setSearchText,
  isFiltered,
  interactives,
  setInteractives,
  copySession,
  selectedSessionsLength,
  enableInteractives,
  // Guide
  setStepIndex,
  interactiveToolbarRef,
  interactiveSearchMenuRef,
  interactiveSearchBtnRef
}) {
  const history = useHistory();
  const location = useLocation();
  const toolbarRef = useRef(null);

  // Guide
  interactiveToolbarRef.current = toolbarRef.current;

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isAddInteractiveOpened, setIsAddInteractiveOpened] = useState(false);
  const [addInteractivePosition, setAddInteractivePosition] = useState({
    left: 0,
    top: 0
  });

  const toggleAddInteractive = (btn) => {
    if (!isAddInteractiveOpened) {
      const btnRect = btn.getBoundingClientRect();
      setAddInteractivePosition({
        left: `${btnRect.left}px`,
        top: `${btnRect.top + 35}px`
      });
    }
    setIsAddInteractiveOpened(!isAddInteractiveOpened);
  };

  const addBtn = (
    <Button
      startIcon={<Icon size={20} fontName="fal fa-plus" />}
      className="js_add-interactive"
      onlyIcon
      variant="icon"
      color="#57adf2"
      clickHandler={(event) => {
        toggleAddInteractive(event.target);
      }}
      title={window.t('addInteractive')}
      style={{ padding: '6px 8px' }}
    />
  );

  const height = isFilterActive && toolbarRef.current ? toolbarRef.current.scrollHeight : 44;
  const isCopyDisabled = selectedSessionsLength !== 1;

  return (
    <div ref={toolbarRef} className={styles.toolbar} style={{ height: `${height}px` }}>
      <ul className={styles['sessions-tbar']}>
        <Button
          startIcon={<Icon size={26} fontName="fal fa-chevron-left" />}
          onlyIcon
          variant="icon"
          color="#57adf2"
          clickHandler={() => {
            // if (history.length === 2) goTo(history, "/");
            // else history.goBack();

            history.length <= 2 || !location.state?.prevPath
              ? goTo(history, '/', null, location)
              : history.push({
                  pathname: location.state.prevPath
                });
          }}
          style={{ padding: '3px 10px', margin: '0 3px' }}
        />
        <li className={`${styles.title} ${styles['st__item']}`}>{window.t('interactives')}</li>
        <Menu
          activator={addBtn}
          activatorHandler={toggleAddInteractive}
          isActive={isAddInteractiveOpened}
          left={addInteractivePosition ? addInteractivePosition.left : 0}
          top={addInteractivePosition ? addInteractivePosition.top : 0}>
          <AddNewInteractiveMenu
            interactives={interactives}
            toggleAddInteractive={toggleAddInteractive}
            createSession={(type) => createSession(type)}
          />
        </Menu>

        <Button
          startIcon={<Icon size={20} fontName="fal fa-minus" />}
          className="js_remove-interactive"
          onlyIcon
          variant="icon"
          color="#57adf2"
          clickHandler={removeSelectedSessions}
          title={window.t('deleteInteractive')}
          style={{ padding: '6px 8px' }}
        />
        <Button
          startIcon={<Icon size={16} fontName="far fa-sync-alt" />}
          onlyIcon
          variant="icon"
          color="#57adf2"
          clickHandler={() => {
            refreshSessions();
            setSearchText('');
            setIsSearchActive(false);
            setIsFilterActive(false);
          }}
          title={window.t('refreshList')}
          style={{ padding: '6px 8px' }}
        />
        <Button
          startIcon={<Icon size={20} fontName="fas fa-clone" />}
          onlyIcon
          variant="icon"
          color="#57adf2"
          clickHandler={copySession}
          title={isCopyDisabled ? window.t('сhooseInteractiveToCopy') : window.t('copyInteractive')}
          style={{ padding: '6px 8px' }}
          isDisabled={isCopyDisabled}
        />
        <SearchInput
          interactiveSearchBtnRef={interactiveSearchBtnRef}
          isActive={isSearchActive}
          isFiltered={isFiltered}
          searchText={searchText}
          toggleSearchActivity={(value) => {
            setIsSearchActive(value);
            setStepIndex((v) => ++v);
          }}
          changeHandler={(value) => setSearchText(value)}
          onFocusHandler={() => setIsFilterActive(true)}
          styles={{ marginLeft: 'auto' }}
          width="30%"
        />
      </ul>
      <ul ref={interactiveSearchMenuRef} className={`${styles['ms__selected-interactives']} mt-2`}>
        {interactives.map(({ type, isActive, img }, i) => {
          return (
            <Button
              startIcon={
                <Avatar
                  title={window.t(type)}
                  src={getIconImage(img)}
                  size={25}
                  alt={window.t(type)}
                />
              }
              clickHandler={() => {
                setInteractives((interactives) =>
                  interactives.map((el) => (el.type === type ? { ...el, isActive: !isActive } : el))
                );
              }}
              variant="text"
              color={isActive ? '#57ADF2' : '#83888e'}
              style={{ fontSize: 15 }}
              key={type + i}>
              {window.t(type)}
            </Button>
          );
        })}
      </ul>
      <Button style={{ float: 'right' }} clickHandler={() => setIsFilterActive(false)}>
        {window.t('close')}
      </Button>
    </div>
  );
}

export default memo(Toolbar);

Toolbar.propTypes = propTypes;
Toolbar.defaulProps = defaulProps;
