import { useSelector } from 'react-redux';

// import { Icon } from 'components';
import styles from './SearchStatistics.module.css';

function SearchStatistics() {
  const { authors, messages } = useSelector((state) => state.searchStatistics);

  return (
    <div className={styles.statisticsBlock}>
      <div className={styles.statisticsItem}>
        <span>{window.t('authors')}:</span>
        {/* <Icon size={16} fontName="fad fa-solid fa-user" /> */}
        <div className={styles.count}>{authors !== null && authors?.length}</div>
      </div>

      <div className={styles.statisticsItem}>
        <span>{window.t('messagesStat')}:</span>
        {/* <Icon size={16} fontName="fad fa-solid fa-envelope" /> */}
        <div className={styles.count}>{messages !== null && messages}</div>
      </div>
    </div>
  );
}

export default SearchStatistics;
