import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { Button, Chip, FileItem, Icon, Input, InputFile } from 'components';

import useActions from 'hooks/useActions';

import { ResponseTypes } from 'store/constants';

import styles from './FeedbackInput.module.css';

function FeedbackInput({
  onAnimationStart,
  duration = 700,
  emojiPickerOffset,
  id,
  senderNumber,
  channel,
  isActive = true,
  handleSend,
  setIsActive,
  quickReplies
}) {
  const { sendResponseMessage, addSnack } = useActions();
  const [text, setText] = useState('');
  const [messageFiles, setMessageFiles] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isActive && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 700);
    }
  }, [isActive]);

  const inputFileChangeHandler = (e) => {
    if (Object.values(e.target.files).some((file) => file.size > 20971520)) {
      addSnack({
        type: 'danger',
        message: 'Размер файла не долже превышать 20мб!'
      });
      return;
    }
    const newMessageFiles = [
      ...messageFiles,
      ...Object.values(e.target.files).filter(
        (file) => !messageFiles.find((item) => file.name === item.name && file.size === item.size)
      )
    ];
    if (newMessageFiles.length > 5) {
      addSnack({
        type: 'danger',
        message: 'Максимальное количество файлов должно быть не больше 5!'
      });
      return;
    }
    setMessageFiles(newMessageFiles);
  };

  const removeFileItemHandler = (file) => {
    setMessageFiles((files) => files.filter((el) => el.name !== file.name));
  };

  const handleChange = useCallback((e) => {
    setText(e.target.value);
  }, []);

  const send = (e) => {
    // handleSend?.(text);
    sendResponseMessage(
      {
        id,
        senderNumber,
        channel,
        attachment: messageFiles
      },
      {
        text,
        type: ResponseTypes.message
      }
    );
    setText('');
    setMessageFiles([]);
    setIsActive?.(false);
  };

  const handleEmojiClick = useCallback((emoji) => {
    if (emoji) {
      setText((value) => value + emoji);
      inputRef.current?.focus?.();
    }
  }, []);

  return (
    <AnimateHeight
      onAnimationStart={onAnimationStart}
      duration={duration}
      height={isActive ? 'auto' : 0}>
      <div className={classNames(styles['feedback-input'], styles[`chan-${channel?.trim()}`])}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          <div className={styles.form}>
            <div className={styles.form__inputContainer}>
              <Input
                ref={inputRef}
                value={text}
                changeHandler={handleChange}
                focusable={false}
                emojiPickerOffset={emojiPickerOffset}
                handleEmojiClick={handleEmojiClick}
                withEmojiPicker
                emoji={isActive ?? false}
              />
            </div>
            <InputFile
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#524e4e',
                opacity: 0.33,
                padding: '5px 5px'
              }}
              changeHandler={inputFileChangeHandler}
              multiple>
              <Icon size={24} fontName="fas fa-paperclip" />
            </InputFile>
            <Button
              startIcon={<Icon size={24} fontName="fas fa-paper-plane" />}
              onlyIcon
              color="#524e4e"
              variant="message-action"
              clickHandler={send}
            />
          </div>
        </form>
        {messageFiles.length > 0 && (
          <div className={styles.messageFiles}>
            {messageFiles.map((file) => (
              <FileItem
                key={file.name}
                file={file}
                type="small"
                withRemoveBtn
                removeHandler={() => removeFileItemHandler(file)}
              />
            ))}
          </div>
        )}
        <div className={classNames('d-flex align-start', styles.pickers)}>
          <div className={styles['quick-answers']}>
            {quickReplies?.map((el) => (
              <Chip
                key={el.id}
                radius={8}
                fontSize={12}
                height={28}
                clickHandler={() => {
                  setText((v) => `${v}${el.additional_text} `);
                  if (inputRef && inputRef?.current) {
                    setTimeout(() => {
                      inputRef.current.setSelectionRange(
                        inputRef.current.value.length,
                        inputRef.current.value.length
                      );
                      inputRef.current.focus();
                    }, 0);
                  }
                }}
                label={el.additional_text}
              />
            ))}
          </div>
        </div>

        <div className={styles.filesInfo}>{window.t('filesInfo')}</div>
      </div>
    </AnimateHeight>
  );
}

export default FeedbackInput;
