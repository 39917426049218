import { sub } from 'date-fns';

export function filterMessagesHelper(searchConditions, messages) {
  let {
    text,
    timeType,
    hoursAndMinutesStart,
    hoursAndMinutesEnd,
    timeStart,
    timeEnd,
    label,
    media,
    channel
  } = searchConditions;

  if (text && text.length >= 3) messages = filterByText(text, messages);

  if (timeType && timeType !== 'period') messages = filterByTimeType(timeType, messages);
  else if (
    timeType === 'period' &&
    timeStart &&
    timeEnd &&
    hoursAndMinutesStart &&
    hoursAndMinutesEnd
  ) {
  }
  // messages = filterByTimePeriod(
  //   hoursAndMinutesStart,
  //   hoursAndMinutesEnd,
  //   timeStart,
  //   timeEnd,
  //   messages
  // );
  if (label) messages = filterByLabel(label, messages);

  if (media) messages = filterByMedia(media, messages);

  if (channel) messages = filterByChannel(channel, messages);

  return messages.map((el) => el.id);
}

function filterByText(text, messages) {
  const regPhrase = new RegExp(text, 'i');
  return messages.filter(
    (el) => regPhrase.test(el.content || el.title) || regPhrase.test(el.content || el.description)
  );
}

function filterByTimeType(timeType, messages) {
  const getAddObj = (timeType) => {
    switch (timeType) {
      case '1h':
        return {
          hours: 1
        };
      case '6h':
        return {
          hours: 6
        };
      case '1d':
        return {
          days: 1
        };
      case '2d':
        return {
          days: 2
        };
      case '1w':
        return {
          weeks: 1
        };
      case '1m':
        return {
          months: 1
        };
      default:
        return {};
    }
  };
  const start = +sub(new Date(), getAddObj(timeType));
  const finish = +new Date();
  return messages.filter(
    (el) => +new Date(el.date || el.pubDate) >= start && +new Date(el.date || el.pubDate) <= finish
  );
}

export function filterByTimePeriod(
  hoursAndMinutesStart,
  hoursAndMinutesEnd,
  timeStart,
  timeEnd,
  messages
) {
  const start = +new Date(`${timeStart} ${hoursAndMinutesStart}:00`);
  const finish = +new Date(`${timeEnd} ${hoursAndMinutesEnd}:59`);

  return messages.filter(
    (el) =>
      Date.parse(`${el.date || el.pubDate}+03:00`) >= start &&
      Date.parse(`${el.date || el.pubDate}+03:00`) <= finish
  );
}

function filterByLabel(label, messages) {
  if (label === 'any') return messages.filter((el) => el.labels && el.labels.length > 0);
  return messages.filter((el) => el.labels && el.labels.length > 0 && el.labels.includes(label));
}

function filterByMedia(media, messages) {
  if (media === 'any') return messages.filter((el) => el.attachments && el.attachments.length > 0);
  return messages.filter((el) => {
    let attachments = el.enclosure?.['@attributes'];
    if (attachments && !Array.isArray(attachments)) attachments = [attachments];
    return (
      (attachments && attachments.length > 0 && attachments[0].type?.includes(media)) ||
      (el.attachments && el.attachments.length > 0 && el.attachments[0].type?.includes(media))
    );
  });
}

function filterByChannel(channel, messages) {
  return messages.filter((el) => el.channel === channel);
}
