import SessionRow from '../SessionRow/SessionRow';
import { memo } from 'react';

import { useActions } from 'hooks';

import styles from './PinInteractivesList.module.css';

function PinInteractivesList({
  togglePinHandler,
  selectSessionCb,
  openSession,
  selectedSessions,
  listPinnedInteractives
}) {
  const { setPinnedInteractives, setPinnedInteractivesThunk } = useActions();

  const dragStartHandler = (evt) => {
    evt.target.classList.add(`selectedPin`);
  };
  const dragEndHandler = (evt) => {
    evt.target.classList.remove(`selectedPin`);

    const arrOrder = [...evt.currentTarget.childNodes].map((el) => ({
      id: el.id,
      type: el.dataset.type
    }));

    setPinnedInteractives(arrOrder);
    setPinnedInteractivesThunk({ pinnedInteractives: arrOrder });
  };
  const dragOverHandler = (evt) => {
    evt.preventDefault();
    const activeElement = evt.currentTarget.querySelector(`.selectedPin`);
    const currentElement = evt.target;
    const isMoveable = activeElement !== currentElement && currentElement.localName === 'li';
    if (!isMoveable) {
      return;
    }

    const nextElement =
      currentElement === activeElement.nextElementSibling
        ? currentElement.nextElementSibling
        : currentElement;

    evt.currentTarget.insertBefore(activeElement, nextElement);
  };

  return (
    <ul
      className={styles.pinnedList}
      onDragStart={dragStartHandler}
      onDragEnd={dragEndHandler}
      onDragOver={dragOverHandler}>
      {listPinnedInteractives?.length !== 0 &&
        listPinnedInteractives?.map((el) => {
          if (el) {
            return (
              <SessionRow
                isSelected={selectedSessions.includes(`${el.type}_${el.id}`)}
                selectSessionCb={selectSessionCb}
                openSession={openSession}
                rowData={el}
                togglePinHandler={togglePinHandler}
                key={`${el.type}_${el.id}_pin`}
                isPinnedInteractive={true}
              />
            );
          }
        })}
    </ul>
  );
}

export default memo(PinInteractivesList);
