import { hostname, socketToken1 } from './constants';
import { format } from 'date-fns';
import merge from 'lodash/merge';

import { TABS } from 'containers/Feed/constants';

import { addChatMessages } from 'store/actions/chat';
import {
  addResponseMessage,
  removeFeedItem,
  setFeedItemHiddenState,
  setFeedItemLikeStatus,
  setFeedItemMinimizedStatus
} from 'store/actions/feed';
import { updateInfoField } from 'store/actions/isettings';
import { setPinnedAuthors } from 'store/actions/pinnedAuthors';
import { editProfile } from 'store/actions/profile';
import {
  createSessionSuccess,
  createSessionTempSuccess,
  removeSessions,
  updateSessionInfo
} from 'store/actions/sessions';
import { setAuthors } from 'store/actions/settings';
import { INTERACTIVE_ACTIVITIES } from 'store/constants';
import { broadcastActions } from 'store/slices/broadcastSlice';
import { registrationActions } from 'store/slices/registrationSlise';
import { voteActions } from 'store/slices/voteSlice';
import {
  addSocketMessage,
  clearTitre,
  exportMessageSuccessThunk,
  fetchBroadcastMessages,
  updateRemoveBroadcastMessage,
  updateStartBroadcast,
  updateStopBroadcast
} from 'store/thunks/broadcast';
import { addLabelSocket, setLabelsSocket, setSocketFavState } from 'store/thunks/feed';
import { setISettingsInfo, setISettingsInfoFromSession } from 'store/thunks/isettings';
import { updateCurrentProfileSettings } from 'store/thunks/profile';
import {
  addParticipantCard,
  updateStartRegistr,
  updateStopRegistr
} from 'store/thunks/registration';
import { setSettings } from 'store/thunks/settings';
import { addSnack } from 'store/thunks/snackBox';
import { calcVotes, fetchVoteVariants, updateStartVote, updateStopVote } from 'store/thunks/vote';

import { clearAndParseJSON } from 'helpers/text';

export let webSocket;

const startSocket = (dispatch, getState, socketPort) => {
  if (!socketPort) {
    console.error(`Переменной ${socketPort} не существует`);
    return false;
  }

  switch (window.location.protocol) {
    case 'http:':
      webSocket = new WebSocket(`ws://212.8.247.80:${socketPort}/`);
      break;
    case 'https:':
      webSocket = new WebSocket('wss://synhro.iactive.pro/wss/');
      break;
    default:
      throw new Error('???');
  }

  // webSocket = new WebSocket('wss://synhro.iactive.pro:' + socketPort + '/');

  webSocket.onopen = function () {
    console.log('Соединение с сокет-сервером Установленно');
  };

  webSocket.onclose = function () {
    console.log('Соединение с сокет-сервером Закрыто');
    console.log('Повторное подключение...');
    setTimeout(() => startSocket(dispatch, getState, socketPort), 500);
  };

  webSocket.onmessage = function (data) {
    try {
      data = JSON.parse(data.data);
      // console.log({ data });
    } catch (e) {
      console.log({ 'info:': e, data: data.data });
      return;
    }

    const sTok = data.soсketToken ? data.soсketToken.split(':') : [];

    // window.location.hostname
    if (data.soсketToken && socketToken1 !== sTok[0] && hostname === sTok[1]) {
      switch (data.actionName) {
        case 'messagerSend': {
          switch (data.param2) {
            case 'like': {
              // todo: pass a senderNumber as third

              const senderNumber = null;
              const updatedFromSocket = true;
              dispatch(
                setFeedItemLikeStatus(
                  data.param1,
                  data.param3,
                  senderNumber,
                  updatedFromSocket,
                  TABS.messages
                )
              );
              return;
            }
            case 'message': {
              // todo: pass a senderNumber as third

              const senderNumber = null;
              const updatedFromSocket = true;
              dispatch(
                addResponseMessage(
                  data.param1,
                  {
                    out_message: data.param3,
                    id: Math.random()
                  },
                  senderNumber,
                  updatedFromSocket,
                  TABS.messages
                )
              );
              dispatch(
                addChatMessages(data.param1, [
                  {
                    content: data.param3,
                    date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
                    id: Math.random(),
                    own: 1,
                    isNew: true
                  }
                ])
              );
              return;
            }
            default:
              throw new Error('unknown action');
          }
        }
        case 'messageDelete': {
          // todo pass senderNumber to both

          const senderNumber = null;
          const updatedFromSocket = true;
          dispatch(
            setFeedItemHiddenState(
              data.param1,
              true,
              senderNumber,
              updatedFromSocket,
              TABS.messages
            )
          );
          dispatch(removeFeedItem(data.param1, senderNumber, updatedFromSocket, TABS.messages));
          break;
        }
        case 'profileEdit': {
          const {
            settings,
            profile: { profiles, currentProfile }
          } = getState();
          const json = clearAndParseJSON(data.param3);

          if (data.param1 === currentProfile.id) {
            if (data.param2 === 'settings') {
              if (Object.keys(json).includes('authors')) {
                const updatedFromSocket = true;
                dispatch(setAuthors(json.authors, updatedFromSocket));
              } else if (Object.keys(json).includes('pinnedInteractives')) {
                dispatch(
                  setSettings({ ...settings, pinnedInteractives: json['pinnedInteractives'] })
                );
              } else {
                dispatch(setSettings(merge(settings, json)));
              }
            }
            dispatch(updateCurrentProfileSettings());
          } else {
            editProfile({
              id: data.param1,
              [data.param2]: merge(
                profiles.find((profile) => profile.id === data.param1)?.[data.param2],
                json
              )
            });
          }

          break;
        }
        case 'InteractiveDelete': {
          const { sessions } = getState().sessions;
          if (!Array.isArray(sessions) || sessions.length === 0) return;

          const idsToDel = data.param1.split(',').map((el) => el.toString());

          dispatch(removeSessions(idsToDel));
          break;
        }
        case 'contentCopy':
        case 'broadcastCopy':
        case 'voteCopy':
        case 'quizCopy':
        case 'QuizCreate':
        case 'contentCreate':
        case 'BroadcastCreate':
        case 'VoteCreate': {
          const { isLoading, isSetSessions } = getState().sessions;
          const type = data.param1;
          const id = data.param2;
          const title = data.param3;
          const broadcastId = data.other.broadcastId;

          if (isLoading) {
            dispatch(
              createSessionTempSuccess({
                id,
                type,
                title,
                description: '',
                memo: '',
                ...((data.actionName.toLowerCase().includes('vote') ||
                  data.actionName.toLowerCase().includes('quiz')) && {
                  votes_count: 0,
                  broadcastId
                }),
                ...(data.actionName.toLowerCase().includes('vote') && {
                  vars: data.other[0]
                }),
                ...(data.actionName.toLowerCase().includes('broadcast') && {
                  message_count: 0
                }),
                ...(data.actionName.includes('content') && {
                  type: 'content'
                }),
                isHidden: false
              })
            );
          } else {
            if (!isSetSessions) {
              break;
            }
            dispatch(
              createSessionSuccess({
                id,
                type,
                title,
                description: '',
                memo: '',
                ...((data.actionName.toLowerCase().includes('vote') ||
                  data.actionName.toLowerCase().includes('quiz')) && {
                  votes_count: 0,
                  broadcastId
                }),
                ...(data.actionName.toLowerCase().includes('vote') && {
                  vars: data.other[0]
                }),
                ...(data.actionName.toLowerCase().includes('broadcast') && {
                  message_count: 0
                }),
                ...(data.actionName.includes('content') && {
                  type: 'content'
                }),
                isHidden: false
              })
            );
          }
          break;
        }
        case 'BroadcastEdit': {
          const { info } = getState().isettings;
          const {
            id,
            userName,
            socketPort,
            socketToken,
            token,
            state,
            settings,
            ...changedParams
          } = data.other;

          if (id !== info.id && id !== info.broadcastId) return;

          const newInfo = {
            ...info,
            id,
            ...changedParams,
            ...(settings && { settings: clearAndParseJSON(settings) }),
            ...(state && { state: clearAndParseJSON(state) })
          };

          if (newInfo.activity === INTERACTIVE_ACTIVITIES.active) {
            dispatch(
              broadcastActions.updateActiveBroadcastField({
                field: 'settings',
                value: newInfo.settings
              })
            );
          }

          dispatch(updateSessionInfo(newInfo));
          dispatch(setISettingsInfo(newInfo));
          break;
        }
        case 'VoteEdit': {
          const { info } = getState().isettings;
          const {
            id,
            userName,
            socketPort,
            socketToken,
            token,
            state,
            settings,
            rangeNumbers,
            ...changedParams
          } = data.other;

          if (id !== info.id && id !== info.broadcastId) return;

          const newInfo = {
            ...info,
            id,
            ...changedParams,
            ...(rangeNumbers && { tags: clearAndParseJSON(rangeNumbers) }),
            ...(settings && { settings: clearAndParseJSON(settings) }),
            ...(state && { state: clearAndParseJSON(state) })
          };

          dispatch(updateSessionInfo(newInfo));
          dispatch(setISettingsInfo(newInfo));
          break;
        }
        case 'iactivesEditRangeNumbers': {
          const { info } = getState().isettings;
          if (data.param1 != info.id) return;

          dispatch(updateInfoField('rangeNumbers', clearAndParseJSON(data.param2)));
          break;
        }
        case 'voteSetResult': {
          const { info } = getState().isettings;
          if (data.param1 != info.id) return;

          dispatch(updateInfoField('result', data.param2));
          break;
        }
        case 'voteVarsDeleteimg':
        case 'voteVarsLoadimg': {
          const { info } = getState().isettings;
          if (data.param1 != info.id) return;
          if (data.param2) {
            dispatch(
              voteActions.updateVariantField({
                varnum: data.param2,
                field: 'var_img_url',
                value: data.param3
              })
            );
          } else {
            dispatch(updateInfoField('question_img_url', data.param3));
          }
          break;
        }
        case 'voteCancel': {
          const { info } = getState().isettings;
          if (data.param1 != info.id) return;
          // dispatch(clearTitreSuccess(null));
          const variants = [...getState().vote.variants].map((variant) => ({
            ...variant,
            count: 0,
            percent: 0
          }));
          dispatch(voteActions.setTotalVoteCount({ count: 0 }));
          dispatch(voteActions.fetchVoteVariantsSuccess({ variants }));
          if (info.broadcastId)
            dispatch(broadcastActions.fetchBroadcastMessagesSuccess({ messages: [] }));
          dispatch(
            addSnack({
              type: 'success',
              message: window.t('sccStatisticsCleared')
            })
          );
          break;
        }
        case 'voteAddVote': {
          const { info } = getState().isettings;
          if (data.param1 != info.id) return;

          const varnum = data.param2;
          const variants = Array.from(getState().vote.variants);
          const index = variants.findIndex((el) => el.varnum == varnum);
          variants[index].count = +variants[index].count + 1;

          const [percentList, countAll] = calcVotes(variants);

          dispatch(voteActions.setTotalVoteCount({ count: countAll }));

          variants.forEach((el, i) => {
            el.percent = percentList[i];
          });
          dispatch(voteActions.fetchVoteVariantsSuccess({ variants }));
          break;
        }
        case 'VoteStart': {
          dispatch(updateStartVote(data.param1));
          break;
        }
        case 'VoteStop': {
          dispatch(updateStopVote(data.param1));
          break;
        }
        case 'BroadcastStart': {
          const broadcastId = data.param1;
          const settings = data.param2;
          // setISettingsInfoFromSession(broadcastId, INTERACTIVES.broadcast);
          dispatch(updateStartBroadcast(broadcastId, undefined, undefined, settings));
          break;
        }
        case 'BroadcastStop': {
          const broadcastId = data.param1;
          dispatch(updateStopBroadcast(broadcastId));
          break;
        }
        case 'DeleteAllMessages':
          const { broadcastId } = getState().broadcast;
          if (data.param2 == broadcastId) {
            dispatch(clearTitre(null, broadcastId));
            dispatch(broadcastActions.fetchBroadcastMessagesSuccess({ messages: [] }));
          }
          break;
        case 'FillBroadcast': {
          const { info } = getState().isettings;
          const id = data.param1;

          if (id !== info.id && id !== info.broadcastId) return;

          const currentIdBroadcast = info.broadcastId || info.id;
          if (id == currentIdBroadcast) {
            dispatch(fetchBroadcastMessages(id));
          }

          break;
        }
        case 'VoteVarUpdate': {
          const { id } = data.other;

          const { info } = getState().isettings;
          if (id != info.id) return;
          dispatch(fetchVoteVariants(+id));
          break;
        }
        case 'broadcastsTitreClear': {
          const titreTag = data.param1;
          const broadcastId = data.param2;
          dispatch(broadcastActions.clearTitreSuccess({ titreTag, broadcastId }));
          break;
        }

        case 'broadcastsTitreRemove': {
          const id = data.param1;
          const key = data.param2;
          const messageFeedId = data.other.msgId;
          dispatch(
            broadcastActions.delFromTitrSuccess({
              id,
              messageFeedId,
              exportSideKey: key
            })
          );
          break;
        }

        case 'broadcastsTitreAdd': {
          const id = data.param1;
          const interactiveId = data.param2;
          const exportSideKey = data.param3;
          const messageFeedId = data.other.msgId;

          dispatch(exportMessageSuccessThunk(id, messageFeedId, exportSideKey, interactiveId));
          break;
        }
        case 'iactivesEdit': {
          const broadcastId = data.param1;
          const typeInteractive = data.param2;
          const text = data.param3;
          dispatch(updateInfoField('interactiveName', text));
          break;
        }

        // case 'VoteVarUpdate':
        case 'VoteVarDelete': {
          // actionName: "VoteVarDelete"
          // other: []
          // param1: "3277"
          // param2: "3"
          const { info } = getState().isettings;
          if (data.param1 != info.id) return;

          dispatch(fetchVoteVariants(+data.param1));
          break;
        }
        case 'VoteVarCreate': {
          // actionName: "VoteVarCreate"
          // other:
          // comment: "Комментарий"
          // id: "3277"
          // name: "Вариант3"
          // num: "3"
          // socketPort: "8001"
          // socketToken: "ls9opxe88bpctssip75o:localhost"
          // token: "000000000000000000"
          // userName: "+79619002008"
          const { id } = data.other;

          const { info } = getState().isettings;
          if (id != info.id) return;
          dispatch(fetchVoteVariants(+id));
          break;
        }
        case 'AddMessage': {
          // Добавление сообщения в ленту
          // actionName: "AddMessage"
          // other: []
          // param1: "10782"
          // param2: "5436"
          // param3: ""
          const { info } = getState().isettings;
          const idBroadcastCheck = info.broadcastId;
          if (data.param2 != info.id && data.param2 != idBroadcastCheck) return;

          dispatch(addSocketMessage(data.param2, data.param1));
          break;
        }
        case 'DeleteMessage': {
          // if (data.param1.length > 4 && data.param2.length === 0) {
          //   const id = data.param1;
          //   let elem = getById(id);
          //   elem.remove();
          // } else {
          //   const bSocket = new BroadcastSocket();
          //   bSocket.deleteMessage(data);
          // }
          const { info } = getState().isettings;

          if (data.param2 != info.id && data.param2 != info.broadcastId) return;

          dispatch(updateRemoveBroadcastMessage(data.param1));
          break;
        }
        case 'EditMessage': {
          const { info } = getState().isettings;

          if (data.param2 !== info.broadcastId && data.param2 !== info.id) return;

          dispatch(
            broadcastActions.updateMessageText({
              id: data.param1,
              text: data.other.content
            })
          );
          break;
        }
        case 'RestoreMessage': {
          // const bSocket = new BroadcastSocket();
          // bSocket.restoreMessage(data);
          break;
        }
        case 'FavoriteMessage': {
          // const bSocket = new BroadcastSocket();
          // bSocket.favoriteMessage(data);
          break;
        }
        case 'MessageStatus': {
          const { param1: id, param2: action } = data;
          const isMinimize = action === 'minimized';
          // todo: pass a senderNumber as third

          const senderNumber = null;
          const updatedFromSocket = true;

          dispatch(
            setFeedItemMinimizedStatus(
              id,
              isMinimize,
              senderNumber,
              updatedFromSocket,
              TABS.messages
            )
          );
          // const id = data.param1;
          // let elem = getById(id);
          // const l = elem.querySelector('.minimize-btn');
          // const audioBlock = elem.querySelector('.audio_messages');
          // if (data.param2 == "minimized" && !elem.classList.contains('minimied')) {
          //   elem.classList.add('minimized');
          //   if (audioBlock) {
          //     audioBlock.classList.add('hidden')
          //   }
          // } else if (data.param2 == '' && elem.classList.contains('minimized')) {
          //   elem.classList.remove('minimized');
          //   if (audioBlock) audioBlock.classList.remove('hidden')
          // }
          break;
        }
        case 'MessageFavorite': {
          // actionName: "MessageFavorite"
          // other: []
          // param1: "6121472"
          // param2: "true"
          const { param1: id, param2 } = data;
          const isFav = param2 === 'true';
          // todo: pass senderNumber as third
          const senderNumber = null;
          const updatedFromSocket = true;
          dispatch(setSocketFavState(id, isFav, senderNumber, updatedFromSocket, TABS.messages));
          break;
        }
        case 'MessageLabels':
          // actionName: "MessageLabels"
          // other: []
          // param1: "6121472"
          // param2: "blue"
          const { param1: id, param2: color } = data;
          dispatch(setLabelsSocket(id, color, TABS.messages));
          break;

        case 'registrationCreate': {
          const { isLoading, isSetSessions } = getState().sessions;
          const type = 'registration';
          const id = data.param2;
          const title = data.param3;

          if (isLoading) {
            dispatch(
              createSessionTempSuccess({
                id,
                type,
                title,
                description: '',
                memo: '',
                isHidden: false
              })
            );
          } else {
            if (!isSetSessions) {
              break;
            }
            dispatch(
              createSessionSuccess({
                id,
                type,
                title,
                description: '',
                memo: '',
                isHidden: false
              })
            );
          }
          break;
        }

        case 'RegistrationEdit': {
          const { info } = getState().isettings;
          const { id, userName, socketPort, socketToken, token, ...changedParams } = data.other;

          let newInfo = {};

          if (
            changedParams?.event_description ||
            changedParams?.event_title ||
            'event_image' in changedParams ||
            changedParams?.checkboxes
          ) {
            newInfo = {
              ...info,
              registration_json: {
                ...info.registration_json,
                ...changedParams
              }
            };
            if (changedParams?.checkboxes) {
              newInfo.registration_json.checkboxes = clearAndParseJSON(changedParams?.checkboxes);
            }
          } else {
            newInfo = {
              ...info,
              id,
              ...changedParams
            };
          }

          if (id !== info.id || !info.id) {
            dispatch(updateSessionInfo({ id, ...changedParams }));
            return;
          }

          dispatch(setISettingsInfo(newInfo));
          break;
        }

        case 'RegistrationStart': {
          dispatch(updateStartRegistr(data.param1, data.param2));
          break;
        }

        case 'RegistrationStop': {
          dispatch(updateStopRegistr(data.param1));
          break;
        }

        case 'newRegistrationCard':
        case 'updateRegistrationCard':
        case 'EditRegistrationCard': {
          const { info } = getState().isettings;
          const { param2: card_id, param1: id } = data;
          if (info.id !== id) return;

          dispatch(addParticipantCard(card_id));
          break;
        }
        case 'DeleteRegistrationCard': {
          const { param1: id } = data;
          dispatch(registrationActions.deleteParticipant({ id }));
          break;
        }

        case 'deleteMessage':
          // $1 - message id
          // const ind = lbMsg.messageList.find(item => item.id == data.param1);
          // lbMsg.messageList.splice(ind, 1);
          // document.getElementById(data.param1).remove();
          break;
        case 'EXP_EDITED':
          // const elem3 = getById(data.param1 + '-exp');
          // if (elem3) {
          //   elem3.getElementsByClassName('text')[0].innerHTML = data.param2;
          // }
          break;
        case 'ChangeCommonData':
          // $1 - id inter
          // $2 - new json
          // if (getById('module-broadcast').dataset.id === data.param1 && !getById('module-broadcast').classList.contains('hidden')) {
          //   const param2 = JSON.parse(data.param2);
          //   const tv = getById('module-broadcast');
          //   tv.querySelector('.title').innerHTML = param2.title;
          //   tv.querySelector('.descr').innerHTML = param2.description;
          //   tv.querySelector('.memo').innerHTML = param2.memo;
          //   tv.querySelector('.tag').innerHTML = param2.tag;
          // }
          // if (getById(data.param1)) {
          //   const param2 = JSON.parse(data.param2);
          //   const smallTv = getById(data.param1);
          //   smallTv.querySelector('.title').innerHTML = param2.title;
          //   smallTv.querySelector('.description').innerHTML = param2.description;
          //   smallTv.querySelector('.memo').innerHTML = param2.memo;
          //   smallTv.querySelector('.key-tag').innerHTML = param2.tag;
          // }
          break;
        case 'RemoveMessageFromInter':
          // $1 - id inter
          // $2 - content,
          // $3 - message timeId
          // if (getById('module-broadcast').dataset.id === data.param1
          //   && !getById('module-broadcast').classList.contains('hidden')) {
          //   document.querySelector(`[data-time-id="${data.param3}"]`).remove();
          //   const msgIndex = lbExp.messageList.findIndex(item => item.timeId === data.param3);
          //   if (msgIndex !== -1) {
          //     lbExp.messageList.slice(msgIndex, 1);
          //   }
          // }
          break;
        case 'EXP_REVERT':
          // $1 - текст сообщения
          // $2 - timeId
          // $3 - inter id
          // if (!getById('module-broadcast').classList.contains('hidden') &&
          //   getById('module-broadcast').dataset.id === data.param3) {
          //   const msgInList = lbExp.messageList.findIndex(item => item.timeId === data.param2);
          //   const msgElement = document.querySelector(`[data-time-id="${data.param2}"] > .text`);
          //   lbExp.messageList[msgInList].content = data.param1;
          //   msgElement.innerHTML = data.param1;
          // }
          break;
        case 'contentChange':
          // $1 - id inter
          // $2 - content,
          // $3 - message timeId
          // if (!getById('module-broadcast').classList.contains('hidden') &&
          //   getById('module-broadcast').dataset.id === data.param1) {
          //   const msgInList = lbExp.messageList.findIndex(item => item.timeId === data.param3);
          //   const msgElement = document.querySelector(`[data-time-id="${data.param3}"] > .text`);
          //   lbExp.messageList[msgInList].content = data.param2;
          //   msgElement.innerHTML = data.param2;
          // }
          break;
        case 'IactiveChange':
          // if (getById('module-broadcast').dataset.id == data.param1) {
          //   if (!getById(data.param1 + '-exp')) {

          //     loadLinkedMessages(data.param1);
          //     // добавлено новое
          //     // if (document.querySelectorAll('#lbExp > ul > li').length < param2.length) {
          //     //     // param2.forEach(i => {
          //     //     //     if (!getById(i+'-exp'))
          //     //     //         newMSG = i;
          //     //     // })
          //     //     //
          //     //     // if (newMSG) {
          //     //     //     ajax({
          //     //     //         type: 'post',
          //     //     //         url: 'scr/dbController.php',
          //     //     //         data: {
          //     //     //             action: 'MessagesLoad',
          //     //     //             param1: newMSG,
          //     //     //             param2: 'alone'
          //     //     //         }
          //     //     //     }, function (d) {
          //     //     //         addSocketMessage(d);
          //     //     //     })
          //     //     // }
          //     //
          //     //     // одно удалено
          //     // } else if (document.querySelectorAll('#lbExp > ul > li').length > param2.length) {
          //     //     console.log('deleteee');
          //     //     param2.forEach(i => {
          //     //         if (!getById(i+'-exp'))
          //     //             newMSG = i;
          //     //     })
          //     //     const messagesAll = document.querySelectorAll('#lbExp > ul > li');
          //     //     for (let i=0; i<messagesAll.length; i++) {
          //     //         if (param2.indexOf(messagesAll[i].dataset.id) === -1) {
          //     //             const m = messagesAll[i].getElementsByClassName('action-mnu')[0];
          //     //             m.click();
          //     //             getById('tv-msg-action-remove').click();
          //     //             messagesAll[i].remove();
          //     //         }
          //     //     }
          //     // } else {
          //     //     //const firstId = document.querySelectorAll('#lbExp > ul > li')[0].dataset.id;
          //     //     //if (firstId != param2[0]) {
          //     //     // const oldEl = getById(param2[0]+'-exp');
          //     //     // const oldNode = document.querySelectorAll('#lbExp > ul > li')
          //     //     // const newEl = oldEl.cloneNode();
          //     //     // oldNode.insertBefore(newEl, firstId);
          //     //     // oldEl.remove();
          //     //     //}
          //     // }
          //   }
          // }
          break;
        case 'toggleFav':
          // избранное в трансляции
          // $1 - timeId message,
          // $2 - inter id
          // $3 - status (0-1)
          // if (!getById('module-broadcast').classList.contains('hidden') &&
          //   getById('module-broadcast').dataset.id === data.param2) {
          //   const msgInList = lbExp.messageList.findIndex(item => item.timeId === data.param1);
          //   const msgElement = document.querySelector(`[data-time-id="${data.param1}"] > .inter-fav`);
          //   if (parseInt(data.param3) === 1) {
          //     msgElement.classList.add('on');
          //   } else {
          //     msgElement.classList.remove('on');
          //   }
          //   lbExp.messageList[msgInList].isFav = parseInt(data.param3);
          // }

          break;
        case 'EXP_EXPORT':
          // TODO: export socket

          break;
        case 'AssessmentMessage':
          // if (getById(data.param1))
          //   getById(data.param1).querySelectorAll('.assassmentList > ul')[0].innerHTML += `<li><img src="http://dev533.iactive.pro/scr/getUserMediaImage.php?id=${data.param2}" alt=""></li>`
          break;
        case 'HideAssessment':
          // const list = document.querySelectorAll('*[data-id="' + data.param1 + '"]');
          // for (let i = 0; i < list.length; i++)
          //   list[i].remove();
          break;
        case 'NewMessage':
          // executeServerCommand("MessagesLoad", getLastVisibleMessageID());
          break;
        case 'newAudio':
          // newAudioAction(data.param1, data.param2, data.param3, data.other.newAudioLink);
          break;
        default:
          return;
      }
    } else if (data.soсketToken && data.soсketToken === 'socketToken') {
      // switch (data.actionName) {
      //   case 'newAudio':
      //     newAudioAction(data.param1, data.param2, data.param3, data.other.newAudioLink);
      //     break;
      // }
    }
  };

  return webSocket;
};

// function newAudioAction(type, mId, interid, newAudioLink) {
//   const intMsg = getById(mId);
//   if (intMsg) {
//     const mediaBlock = intMsg.querySelector('.media');
//     if (type === 'audio') {
//       const needElement = mediaBlock.querySelector('#player-' + interid).parentElement.parentElement;
//       const forRemove = needElement.querySelector('.changedMedia');
//       if (forRemove) forRemove.remove();

//       const div = document.createElement('div');
//       div.id = 'changed-audio-' + interid;
//       div.className = 'changedMedia';
//       needElement.appendChild(div);
//       Player.create('changed-audio-' + interid, newAudioLink, { 'data': { 'inerId': interid } });
//       let p1 = new Player('#' + 'changed-audio-' + interid, preUrl + newAudioLink, 'Загруженное аудио');
//     } else if (type === 'audio_msg') {

//       const audio_block = intMsg.querySelector('.audio_messages');
//       const forRemove = intMsg.querySelector('.changedMedia');
//       if (forRemove) forRemove.remove();

//       const div = document.createElement('div');
//       div.id = 'changed-audio-msg-' + interid;
//       div.className = 'changedMedia';
//       audio_block.parentElement.appendChild(div);

//       Player.create('changed-audio-msg-' + interid, preUrl + newAudioLink,
//         {
//           'data': {
//             'type': 'audio_msg',
//             'mid': mId,
//             'audio': newAudioLink,
//             'jId': 0,
//             'inerId': mId
//           }
//         });
//       let p1 = new Player('#' + 'changed-audio-msg-' + interid, preUrl + newAudioLink, 'Загруженное аудио');

//       // const frame = document.createElement('iFrame');
//       // frame.src = 'scr/audioIframe.php?audio='+newAudioLink+'&type=changedAudio';
//       // frame.className = 'audio-iframe changedMedia';
//       // audio_block.appendChild(frame);
//     }
//     new Snack({
//       msg: 'Файл загружен'
//     });
//   }

// }

// start();

// setInterval(function () {
//   if (!sockets) {
//     start();
//   }
// }, 5000);

export default startSocket;
