import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';

import Button from 'components/Button/Button';

import useActions from 'hooks/useActions';

import styles from './Content.module.css';

function Content({ content, isHidden, isBroadcastMsg, id, className, countCharsOneLine }) {
  const { updateMessageText, changeBroadcastText } = useActions();
  const textRef = useRef(null);
  const [needHide, setNeedHide] = useState(false);
  const [isHided, setIsHided] = useState(true);

  const classes = classNames(
    styles['text'],
    'hyphens',
    'sel-on',
    {
      [styles['editable']]: isBroadcastMsg
    },
    className
  );

  useEffect(() => {
    if (textRef.current.offsetHeight > 67) {
      setNeedHide(true);
    }
  }, []);

  const textStyle = {
    maxHeight: isHided ? (isBroadcastMsg ? 72 : 65) : 'none',
    opacity: isHidden ? 0 : 1,
    transition: 'opacity 1s'
  };

  const onChange = (event) => updateMessageText({ id, text: event.target.value });

  const onBlur = (event) => {
    updateMessageText({ id, text: event.target.innerText });
    changeBroadcastText(id);
  };

  return (
    <>
      {isBroadcastMsg ? (
        <ContentEditable
          innerRef={textRef}
          onChange={onChange}
          onBlur={onBlur}
          html={content}
          className={classes}
          style={textStyle}
        />
      ) : (
        <div
          ref={textRef}
          style={textStyle}
          className={classes}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
      {(countCharsOneLine ? content?.length > countCharsOneLine * 3 : needHide) && (
        <Button
          variant="text"
          clickHandler={() => setIsHided(!isHided)}
          color="#949494"
          hoverColor="transparent"
          activeColor="transparent"
          style={{
            padding: '5px 0',
            minWidth: 'unset'
          }}>
          {isHided ? window.t('more') : window.t('hide')}
        </Button>
      )}
    </>
  );
}

export default Content;
