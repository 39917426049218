import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authors: null,
  messages: null
};

export const searchStatisticsSlice = createSlice({
  name: 'searchStatistics',
  initialState,
  reducers: {
    setSearchStatisticsByTime: (state, action) => {
      const { authors, messages } = action.payload;
      return {
        ...state,
        authors,
        messages
      };
    }
  }
});

export const searchStatisticsActions = searchStatisticsSlice.actions;
export const searchStatisticsReducer = searchStatisticsSlice.reducer;
