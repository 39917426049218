import SearchStatistics from '../SearchStatistics/SearchStatistics';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Button, Icon } from 'components';

import { getDateNow } from 'helpers/dateTime';

import styles from './TimeSearchMode.module.css';

function TimeSearchMode({
  searchConditions,
  setSearchConditions,
  activeSearchMode,
  scrollBoxRef,
  fetchSearchStatistics
}) {
  // const periods = [
  //   {
  //     title: window.t('inHour'), // 'В течение часа',
  //     type: '1h'
  //   },
  //   {
  //     title: window.t('forSixHours'),
  //     type: '6h'
  //   },
  //   {
  //     title: window.t('perDay'),
  //     type: '1d'
  //   },
  //   {
  //     title: window.t('forTwoDays'),
  //     type: '2d'
  //   },
  //   {
  //     title: window.t('duringWeek'),
  //     type: '1w'
  //   },
  //   {
  //     title: window.t('perMonth'),
  //     type: '1m'
  //   }
  // ];

  const { timeType, hoursAndMinutesStart, hoursAndMinutesEnd, timeStart, timeEnd } =
    searchConditions;

  const setTimeType = (value) => {
    const newVal = timeType === value ? null : value;
    setSearchConditions('timeType', newVal, scrollBoxRef);
  };

  const setPeriod = (event) => {
    const { name, value } = event.target;
    setSearchConditions(name, value, null);
  };

  const setDate = (event) => {
    const { value } = event.target;
    setSearchConditions('timeStart', value, null);
    setSearchConditions('timeEnd', value, null);
  };

  const classes = classNames(styles['pane'], {
    hidden: activeSearchMode !== 'timeType'
  });

  // const getButtonClasses = (isActive) => {
  //   return classNames({
  //     [styles['selected']]: isActive
  //   });
  // };

  const inputClasses = classNames(styles['inputItem'], [styles['selected']]);

  useEffect(() => {
    if (hoursAndMinutesStart === null || hoursAndMinutesStart === undefined) {
      setSearchConditions('hoursAndMinutesStart', '00:00', scrollBoxRef);
    }
    if (hoursAndMinutesEnd === null || hoursAndMinutesEnd === undefined) {
      setSearchConditions('hoursAndMinutesEnd', '23:59', scrollBoxRef);
    }
    if (timeType) {
      setTimeType(null);
    }
  }, []);

  const arrowUpClick = () => {
    if (hoursAndMinutesEnd !== '23:59') {
      setSearchConditions('hoursAndMinutesEnd', '23:59', null);
    }
  };

  return (
    <div id="searchModeTimeTab" className={classes}>
      <p className={styles['title']}>{window.t('statistics')}</p>
      <div className={styles['rangeTimeWrapper']}>
        <div className={styles['inputs-wrapper']}>
          <div className={inputClasses}>
            <div className={styles['inputTitle']}>{window.t('date')}</div>
            <input
              value={timeStart || ''}
              className={styles['searchActive']}
              onChange={setDate}
              name="timeStart"
              type="date"
              max={getDateNow().slice(0, 10)}
              id="searchDateFrom"
              required
            />
          </div>

          <div className={inputClasses}>
            <div className={styles['inputTitle']}>{window.t('with')}</div>
            {/* <input
              value={timeStart || ''}
              className={styles['time-start-input']}
              onChange={setPeriod}
              name="timeStart"
              type="date"
              min="2016-01-01"
              max={getDateNow().slice(0, 10)}
              id="searchDateFrom"
            /> */}
            <input
              value={hoursAndMinutesStart || ''}
              className={styles['searchActive']}
              onChange={setPeriod}
              name="hoursAndMinutesStart"
              type="time"
              id="hoursAndMinutesStart"
              max={hoursAndMinutesEnd || ''}
            />
          </div>
          <div className={inputClasses}>
            <div className={styles['inputTitle']}>{window.t('by')}</div>
            {/* <input
              value={timeEnd || ''}
              className={styles['time-start-input']}
              onChange={setPeriod}
              name="timeEnd"
              min={timeStart || '2016-01-01'}
              max={getDateNow().slice(0, 10)}
              type="date"
              id="searchDateTo"
            /> */}
            <input
              value={hoursAndMinutesEnd || ''}
              className={styles['searchActive']}
              onChange={setPeriod}
              name="hoursAndMinutesEnd"
              type="time"
              id="hoursAndMinutesEnd"
              min={hoursAndMinutesStart || ''}
            />
            <div
              className={styles['arrowUpBlock']}
              title={`${window.t('selectMaximumTime')} - 23:59`}
              onClick={arrowUpClick}>
              <Icon fontName="fas fa-arrow-up" size={16} color="#fff" style={{}} />
            </div>
          </div>
        </div>
        <div className={styles['btnWrapper']}>
          <Button
            style={{
              minWidth: '86px'
            }}
            title={window.t('searchBySpecifiedTimeInterval')}
            variant="contained"
            color="primary"
            clickHandler={() => {
              fetchSearchStatistics();
              // setTimeType('period');
            }}>
            {window.t('send')}
          </Button>
        </div>
      </div>

      {activeSearchMode === 'timeType' && (
        <div className={styles['statisticsBlock']}>
          <SearchStatistics />
        </div>
      )}

      {/* <ul>
        {periods.map(({ type, title }, i) => {
          return (
            <Button
              clickHandler={() => {
                setTimeType(type);
              }}
              className={getButtonClasses(timeType === type)}
              color="#00000026"
              hoverColor="#00000040"
              textColor="#ffffff"
              variant="contained"
              style={{
                borderRadius: 50,
                padding: '7px 12px',
                display: 'inline-block',
                margin: '0 1px 7px'
              }}
              size="small"
              key={i}>
              {' '}
              {title}
            </Button>
          );
        })}
      </ul> */}
    </div>
  );
}

export default TimeSearchMode;

TimeSearchMode.propTypes = {
  searchConditions: PropTypes.object,
  setSearchConditions: PropTypes.func,
  activeSearchMode: PropTypes.string
};
