import {
  ADD_EXPORT_CONFIG,
  ADD_LABEL_SUCCESS,
  ADD_MESSAGES_TO_PINNED_AUTHOR,
  ADD_RESPONSE_MESSAGE,
  CLEAR_LABELS_SUCCESS,
  DELETE_EXPORT_CONFIG,
  DELETE_LABEL_SUCCESS,
  REMOVE_FEED_ITEM,
  SET_AUTHORS,
  SET_AVATAR_FORM,
  SET_BROADCAST_CONFIG,
  SET_CURRENT_TAB,
  SET_FEED_ITEM_FAV_STATUS,
  SET_FEED_ITEM_HIDDEN_STATE,
  SET_FEED_ITEM_LIKE_STATUS,
  SET_FEED_ITEM_MINIMIZED_STATUS,
  SET_LABELS_SUCCESS,
  SET_MESSAGE_ACTIONS_CONFIG,
  SET_MESSAGE_FEED_CONFIG,
  SET_MESSAGE_VIEW_CONFIG,
  SET_PINNED_AUTHORS,
  SET_PINNED_INTERACTIVES,
  SET_PINNED_MESSAGES_AS_READ,
  SET_SETTINGS,
  SET_SETTINGS_ARRAY,
  SET_TOTAL_NOTE_COUNT,
  TOGGLE_PINNED_AUTHOR,
  UPDATE_APP_SETTINGS,
  UPDATE_BROADCAST_CONFIG,
  UPDATE_EXPORT_CONFIG,
  UPDATE_FEED_ITEM_AUDIO,
  UPDATE_MESSAGE_ACTIONS_CONFIG,
  UPDATE_MESSAGE_FEED_CONFIG,
  UPDATE_MESSAGE_VIEW_CONFIG,
  UPDATE_SETTINGS_ARRAY
} from '../actions/actionTypes';
import { NOTIFICATION_PERMISSIONS } from '../constants';
import uniqBy from 'lodash/uniqBy';

export const initialSettingsState = {
  totalNoteCount: 0,
  templates: false,
  appSettings: {
    permission: NOTIFICATION_PERMISSIONS.default
  },
  exportConfig: {
    email: [],
    telegram: []
  },
  messageActionsConfig: {
    copy: true,
    exportToBroadcast: true,
    minimize: true,
    settings: true,
    favorite: true
  },
  feedbackActions: {
    like: true,
    dislike: true,
    prize: true
  },
  messageViewConfig: {
    idMark: true,
    dateTime: true,
    labels: true, // метки
    region: true, // город, с которого отправляется сообщение<
    // 'like': false,
    attachments: true // вложения
  },
  messageFeedConfig: {
    isFeedbackEnabled: false,
    isNewAtTop: false, // новые сверху
    isAlwaysAutoScroll: false,
    tag: [],
    like: [],
    answer: []
  },
  broadcastConfig: {
    // 'titr': false, // показывать ли кнопки "в титр" в интерактиве
    isExportAvailable: true,
    // 'countChar': false,
    isCharCounterAvailable: false,
    charAmount: 20,
    newMessageBtn: true,
    titmeToAutoScroll: 5 * 1000,
    isAutoSend: false,
    isAutoAdd: false,
    noAutoload: false,
    isProfileCardModal: false
  },
  chanelsForAss: {
    // какие каналы можно оценивать
    facebook: true,
    telegrambot: true,
    vkgroup: true,
    sms: true,
    viber: true,
    livechat: true,
    whatsapp: true,
    smsMoney: true,
    fbinstagram: true
  },
  playerConfig: {
    noAutoLoad: true,
    autoRepeat: false
  },
  authors: [],
  avatarForm: null,
  pinnedInteractives: [],
  authorsUpdatedFromSocket: false
};

export default function settingsReducer(state = initialSettingsState, action) {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    case SET_TOTAL_NOTE_COUNT:
      return {
        ...state,
        totalNoteCount: action.count
      };
    case SET_SETTINGS_ARRAY:
      return {
        ...state,
        settingsArray: action.settingsArray
      };
    case UPDATE_SETTINGS_ARRAY:
      return {
        ...state,
        settingsArray: {
          ...state.settingsArray,
          [action.field]: action.value
        }
      };
    case UPDATE_APP_SETTINGS:
      return {
        ...state,
        appSettings: {
          ...state.appSettings,
          [action.field]: action.value
        }
      };
    case UPDATE_MESSAGE_ACTIONS_CONFIG:
      return {
        ...state,
        messageActionsConfig: {
          ...state.messageActionsConfig,
          [action.field]: action.value
        }
      };
    case UPDATE_MESSAGE_VIEW_CONFIG:
      return {
        ...state,
        messageViewConfig: {
          ...state.messageViewConfig,
          [action.field]: action.value
        }
      };
    case UPDATE_MESSAGE_FEED_CONFIG:
      return {
        ...state,
        messageFeedConfig: {
          ...state.messageFeedConfig,
          [action.field]: action.value
        }
      };
    case UPDATE_BROADCAST_CONFIG:
      return {
        ...state,
        broadcastConfig: {
          ...state.broadcastConfig,
          [action.field]: action.value
        }
      };

    case SET_MESSAGE_ACTIONS_CONFIG:
      return {
        ...state,
        messageActionsConfig: action.messageActionsConfig
      };
    case SET_MESSAGE_VIEW_CONFIG:
      return {
        ...state,
        messageViewConfig: action.messageViewConfig
      };
    case SET_MESSAGE_FEED_CONFIG:
      return {
        ...state,
        messageFeedConfig: action.messageFeedConfig
      };
    case SET_BROADCAST_CONFIG:
      return {
        ...state,
        broadcastConfig: action.broadcastConfig
      };

    case ADD_EXPORT_CONFIG:
      return {
        ...state,
        exportConfig: {
          ...state.exportConfig,
          [action.payload.type]: [
            ...state.exportConfig[action.payload.type],
            action.payload.newValue
          ]
        }
      };

    case DELETE_EXPORT_CONFIG:
      return {
        ...state,
        exportConfig: {
          ...state.exportConfig,
          [action.payload.type]: state.exportConfig[action.payload.type].filter(
            (_, i) => i !== action.payload.index
          )
        }
      };

    case UPDATE_EXPORT_CONFIG:
      return {
        ...state,
        exportConfig: {
          ...state.exportConfig,
          [action.payload.type]: state.exportConfig[action.payload.type].map((item, i) => {
            if (action.payload.index === i) {
              return { ...item, ...action.payload.value };
            }
            return item;
          })
        }
      };
    case SET_AUTHORS:
      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: action.authors
      };
    case TOGGLE_PINNED_AUTHOR:
      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: state.authors?.some(
          (author) => author.senderNumber === action.message.senderNumber
        )
          ? (state.authors || []).filter(
              (author) => author.senderNumber !== action.message.senderNumber
            )
          : [
              ...(state.authors || []),
              {
                senderNumber: action.message.senderNumber,
                image: action.message.image,
                author: action.message.author,
                channel: action.message.channel,
                lastReadMessageId: Math.max(
                  ...action.messageFeed
                    ?.filter((msg) => msg.senderNumber === action.message.senderNumber)
                    .map((el) => Number(el.id))
                ).toString(),
                firstMessageId: action.message.id
              }
            ]
      };
    case SET_PINNED_MESSAGES_AS_READ:
      if (!state.authors?.some((author) => author.senderNumber === action.senderNumber))
        return state;
      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                lastReadMessageId: action.lastReadMessageId
              }
        )
      };
    case SET_FEED_ITEM_FAV_STATUS: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || [] || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((message) =>
                  message.id === action.id ? { ...message, favorite: action.value } : message
                )
              }
        )
      };
    }
    case SET_FEED_ITEM_LIKE_STATUS: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || [] || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((message) =>
                  message.id === action.id ? { ...message, isLike: action.value } : message
                )
              }
        )
      };
    }
    case SET_FEED_ITEM_MINIMIZED_STATUS: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || [] || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((msg) =>
                  msg.id === action.id
                    ? {
                        ...msg,
                        status: action.value
                          ? [...msg.status, 'minimized']
                          : msg.status.filter((el) => el !== 'minimized')
                      }
                    : msg
                )
              }
        )
      };
    }

    case ADD_RESPONSE_MESSAGE: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || [] || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((message) =>
                  message.id === action.id
                    ? {
                        ...message,
                        after: [
                          ...message.after,
                          {
                            ...action.message,
                            message_type: 'message',
                            isNew: true
                          }
                        ]
                      }
                    : message
                )
              }
        )
      };
    }
    case SET_FEED_ITEM_HIDDEN_STATE: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((msg) =>
                  msg.id === action.id ? { ...msg, isHidden: action.value } : msg
                )
              }
        )
      };
    }
    case SET_LABELS_SUCCESS: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((msg) =>
                  msg.id === action.id ? { ...msg, labels: action.labels.split(',') } : msg
                )
              }
        )
      };
    }
    case ADD_LABEL_SUCCESS:
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((msg) =>
                  msg.id === action.id ? { ...msg, labels: [...msg.labels, action.label] } : msg
                )
              }
        )
      };
    case DELETE_LABEL_SUCCESS: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((msg) =>
                  msg.id === action.id
                    ? {
                        ...msg,
                        labels: msg.labels.filter((el) => el !== action.label)
                      }
                    : msg
                )
              }
        )
      };
    }
    case CLEAR_LABELS_SUCCESS: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((msg) =>
                  msg.id === action.id ? { ...msg, labels: [] } : msg
                )
              }
        )
      };
    }
    // case FILTER_MESSAGES:
    //   return {
    //     ...state,
    //     messages: state.messages.map((msg) =>
    //       action.toShow.includes(msg.id)
    //         ? { ...msg, isHidden: false }
    //         : { ...msg, isHidden: true }
    //     ),
    //   };
    // case SHOW_ALL_MESSAGES:
    //   return {
    //     ...state,
    //     messages: state.messages.map((msg) => ({ ...msg, isHidden: false })),
    //   };
    case REMOVE_FEED_ITEM: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).filter((el) => el.id !== action.id)
              }
        )
      };
    }
    case UPDATE_FEED_ITEM_AUDIO: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((message) =>
                  message.id !== action.id
                    ? message
                    : {
                        ...message,
                        attachments: message.attachments.map((media) =>
                          media.ogg !== action.prevSrc ? media : { ...media, ogg: action.newSrc }
                        )
                      }
                )
              }
        )
      };
    }
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.currentTab
      };
    // форма аватарок сообщений
    case SET_AVATAR_FORM:
      return {
        ...state,
        avatarForm: action.avatarForm
      };
    // закрепленные интерактивы
    case SET_PINNED_INTERACTIVES:
      return {
        ...state,
        pinnedInteractives: action.pinnedInteractives
      };

    default:
      return state;
  }
}
