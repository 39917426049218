import { MAX_COUNT_LINES } from 'store/constants';

export const checkCountLines = (value, max) => {
  const countLines = value.includes('\n') ? Number(value.split('\n').length) - 1 : 0;
  if (max) {
    return countLines < max;
  } else {
    return countLines < MAX_COUNT_LINES;
  }
};

export const checkIsValid = (
  elementKey,
  block,
  isTouched,
  activeBlocksLength,
  updateContentBlockField,
  prev
) => {
  const value = block && block[elementKey];
  let isValid = true;

  if (value && value['type#'] === 'video') {
    if (
      (value?.isOptional === false && isTouched && !value.value) ||
      (value?.isOptional === undefined && isTouched && !value.value) // для корректной работы шаблонов при отсутствии настройки isOptional в JSONе
    ) {
      return false;
    } else return true;
  }

  if (value && value['type#'] === 'image') {
    if (isTouched && value?.isOptional === false && !value.value) {
      return false;
    } else return true;
  }

  if (value && value['type#'] === 'audio') {
    if (!value.value.url && value?.isOptional === false) {
      return false;
    } else return true;
  }

  if (value && typeof value === 'object') {
    if (value['type#'] === 'titreDurationElement') {
      if (block.lastBlockNumber?.value > activeBlocksLength) {
        return false;
      } else if (isTouched && !block.lastBlockNumber.value) {
        return false;
      } else return true;
    }

    const maxLength = Number(value && value['max_length#']);

    if (value['type#'] === 'speech') {
      if (
        value.duration > maxLength ||
        (value?.isOptional === false && value.duration === 0 && isTouched) ||
        (value?.isOptional === undefined && value.duration === 0 && isTouched) // для корректной работы шаблонов при отсутствии настройки isOptional в JSONе
      ) {
        return false;
      } else return true;
    }

    let length =
      typeof value.value === 'object' && value.value.text !== undefined
        ? value.value.text.replaceAll('\n', '')?.length
        : value.value.replaceAll('\n', '')?.length;

    // автоматический перенос текста
    if (value.value.textHyphens !== undefined && typeof value.value === 'object') {
      length = value.value.textHyphens.replaceAll('\n', '')?.length;
    }

    if (maxLength && maxLength < length) {
      return false;
    }
    if (value?.isOptional === false && length === 0 && isTouched) {
      return false;
    }

    if (value['type#'] === 'stringWithPreviewElement') {
      if (prev !== undefined && prev !== null) {
        isValid = prev;
      }
      if (isValid === true) {
        if (value.value.text) {
          isValid = checkCountLines(value.value.text, value.maxCountLines);
        } else if (value.value && typeof value.value === 'string') {
          isValid = checkCountLines(value.value, value.maxCountLines);
        }
      }
      return isValid;
    }
  }

  // value?.isValid !== isValid && updateContentBlockField(block?.id, elementKey, isValid, 'isValid');
  return isValid;
};
