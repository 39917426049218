import { types } from './constants';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Joyride from 'react-joyride';

import { SessionModuleView } from 'components';

import { useFetch, useInteractive } from 'hooks';

import { LocaleContext } from 'providers/LocaleProvider';

import { INTERACTIVES } from 'store/constants';

import { formatTreeSelectData } from 'helpers/content';
import { getGuideLocale } from 'helpers/locale';

import {
  ContentControlsView,
  ContentListView,
  ContentSettingsView,
  ContentToolbarView
} from './components';

import { useContent, useContentControls } from './hooks';
import { useContentGuide } from './hooks/useContentGuide';

export function ContentContainer({ match, location }) {
  const [locale] = useContext(LocaleContext);
  const isCreatedRef = useRef(false);
  const {
    interactiveId: contentId,
    isSettingsOpened,
    setIsSettingsOpened
  } = useInteractive({ match, location, type: INTERACTIVES.content });

  const {
    run,
    startGuide,
    steps,
    stepIndex,
    setStepIndex,
    handleJoyrideCallback,
    controlsRef,
    newsBlockRef,
    textToSpeechRef,
    contentViewRef,
    interactiveInfoRef,
    selectControlsRef,
    okButtonRef
  } = useContentGuide({ type: INTERACTIVES.content });

  const {
    interactiveName,
    content_type,
    content_slot,
    content_rule,
    content_json,
    publishTime,
    blocks,
    activeBlocksLength,
    needBlocksCount,
    toggleSettings,
    addBlock,
    removeBlock,
    moveUp,
    moveDown
  } = useContent({ isSettingsOpened, setIsSettingsOpened });

  const { slots, selectedType, changeType, selectedSlot, changeSlot, publish } = useContentControls(
    {
      contentId,
      content_type,
      content_slot,
      setStepIndex,
      blocks
    }
  );

  const blockTypes = useMemo(
    () =>
      content_rule?.['content#'] &&
      Object.entries(content_rule['content#']).map(([key, { label, canSelect, isAutoCreate }]) => ({
        label,
        canSelect,
        isAutoCreate,
        value: key
      })),
    [content_rule]
  );

  const selectBlockTypes = blockTypes?.filter((type) => type.canSelect);

  useEffect(() => {
    if (!blockTypes) return;
    const autoCreateElements = blockTypes.filter((block) => block?.isAutoCreate === true);
    if (!Boolean(content_json?.length) && !isCreatedRef.current) {
      isCreatedRef.current = true;
      autoCreateElements.forEach((elementType) => {
        addBlock(elementType);
      });
    }
  }, [content_json, blockTypes]);

  const [{ response }, doFetch] = useFetch('api_get_audio_catalog');
  const [initialAudioFiles, setInitialAudioFiles] = useState();

  useEffect(() => {
    doFetch({
      method: 'GET'
    });
  }, []);

  useEffect(() => {
    if (!response) return;
    const formattedTreeSelectData = formatTreeSelectData(response);
    setInitialAudioFiles(formattedTreeSelectData);
  }, [response]);

  return (
    <SessionModuleView ref={contentViewRef} className="overflow-hidden">
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        steps={steps}
        stepIndex={stepIndex}
        locale={getGuideLocale()}
      />
      <ContentToolbarView interactiveName={interactiveName} toggleSettings={toggleSettings} />
      <div className="h-full content-container">
        {isSettingsOpened && (
          <ContentSettingsView
            types={types}
            slots={slots}
            changeType={changeType}
            changeSlot={changeSlot}
            selectedType={selectedType}
            selectedSlot={selectedSlot}
            publishTime={publishTime}
            activeBlocksLength={activeBlocksLength}
            contentId={contentId}
            toggleSettings={toggleSettings}
            startGuide={startGuide}
            interactiveInfoRef={interactiveInfoRef}
            selectControlsRef={selectControlsRef}
            okButtonRef={okButtonRef}
            setStepIndex={setStepIndex}
          />
        )}
        <ContentControlsView
          locale={locale}
          blockTypes={selectBlockTypes}
          selectedType={selectedType}
          selectedSlot={selectedSlot}
          publishTime={publishTime}
          publish={publish}
          addBlock={addBlock}
          activeBlocksLength={activeBlocksLength}
          needBlocksCount={needBlocksCount}
          ref={controlsRef}
          setStepIndex={setStepIndex}
        />
        <ContentListView
          initialAudioFiles={initialAudioFiles}
          backgroundAudioEffects={content_rule?.background_audio_effects}
          content_rule={content_rule}
          blockTypes={selectBlockTypes}
          contentId={contentId}
          blocks={blocks}
          removeBlock={removeBlock}
          moveUp={moveUp}
          moveDown={moveDown}
          setStepIndex={setStepIndex}
          newsBlockRef={newsBlockRef}
          activeBlocksLength={activeBlocksLength}
          textToSpeechRef={textToSpeechRef}
          // add weather
          selectedType={selectedType}
        />
      </div>
    </SessionModuleView>
  );
}
