import { addSnack } from './snackBox';

import { REQUEST_TAB_NAME } from 'store/constants';
import { searchStatisticsActions } from 'store/slices/searchStatisticsSlice';

import { getSearchStatistics } from 'api/dataLayer';

import { getDateMoscowZone } from 'helpers/dateTime';

export function fetchSearchStatistics() {
  return async (dispatch, getState) => {
    try {
      const { searchConditions } = getState().messageFilters;
      const { currentTab } = getState().feed;

      const { hoursAndMinutesStart, hoursAndMinutesEnd, timeStart, timeEnd } = searchConditions;

      if (!timeStart) {
        dispatch(
          addSnack({
            type: 'danger',
            message: window.t('enterDate')
          })
        );
        return;
      } else if (
        hoursAndMinutesStart > hoursAndMinutesEnd ||
        hoursAndMinutesEnd < hoursAndMinutesStart
      ) {
        dispatch(
          addSnack({
            type: 'danger',
            message: window.t('incorrectTimeInterval')
          })
        );
        return;
      }

      let start = `${timeStart} ${hoursAndMinutesStart}`;
      let end = `${timeEnd} ${hoursAndMinutesEnd}`;

      const postBody = {
        queryName: REQUEST_TAB_NAME[currentTab],
        dateStart: `${getDateMoscowZone(start)}:00`,
        dateEnd: `${getDateMoscowZone(end)}:59`
      };

      const response = await getSearchStatistics(postBody);
      const body = response?.body;

      const authors = body?.authorsList?.map((author) => author?.senderNumber?.toString()) || [];

      dispatch(
        searchStatisticsActions.setSearchStatisticsByTime({
          authors,
          messages: body?.messagesCount || 0
        })
      );
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errLoadingStatistics')
        })
      );
    }
  };
}
