import { useVirtualizer } from '@tanstack/react-virtual';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';

import { Button, DotsLoader, Icon } from 'components';

import styles from './RowVirtualizerDynamic.module.css';

function RowVirtualizerDynamic({
  data,
  virtualOptions,
  renderRow,
  children,
  parentRef,
  overscan = 3,
  loadingMessages
}) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const virtualizer = useVirtualizer({
    ...virtualOptions,
    count: data.length,
    getScrollElement: () => parentRef.current,
    overscan
  });

  const items = virtualizer.getVirtualItems();

  const debounceSetScrollPosition = useCallback(
    debounce((scroll) => {
      setScrollPosition(scroll);
    }, 500),
    []
  );

  return (
    <div
      onScroll={(e) => {
        debounceSetScrollPosition(e.target.scrollTop);
      }}
      ref={parentRef}
      style={{
        height: '100%',
        overflowY: 'auto'
      }}>
      {children}
      {loadingMessages ? (
        <div style={{ padding: '8px 16px' }}>
          <DotsLoader isActive />
        </div>
      ) : (
        <div
          style={{
            height: virtualizer.getTotalSize(),
            width: '100%',
            position: 'relative'
          }}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${items[0]?.start ?? 0}px)`
            }}>
            {items.map((virtualRow) => (
              <div
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}>
                {renderRow(data[virtualRow.index])}
              </div>
            ))}
          </div>
        </div>
      )}
      <Button
        className={styles.scrollTopBtn}
        onlyIcon
        variant="icon"
        color="#989898"
        startIcon={<Icon size={16} fontName="fas fa-chevron-up" />}
        clickHandler={(e) => {
          parentRef.current.scrollTo({ top: 0, behavior: 'instant' });
          e.currentTarget.style.display = 'none';
        }}
        style={{ display: scrollPosition > 799 ? 'flex' : 'none' }}
      />
    </div>
  );
}

export default RowVirtualizerDynamic;
