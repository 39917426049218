import { executeFilterMessages, getFilteredMessages } from './messageFilters';
import { fetchSearchStatistics } from './searchStatistics';
import { addSnack } from './snackBox';
import { format } from 'date-fns';
import uniqBy from 'lodash/uniqBy';

// import { addMessageToPinnedAuthor } from "store/actions/settings";
import { TABS } from 'containers/Feed/constants';

import { addChatMessages, fetchChatMessagesSuccess, setIsSendingMessage } from 'store/actions/chat';
import {
  addLabelSuccess,
  addResponseMessage,
  clearLabelsSuccess,
  deleteLabelSuccess,
  fetchFeedItemsSuccess,
  removeFeedItem,
  setFeedItemFavStatus,
  setFeedItemHiddenState,
  setFeedItemLikeStatus,
  setFeedItemMinimizedStatus,
  setHasNewFeedItems,
  setLabelsSuccess
} from 'store/actions/feed';
import { addMessageToPinnedAuthor, updatePinnedAuthorMessages } from 'store/actions/pinnedAuthors';
import { editProfile } from 'store/actions/profile';
import { fetchUserMessagesSuccess } from 'store/actions/profileCard';
import { SEARCH_STATISTICS_NAMES } from 'store/constants';
import settingsReducer from 'store/reducers/settings';
import { broadcastActions } from 'store/slices/broadcastSlice';
import { searchStatisticsActions } from 'store/slices/searchStatisticsSlice';

import { requestApiPost } from 'api';
import {
  clearFeedMessageLabels,
  deleteFeedMessage,
  editProfile as editProfileApi,
  recoveryFeedMessage,
  sendMessageToMessenger,
  setMessageFavoriteState,
  setMessageMinimizedState,
  toggleFeedMessageLabel
} from 'api/dataLayer';

import { getDBDate } from 'helpers/dateTime';
import { filterByTimePeriod } from 'helpers/filterMessages';
import { getLocalISOString } from 'helpers/formatDate';
import { getImageByChannel } from 'helpers/image';
import { addNativeNotification } from 'helpers/notificationsApi';

/* MESSAGES THUNK */
export function setItems(newItems, scrollBox = null, isNew = false, tab) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { feed, vote, broadcast } = state;
      const { messages: chatMessages } = state.chat;
      const { messages: profileMessages } = state.profileCard;
      const messages = feed[tab]?.items || [];
      let uniqMessages = uniqBy(newItems, 'id');

      const { authors, messages: searchMessages } = state.searchStatistics;
      const { posts } = state.socials;

      // проверка, чтобы сообщения не повторялись
      uniqMessages = uniqMessages.filter((msg) => !messages?.map((el) => el.id)?.includes(msg.id));

      if (tab === TABS.messages) {
        uniqMessages = uniqMessages.map((el) => {
          let curPost = posts.find((p) => p.href === el.social_href);
          if (!el.social_href) {
            el.isCheckedPost = true;
          } else if (!curPost) {
            el.isCheckedPost = false;
          } else {
            el.isCheckedPost = curPost?.checked;
          }
          return el;
        });
      }

      if (tab === TABS.news) {
        if (
          new Date(uniqMessages[0].created_in_DB || uniqMessages[0].create_in_db) >
          new Date(
            uniqMessages[uniqMessages.length - 1].created_in_DB ||
              uniqMessages[uniqMessages.length - 1].create_in_db
          )
        )
          uniqMessages = [...uniqMessages].reverse();

        uniqMessages = uniqMessages.map((item) => ({
          ...item,
          category: Array.isArray(item.category)
            ? [...new Set(item.category)]
            : [[...new Set([item.category])]],
          pubDate: getDBDate(item.pubDate),
          attachments:
            item.attachments ||
            (!item.enclosure?.['@attributes']
              ? []
              : Array.isArray(item.enclosure?.['@attributes'])
              ? item.enclosure?.['@attributes']
              : [item.enclosure?.['@attributes']])
        }));
      }

      const messagesForInteractive = uniqMessages?.filter((msg) => msg.iactiveID === vote.voteId);

      let allMessages;

      if (isNew) {
        allMessages = [...uniqMessages.reverse(), ...messages];
      } else {
        allMessages = [...messages, ...uniqMessages.reverse()];
      }

      if (tab === TABS.messages) {
        if (isNew && uniqMessages?.length) {
          for (const message of uniqMessages) {
            dispatch(addMessageToPinnedAuthor(message));
          }
        }

        const chatAuthorSenderNumber = chatMessages?.find((msg) => msg?.ID)?.senderNumber;
        const profileAuthorSenderNumber = profileMessages[0]?.SenderNumber;

        if (
          isNew &&
          chatAuthorSenderNumber
          //  && !hasMoreNext
        ) {
          let newChatMessages = uniqMessages?.filter(
            (msg) => msg.senderNumber === chatAuthorSenderNumber
          );
          if (newChatMessages.length) {
            newChatMessages = newChatMessages.map((msg) => ({
              ...msg,
              ID: msg.id,
              id: undefined,
              isNew: true
            }));
            dispatch(fetchChatMessagesSuccess([...chatMessages, ...newChatMessages]));
          }
        }
        if (isNew && profileAuthorSenderNumber) {
          const newProfileMessages = uniqMessages?.filter(
            (msg) => msg.senderNumber === chatAuthorSenderNumber
          );
          if (newProfileMessages.length) {
            dispatch(fetchUserMessagesSuccess([...profileMessages, ...newProfileMessages], tab));
          }
        }
        // if (isNew && messagesForInteractive.length) {
        //   const { messages } = broadcast;
        //   const { isMessagesReversed } = vote;
        //   let newMessages = messagesForInteractive;
        //   newMessages = newMessages.map((el) => ({
        //     ...el,
        //     labels: Array.isArray(el.labels) ? el.labels?.filter((elm) => elm !== '') : []
        //   }));
        //   let updatedMessages = isMessagesReversed
        //     ? [...newMessages.reverse(), ...messages]
        //     : [...messages, ...newMessages];

        //   dispatch(
        //     broadcastActions.fetchBroadcastMessagesSuccess({
        //       messages: updatedMessages
        //     })
        //   );
        // }
      }

      const { searchConditions, activeMenu } = state.messageFilters;
      const toShow = getFilteredMessages(activeMenu, searchConditions, allMessages);
      allMessages.forEach((el) => {
        el.labels = el.labels?.filter((el) => el !== '');
        el.isHidden = !toShow.includes(el.id);
      });
      dispatch(fetchFeedItemsSuccess(allMessages, tab));

      // search statistics
      const { hoursAndMinutesStart, hoursAndMinutesEnd, timeStart, timeEnd, timeType } =
        searchConditions;

      if (isNew && authors && tab === TABS.messages) {
        const searchPeriodTimeMessages = filterByTimePeriod(
          hoursAndMinutesStart,
          hoursAndMinutesEnd,
          timeStart,
          timeEnd,
          uniqMessages
        );

        const fieldsForCreatingAName = SEARCH_STATISTICS_NAMES[tab];

        const createdNames = searchPeriodTimeMessages?.map((msg) =>
          fieldsForCreatingAName
            .map((name) => msg[name])
            .reduce((acc, cur) => {
              return acc + cur;
            }, '')
        );

        const newAuthors = createdNames.filter((name) =>
          authors ? !authors?.includes(name) : true
        );

        const oldAuthors = authors ?? [];

        dispatch(
          searchStatisticsActions.setSearchStatisticsByTime({
            authors: [...oldAuthors, ...newAuthors],
            messages: searchMessages + (searchPeriodTimeMessages?.length || 0)
          })
        );
      }

      if (isNew) {
        const uniqMessagesIds = uniqMessages.map((msg) => msg.id);
        const newShownMessagesLength = allMessages.reduce(
          (acc, msg) => acc + !!(!msg.isHidden && uniqMessagesIds.includes(msg.id)),
          0
        );
        const count = newShownMessagesLength;

        if (!count) return;
        if (scrollBox?.current?.lastChild.offsetHeight > scrollBox?.current?.offsetHeight) {
          dispatch(setHasNewFeedItems(true, count + feed[tab].newItemsCount, tab));
        }

        if (Notification.permission === 'granted' && document.visibilityState === 'hidden') {
          uniqMessages.forEach((message) => {
            addNativeNotification({
              // message: window.t('newMessagesPush').replace(/\[count\]/g, count),
              image: message.image || getImageByChannel(message.channel),
              message: message.content,
              title: message.author
            });
          });
        }
      }
    } catch (ex) {
      console.error({ ex });
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errMessagesLoading')
        })
      );
    }
  };
}

export function onScrollBox(scrollTop, tab, offsetHeight, scrollHeight) {
  return (dispatch, getState) => {
    const { isNewAtTop } = getState().settings.messageFeedConfig;
    const feed = getState().feed;
    const hasNewItems = feed[tab]?.hasNewItems;

    if (
      hasNewItems &&
      ((scrollTop + offsetHeight + 2 >= scrollHeight && !isNewAtTop) ||
        (scrollTop === 0 && isNewAtTop))
    ) {
      dispatch(setHasNewFeedItems(false));
    }
  };
}

/* MESSAGE THUNK */
export function setFavState(id, value, senderNumber, currentTab) {
  return async (dispatch) => {
    try {
      if (currentTab === TABS.messages) {
        await setMessageFavoriteState({ id, value });
      }
      dispatch(setFeedItemFavStatus(id, value, senderNumber, false, currentTab));
      if (value === false) dispatch(executeFilterMessages());
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errMessageToFavorites')
        })
      );
      console.error(ex);
    }
  };
}

export function setSocketFavState(id, value, senderNumber, updatedFromSocket = false, tab) {
  return (dispatch, getState) => {
    const currentTab = getState().feed.currentTab;
    dispatch(setFeedItemFavStatus(id, value, senderNumber, updatedFromSocket, tab));
    if (tab && tab !== currentTab) return;
    dispatch(executeFilterMessages());
  };
}

export function setMinimizedState(id, value, senderNumber) {
  return async (dispatch, getState) => {
    try {
      const currentTab = getState().feed.currentTab;
      if (currentTab === TABS.messages) {
        await setMessageMinimizedState({ id, value });
      }
      dispatch(setFeedItemMinimizedStatus(id, value, senderNumber));
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errMessageToMininmize')
        })
      );
    }
  };
}

export function deleteMessage(id, senderNumber) {
  return async (dispatch, getState) => {
    try {
      const pinedAutors = getState().pinnedAuthors.authors;
      let arr = [];
      pinedAutors.map((el) => el.senderNumber).map((el) => arr.push(el));
      if (arr.find((senNum) => senNum === senderNumber)) {
        dispatch(
          addSnack({
            type: 'danger',
            message: window.t('errCantDeletePinnedMessage')
          })
        );
        return;
      }

      const timeoutToDel = setTimeout(async () => {
        await deleteFeedMessage(id);
        dispatch(removeFeedItem(id, senderNumber));
      }, 6000);

      dispatch(
        addSnack({
          type: 'success',
          message: window.t('sccMessageDeleted'),
          cancelCallback: () => {
            clearTimeout(timeoutToDel);
            dispatch(setFeedItemHiddenState(id, false, senderNumber));
          }
        })
      );
      dispatch(setFeedItemHiddenState(id, true, senderNumber));
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errDeletingMessage')
        })
      );
    }
  };
}

export function recoveryMessage(id, timeoutToDel, senderNumber) {
  return async (dispatch) => {
    try {
      await recoveryFeedMessage(id);
      if (timeoutToDel) {
        clearTimeout(timeoutToDel);
      }
      dispatch(setFeedItemHiddenState(id, false, senderNumber));
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errRestoringMessage')
        })
      );
    }
  };
}

export function restoreMessages({ author, channel, senderNumber }) {
  return async (dispatch, getState) => {
    const { currentTab, ...state } = getState().feed;
    const { items } = state[currentTab];
    const messagesToRestore = items.filter(
      (el) => el.author === author && el.channel === channel && el.senderNumber && senderNumber
    );
    messagesToRestore.forEach((el) => {
      dispatch(setFeedItemHiddenState(el.id, false, senderNumber));
    });
  };
}

export function setMessageUserMessagesHiddenState({ channel, senderNumber, author }, value) {
  return (dispatch, getState) => {
    const { currentTab, ...state } = getState().feed;
    const { items } = state[currentTab];

    const toHide = items.filter(
      (el) => el.channel === channel && el.senderNumber === senderNumber && el.author === author
    );
    for (const { id, senderNumber } of toHide) {
      dispatch(setFeedItemHiddenState(id, value, senderNumber));
    }
  };
}

export function addLabel(id, label) {
  return async (dispatch, getState) => {
    try {
      const { currentTab, ...state } = getState().feed;
      const { items } = state[currentTab];
      const message = items.find((el) => el.id === id);
      const labels = [...message.labels];
      labels.push(label);
      await toggleFeedMessageLabel({ id, label: labels.join(',') });
      dispatch(addLabelSuccess(id, label, message.senderNumber));
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errAddingLabel')
        })
      );
    }
  };
}

export function setLabelsSocket(id, labels, tab) {
  return async (dispatch, getState) => {
    const { currentTab, ...state } = getState().feed;
    const { items } = state[tab || currentTab];
    const message = items.find((el) => el.id === id);
    dispatch(setLabelsSuccess(id, labels, message.senderNumber, true, tab));
    dispatch(executeFilterMessages());
  };
}

export function deleteLabel(id, label) {
  return async (dispatch, getState) => {
    try {
      const { currentTab, ...state } = getState().feed;
      const { items } = state[currentTab];
      const message = items.find((el) => el.id === id);
      let labels = [...message.labels];
      labels = labels.filter((el) => el !== label);
      await toggleFeedMessageLabel({ id, label: labels.join(',') });
      dispatch(deleteLabelSuccess(id, label, message.senderNumber));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function clearLabels(id, senderNumber) {
  return async (dispatch) => {
    try {
      await clearFeedMessageLabels(id);
      dispatch(clearLabelsSuccess(id, senderNumber));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function sendResponseMessage(...args) {
  return async (dispatch, getState) => {
    const currentTab = getState().feed.currentTab;
    switch (currentTab) {
      case TABS.messages:
        dispatch(sendResponseMessageForMessages(...args));
        return;
      case TABS.news:
        dispatch(sendResponseMessageForNews(...args));
        return;
      default:
        return;
    }
  };
}

export function sendResponseMessageForNews(receiver, reply) {
  return async (dispatch, getState) => {
    const { like } = getState().settings.messageFeedConfig;
    let { text, type } = reply;
    const randomId = Math.random();
    let id = randomId;
    dispatch(setIsSendingMessage(true));
    // if (type === "like" && !like.length) {
    //   dispatch(
    //     addSnack({
    //       type: "danger",
    //       message: window.t("likeParamNotSet"),
    //     })
    //   );
    //   return;
    // }

    if (type === 'like') {
      text = like[Math.floor(Math.random() * like.length)]?.additional_text;
    }

    try {
      dispatch(setIsSendingMessage(false));
      if (type === 'message') {
        dispatch(
          addResponseMessage(
            receiver.id,
            {
              message_type: 'message',
              out_message: text,
              attachment: reply.attachments || [],
              id
            },
            receiver.senderNumber
          )
        );
      }

      if (type === 'like') {
        dispatch(
          setFeedItemLikeStatus(receiver.id, reply.attachments || [], receiver.senderNumber)
        );
      }
    } catch (ex) {
      console.error(ex);

      dispatch(setIsSendingMessage(false));
      dispatch(
        addSnack({
          type: 'danger',
          message: ex.message
        })
      );
    }
  };
}

export function sendResponseMessageForMessages(receiver, reply) {
  return async (dispatch, getState) => {
    const { like } = getState().settings.messageFeedConfig;
    let { text, type } = reply;
    const randomId = Math.random();
    let id = randomId;
    dispatch(setIsSendingMessage(true));
    if (type === 'like' && !like.length) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('likeParamNotSet')
        })
      );
      return;
    }

    if (type === 'like') {
      text = like[Math.floor(Math.random() * like.length)]?.additional_text;
    }

    try {
      const response = await sendMessageToMessenger(
        {
          text,
          type,
          messageId: receiver.id,
          senderNumber: receiver.senderNumber,
          channel: receiver.channel,
          attachment: receiver.attachment
        },
        'attachment'
      );

      if (response.code !== 200) {
        throw new Error('Сообщение не было отправлено!');
      }

      dispatch(setIsSendingMessage(false));
      id = response.body.id;
      const attachments = response.body.attachments;
      if (type === 'message') {
        dispatch(
          addChatMessages(receiver.id, [
            {
              id,
              attachments,
              content: text,
              author: '79123595584',
              date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
              favorite: '',
              image: 'http://mb.iactive.pro/bot_itr/avatar/79123595584_viber.jpg',
              region: '',
              senderNumber: '79123595584',
              own: 1,
              isNew: true
            }
          ])
        );
        dispatch(
          addResponseMessage(
            receiver.id,
            {
              message_type: 'message',
              out_message: text,
              attachment: attachments,
              id
            },
            receiver.senderNumber
          )
        );
      }

      if (type === 'like') {
        dispatch(setFeedItemLikeStatus(receiver.id, response.body.likeText, receiver.senderNumber));
        dispatch(
          addChatMessages(receiver.id, [
            {
              content: response.body.likeText,
              date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
              id,
              own: 1,
              isNew: true
            }
          ])
        );
      }
    } catch (ex) {
      console.error(ex);

      dispatch(setIsSendingMessage(false));
      dispatch(
        addSnack({
          type: 'danger',
          message: ex.message
        })
      );
    }
  };
}

export function readMessage(senderNumber) {
  return async (dispatch, getState) => {
    try {
      const interactionDate = getLocalISOString(new Date());
      const response = await requestApiPost('api_interaction_status', {
        senderNumber,
        interactionDate
      });
      if (response.status !== 'ok') throw new Error();

      const state = getState();
      const { feed, broadcast } = state;
      const feedMessages = feed[feed.currentTab].items || [];

      const broadcastMessages = broadcast.messages || [];
      if (feedMessages.some((msg) => msg.senderNumber === senderNumber)) {
        const updatedMessages = feedMessages.map((msg) =>
          msg.senderNumber !== senderNumber ? msg : { ...msg, lastDate: interactionDate }
        );
        dispatch(fetchFeedItemsSuccess(updatedMessages));

        const targetAuthor = state.settings.authors?.find(
          (author) => author.senderNumber === senderNumber
        );
        if (targetAuthor) {
          const authorMessages = targetAuthor.messages.map((msg) => ({
            ...msg,
            lastDate: interactionDate
          }));
          dispatch(updatePinnedAuthorMessages(senderNumber, authorMessages));
        } else {
          const { profiles, currentProfile } = state.profile;
          const targetProfiles = profiles.filter((profile) =>
            profile.settings.authors?.some((author) => author.senderNumber === senderNumber)
          );

          if (targetProfiles.length) {
            for (const profile of targetProfiles) {
              if (profile.id === currentProfile.id) continue;
              const targetAuthor = profile.settings.authors?.find(
                (author) => author.senderNumber === senderNumber
              );

              if (targetAuthor) {
                dispatch(
                  editProfile({
                    id: profile.id,
                    settings: settingsReducer(
                      profile.settings,
                      updatePinnedAuthorMessages(
                        senderNumber,
                        targetAuthor.messages.map((msg) => ({
                          ...msg,
                          lastDate: interactionDate
                        }))
                      )
                    )
                  })
                );

                let authors = settingsReducer(
                  profile.settings,
                  updatePinnedAuthorMessages(
                    senderNumber,
                    targetAuthor.messages.map((msg) => ({
                      ...msg,
                      lastDate: interactionDate
                    }))
                  )
                ).authors;
                authors = authors.map((author) => ({
                  ...author,
                  messages: uniqBy(author.messages, 'id')
                }));

                editProfileApi({
                  profileId: profile.id,
                  parametr: 'settings',
                  value: {
                    authors: settingsReducer(
                      profile.settings,
                      updatePinnedAuthorMessages(
                        senderNumber,
                        targetAuthor.messages.map((msg) => ({
                          ...msg,
                          lastDate: interactionDate
                        }))
                      )
                    ).authors
                  }
                }).catch((error) => {
                  console.error(error);
                });
              }
            }
          }
        }

        dispatch(fetchFeedItemsSuccess(updatedMessages));
      }
      if (broadcastMessages.some((msg) => msg.senderNumber === senderNumber)) {
        const updatedMessages = broadcastMessages.map((msg) =>
          msg.senderNumber !== senderNumber ? msg : { ...msg, lastDate: interactionDate }
        );
        dispatch(
          broadcastActions.fetchBroadcastMessagesSuccess({
            messages: updatedMessages
          })
        );
      }
    } catch (ex) {
      console.error(ex);
    }
  };
}

const formatItemByTabType = {
  messages: (item) => item,
  news: (item, last_create_in_db, last_pubDate) => ({
    ...item,
    id: Math.random(),
    created_in_DB: last_create_in_db,
    create_in_db: last_create_in_db,
    pubDate: item.pubDate || last_pubDate
  }),
  advertisements: (item) => item,
  weather: (item) => item
};

export function addNewFeedItem(formItem, scrollBoxes) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentTab = state.feed.currentTab;

    const formattedItem = formatItemByTabType[currentTab](
      formItem,
      state.feed.news.items[0]?.created_in_DB || state.feed.news.items[0]?.create_in_db,
      state.feed.news.items[0]?.pubDate
    );

    dispatch(setItems([formattedItem], scrollBoxes[currentTab], true, currentTab));
  };
}
