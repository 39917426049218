import PinInteractivesList from './PinnedInteractives/PinInteractivesList';
import SessionRow from './SessionRow/SessionRow';
import SessionSkeleton from './SessionSkeleton/SessionSkeleton';
import Toolbar from './Toolbar/Toolbar';
import { debounce, range } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Icon } from 'components';

import useActions from 'hooks/useActions';
import useFetch from 'hooks/useFetch';
import useLocalStorage from 'hooks/useLocalStorage';

import { MainGuideContext } from 'providers/MainGuideProvider';

import { copyInteractive, createInteractive, deleteInteractive } from 'api/dataLayer';

import { confirm } from 'helpers/common';
import {
  filterByInteractives,
  filterByText,
  getConfirmText,
  getSessionData,
  hasActiveBroadcastId
} from 'helpers/interactives';
import { goTo } from 'helpers/routing';

import styles from './Sessions.module.css';

const propTypes = {
  hasInitialData: PropTypes.bool.isRequired
};

const defaultProps = {
  hasInitialData: false
};

const option = { withCache: true };

export function Sessions({ hasInitialData }) {
  const {
    addSnack,
    createSessionSuccess,
    removeSessions,
    setFilteredSessions,
    setSessions,
    setIsLoadingSessions,
    setActiveBroadcastData,
    setPinnedInteractives,
    updatePinInteractive,
    setScrollPosition,
    setIsSetSessions,
    // обновление settings профиля
    updateProfileSettings
  } = useActions();

  const history = useHistory();
  const location = useLocation();
  const [{ response, isLoading, error }, doFetch, doForceFetch] = useFetch(
    'api_get_interactives',
    option
  );

  const { sessions, enableInteractives, scrollPosition } = useSelector((state) => state.sessions);
  const settings = useSelector((state) => state.settings);
  const { interactives: pinInteractives } = useSelector((state) => state.pinnedInteractives);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [searchText, setSearchText] = useLocalStorage('sessionSearchText', ''); // useState('');
  const [isFiltered, setIsFiltered] = useState(false);
  const [interactives, setInteractives] = useLocalStorage('interactives', [
    {
      type: 'vote',
      title: 'voting',
      img: 'module-vote.png',
      description: 'votingDesc',
      isActive: true
    },
    {
      type: 'quiz',
      title: 'quiz',
      img: 'module-quiz.png',
      description: 'quizDesc',
      isActive: true
    },
    {
      type: 'broadcast',
      title: 'broadcasting',
      img: 'module-tv.png',
      description: 'broadcastingDesc',
      isActive: true
    },
    // {
    //   type: "pulse",
    //   title: "pulse",
    //   img: "module-pulse.png",
    //   description: "pulseDesc",
    //   isActive: true,
    // },
    {
      type: 'content',
      title: 'news',
      img: 'module-content.png',
      description: 'newsDesc',
      isActive: true
    },
    {
      type: 'autoreply',
      title: 'autoreplyTitle',
      img: 'module-autoreply.png',
      description: 'autoreplyDesc',
      isActive: true
    },
    // регистрация участников мероприятий
    {
      type: 'registration',
      title: 'registration',
      img: 'module-registration.png',
      description: 'registrationDesc',
      isActive: true
    }
  ]);

  // для минимизациии информации об закрепленном интерактиве, только id и type
  useEffect(() => {
    if (!settings) return;
    let settingsPinInteractives = settings?.pinnedInteractives || [];

    if (settingsPinInteractives.length !== 0) {
      const isFullInteractive = settingsPinInteractives.some((el) => Object.keys(el).length > 2);
      if (isFullInteractive) {
        let minimizeInteractive = settingsPinInteractives.map((i) => ({
          id: i.id,
          type: i.type
        }));

        updateProfileSettings({ pinnedInteractives: minimizeInteractive });
      }
    }
    return;
  }, [settings]);

  const scrollBox = useRef(null);
  // прокрутка страницы
  useEffect(() => {
    if (scrollBox.current) {
      scrollBox.current.scrollTo({ top: scrollPosition, behavior: 'instant' });
    }
  }, [scrollBox.current]);

  // добавление закрепленных интерактивов в store из поля настроек профиля
  useEffect(() => {
    if (!settings) return;
    setPinnedInteractives(settings?.pinnedInteractives || []);
  }, [settings]);

  // Список закрепленных интерактивов (полные данные об интерактивах)
  const listPinnedInteractives = useMemo(() => {
    if (sessions?.length === 0 || pinInteractives?.length === 0) {
      return [];
    } else {
      return pinInteractives.map((el) => {
        const item = sessions.find((session) => session.id === el.id && session.type === el.type);
        if (item) {
          return item;
        }
      });
    }
  }, [pinInteractives, sessions]);

  useEffect(() => {
    setIsLoadingSessions(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!hasInitialData || isLoaded) return;
    doFetch({
      method: 'GET'
    });
    setIsLoaded(true);
  }, [hasInitialData, doFetch]);

  useEffect(() => {
    if (!response) return;
    if (sessions.length) return;

    let filtered = response.filter((el) => enableInteractives.includes(el.type));
    // флаг - что данные, из запроса на список интерактивов добавлены в store
    setIsSetSessions(true);
    setSessions(filtered);
    filterSessions(filtered);
  }, [response]);

  // todo: remove selection by pressing again
  const selectSessionCb = useCallback((sessionId, isMultiple) => {
    setSelectedSessions((prev) => {
      if (prev.includes(sessionId[0])) {
        return prev;
      }
      return isMultiple ? [...prev, ...sessionId] : [...sessionId];
    });
  }, []);

  const openSession = useCallback(({ id, type }, data = null) => {
    goTo(history, `/${type}/${id}`, data, location);
  }, []);

  const createSession = useCallback(async (type) => {
    try {
      const response = await createInteractive({
        type,
        title: '',
        description: '',
        memo: ''
      });
      const session = getSessionData(type, response);
      // setSessions([session, ...sessions]);
      createSessionSuccess(session);
      openSession(session, { ...session, isCreated: true });
    } catch (ex) {
      addSnack({
        type: 'danger',
        message: window.t('anErrorAccruedDuringAnInteractiveCreation')
      });
    }
  }, []);

  const LengthIsNotDuplicate = useMemo(
    () =>
      selectedSessions.length - selectedSessions.filter((el) => el.includes('duplicate')).length,

    [selectedSessions]
  );

  const removeSelectedSessions = useCallback(async () => {
    const selectedCount = LengthIsNotDuplicate;

    if (selectedCount === 0) return;
    const confirmText = getConfirmText(sessions, selectedSessions, selectedCount);
    const idsToDel = [];

    if (confirm(confirmText)) {
      const toDel = {};
      try {
        for (const session of selectedSessions) {
          const typeId = session.split('_');
          const type = typeId[0];
          const id = typeId[1];
          if (toDel[type]) toDel[type].push(id);
          else toDel[type] = [id];
        }
        for (const [type, ids] of Object.entries(toDel)) {
          await deleteInteractive({
            type,
            param1: ids.join()
          });
          idsToDel.push(...ids);
          if (type === 'broadcast') {
            if (hasActiveBroadcastId(sessions, ids)) setActiveBroadcastData(null);
          }
        }
      } catch (ex) {
        console.error(ex);
      }
      removeSessions(idsToDel);
      setSelectedSessions([]);
      // удаление из списка закрепленных интерактивов
      idsToDel.forEach((id) => {
        const interactive = pinInteractives.find((el) => el.id === id);
        if (!interactive) return;
        togglePinHandler(interactive);
      });

      addSnack({
        type: 'success',
        message: window.t('interactiveRemovedSuccessfully')
      });
    }
  }, [LengthIsNotDuplicate, selectedSessions]);

  const filterSessions = (sessionsList = null) => {
    const toFilter = sessionsList || sessions;

    if (toFilter.length === 0) return;

    const filtered = filterByInteractives(toFilter, interactives);
    const toShow = filterByText(filtered, searchText).map((el) => el.id);

    const activeInteractives = [...interactives].filter((el) => el.isActive).map((el) => el.type);
    setIsFiltered(
      (activeInteractives.length !== interactives.length && activeInteractives.length !== 0) ||
        toShow.length !== toFilter.length
    );
    // setSessions(toFilter => toFilter.map(el => toShow.includes(el.id)
    //   ? { ...el, isHidden: false }
    //   : { ...el, isHidden: true }
    // ));
    const formatedInteractiveList =
      activeInteractives.length === interactives.length || activeInteractives.length === 0
        ? interactives.map((el) => el.type)
        : activeInteractives;

    setFilteredSessions(toShow, formatedInteractiveList);
  };

  useEffect(() => {
    filterSessions();
  }, [sessions?.length, searchText, interactives]);

  const copySession = useCallback(async () => {
    const session = selectedSessions[0];
    const [type, iactive_id] = session.split('_');

    try {
      const response = await copyInteractive({
        type,
        iactive_id
      });
      const session = getSessionData(type, response);
      // setSessions([session, ...sessions]);
      createSessionSuccess(session);
      openSession(session, { ...session, isCreated: true });
    } catch (ex) {
      addSnack({
        type: 'danger',
        message: window.t('anErrorAccruedDuringAnInteractiveCreation')
      });
    }
  }, [selectedSessions, addSnack, createSessionSuccess]);

  // Скрытие дублирующих трансляций викторины и голосования
  const hiddenBroadcastId = useMemo(() => {
    return sessions.filter((el) => el.broadcastId).map((el) => el.broadcastId);
  }, [sessions]);
  const sessionsIsNotHidden = useMemo(() => {
    return sessions.filter((el) => el.type !== 'broadcast' || !hiddenBroadcastId.includes(el.id));
  }, [sessions]);

  const togglePinHandler = useCallback((el) => {
    updatePinInteractive(el);
  }, []);

  const setSearchTextCb = useCallback(setSearchText, [searchText]);

  const setInteractivesCb = useCallback(setInteractives, [interactives]);

  const refreshSessions = useCallback(() => {
    doForceFetch({
      method: 'GET'
    });
  }, []);

  const debounceSetScrollPosition = debounce((scroll) => {
    setScrollPosition(scroll);
  }, 500);

  // список интерактивов без закрепленных
  const listInteractives = useMemo(() => {
    return sessionsIsNotHidden.filter(
      (el) =>
        el.isHidden === false &&
        !pinInteractives.find((int) => int && int.id === el.id && int.type === el.type)
    );
  }, [sessionsIsNotHidden, pinInteractives?.length]);

  // Guide
  const {
    setStepIndex,
    interactiveToolbarRef,
    interactiveSearchBtnRef,
    interactiveSearchMenuRef,
    stepIndex
  } = useContext(MainGuideContext);

  useEffect(() => {
    if (stepIndex > 5) {
      const activeInteractives = interactives.filter(({ type, isActive }) => isActive);
      if (activeInteractives.length === 1 && activeInteractives[0].type === 'broadcast') {
        setStepIndex((v) => ++v);
      }
    }
  }, [interactives]);

  return (
    <div className={styles.workContent}>
      <Toolbar
        refreshSessions={refreshSessions}
        createSession={createSession}
        removeSelectedSessions={removeSelectedSessions}
        searchText={searchText}
        setSearchText={setSearchTextCb}
        isFiltered={isFiltered}
        interactives={interactives}
        setInteractives={setInteractivesCb}
        copySession={copySession}
        selectedSessionsLength={LengthIsNotDuplicate}
        // Guide
        setStepIndex={setStepIndex}
        interactiveToolbarRef={interactiveToolbarRef}
        interactiveSearchMenuRef={interactiveSearchMenuRef}
        interactiveSearchBtnRef={interactiveSearchBtnRef}
        stepIndex={stepIndex}
      />

      <ul
        className={styles.listbox}
        ref={scrollBox}
        onScroll={(e) => {
          debounceSetScrollPosition(e.target.scrollTop);
        }}>
        {isLoading ? (
          range(10).map((el) => <SessionSkeleton key={el} index={el} />)
        ) : (
          <>
            {pinInteractives?.length !== 0 && (
              <li>
                <PinInteractivesList
                  togglePinHandler={togglePinHandler}
                  selectSessionCb={selectSessionCb}
                  openSession={openSession}
                  selectedSessions={selectedSessions}
                  listPinnedInteractives={listPinnedInteractives}
                />
              </li>
            )}
            {listInteractives?.length !== 0 &&
              listInteractives.map((item) => {
                return (
                  <SessionRow
                    isSelected={selectedSessions.includes(`${item.type}_${item.id}`)}
                    selectSessionCb={selectSessionCb}
                    openSession={openSession}
                    rowData={item}
                    togglePinHandler={togglePinHandler}
                    key={`${item.type}_${item.id}`}
                    isPinnedInteractive={false}
                  />
                );
              })}
          </>
        )}
      </ul>
      <Button
        className={styles.scrollTopBtn}
        onlyIcon
        variant="icon"
        color="#989898"
        startIcon={<Icon size={16} fontName="fas fa-chevron-up" />}
        clickHandler={(e) => {
          scrollBox.current.scrollTo({ top: 0, behavior: 'instant' });
          e.currentTarget.style.display = 'none';
        }}
        style={{ display: scrollPosition > 799 ? 'flex' : 'none' }}
      />
    </div>
  );
}

Sessions.propTypes = propTypes;
Sessions.defaultProps = defaultProps;
