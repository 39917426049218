import { checkCountLines } from '../ContentFields/helpers/validationFields';
import FormattedTextPreview from '../FormattedTextPreview/FormattedTextPreview';
import { TextareaLengthInfoView } from '../TextareaLengthInfoView/TextareaLengthInfoView';
import { useMemo, useState } from 'react';
import sanitizeHtml from 'sanitize-html';

import { Text, Textarea } from 'components';
import { TextEditor } from 'components/TextEditor/TextEditor';

import { useActions } from 'hooks';

import { MAX_COUNT_LINES } from 'store/constants';

import styles from './StringWithPreviewElement.module.css';

export function StringWithPreviewElement({
  value,
  block,
  maxLength,
  locale,
  elementKey: key,
  textFromSpeech
}) {
  const { updateContentBlockField } = useActions();

  const [isShowPreview, setIsShowPreview] = useState(false);
  const [isValidPreview, setIsValidPreview] = useState(true);

  let TextEditorComponent;

  // наличие в блоке исходного текста, с разметкой(переносы строк) клиента
  const isTextHyphens = value.value?.textHyphens !== undefined;
  // чекбокс вкл/выкл автоматического переноса текста
  const isAutoHyphens = value.isAutoHyphens !== undefined ? value.isAutoHyphens : true;

  const isValidCountLines = useMemo(
    () => checkCountLines(value.value.text ?? value.value, value.maxCountLines),
    [value.value.text ?? value.value]
  );

  const length = isTextHyphens
    ? sanitizeHtml(value.value?.textHyphens || '').replaceAll('\n', '')?.length
    : sanitizeHtml((value.value?.text ?? value.value) || '').replaceAll('\n', '')?.length;

  if (isTextHyphens) {
    TextEditorComponent = (
      <>
        <TextEditor
          locale={locale}
          elementKey={key}
          isValidPreview={isTextHyphens ? true : isValidPreview}
          textFromSpeech={textFromSpeech}
          text={value.value}
          value={value}
          updateContentBlockField={updateContentBlockField}
          block={block}
          textEffects={value.text_effects}
          setText={(newVal) => updateContentBlockField(block?.id, key, newVal)}
          blurHandler={(e) => {
            // request to update json on back
            setIsShowPreview(false);
          }}
          focusHandler={(e) => {
            setIsShowPreview(true);
          }}
          changeHandler={(e) => {}}
          afterLabel={
            <TextareaLengthInfoView
              {...((maxLength || length) && { length })}
              {...(maxLength && { maxLength })}
            />
          }
          focusable={false}
          label={value.label?.[locale] || window.t(key)}
          className={styles.textAreaBlock}
          isTextHyphens={isTextHyphens}
          isAutoHyphens={isAutoHyphens}
          isValidCountLines={isValidCountLines}
        />
      </>
    );
  } else {
    // для открытия страрых новостей, без автопереноса
    TextEditorComponent = (
      <>
        <Textarea
          elementKey={key}
          isValidPreview={isValidPreview}
          value={value}
          block={block}
          updateContentBlockField={updateContentBlockField}
          text={value.value?.text !== undefined ? value.value?.text : value.value}
          setText={(newVal) => updateContentBlockField(block?.id, key, newVal)}
          blurHandler={() => {
            setIsShowPreview(false);
          }}
          focusHandler={() => {
            setIsShowPreview(true);
          }}
          changeHandler={(e) => {}}
          afterLabel={<TextareaLengthInfoView length={length} maxLength={maxLength} />}
          focusable={false}
          label={value.label?.[locale] || window.t(key)}
          className={styles.textAreaBlock}
        />
      </>
    );
  }

  const { fontFamily, textWidth, fontSize, fontWeight, isUpperCase, fontStyle } = value;

  return (
    <>
      {TextEditorComponent}
      {!isValidCountLines && (
        <Text tag="div" color="#ff2525">
          {window.t('allowedNumberOfLines')}
          {value.maxCountLines || MAX_COUNT_LINES}
        </Text>
      )}
      {/* для открытия страрых новостей, без автопереноса */}
      {!isTextHyphens && (
        <FormattedTextPreview
          label={`${value.label?.[locale] || window.t(key)} ${window.t('preview')}`}
          text={value.value}
          font={fontFamily}
          fontStyle={fontStyle || null}
          width={Number(textWidth)}
          fontSize={Number(fontSize)}
          fontWeight={Number(fontWeight)}
          isUpperCase={isUpperCase}
          isShow={isShowPreview}
          isValidPreview={isValidPreview}
          setIsValidPreview={setIsValidPreview}
        />
      )}
    </>
  );
}
