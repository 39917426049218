import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Button, Icon, Skeleton } from 'components';

import useActions from 'hooks/useActions';

import { searchProfileBanned } from 'api/dataLayer';

const propTypes = {
  routeInfo: PropTypes.object.isRequired,
  blockedId: PropTypes.number
};

const defaultProps = {
  routeInfo: {}
};

function ToolbarControls({ routeInfo, author, userName, isLoading, profileId }) {
  const { blockAuthor, unblockAuthor, addSnack, blockAuthorSuccess } = useActions();
  const { blockedId } = useSelector((state) => state.profileCard);

  const { channel, senderNumber } = routeInfo || {};

  const checkIfAuthorBanned = useCallback(
    async (senderNumber) => {
      try {
        const response = await searchProfileBanned(senderNumber);
        const target = response?.Authors?.find((item) => item.senderNumber === senderNumber);
        blockAuthorSuccess(+target?.id || null);
      } catch (error) {
        console.error(error);
      }
    },
    [blockAuthorSuccess]
  );

  useEffect(() => {
    if (!senderNumber) return;
    checkIfAuthorBanned(senderNumber);
  }, [senderNumber, checkIfAuthorBanned]);

  const authorActionHandler = useCallback(
    (type) => () => {
      if (author) {
        switch (type) {
          case 'block':
            blockAuthor(channel, senderNumber, author);
            break;
          case 'unblock':
            unblockAuthor(channel, senderNumber, author);
            break;
          default:
            throw new Error('unexpected type');
        }
      } else {
        addSnack({
          type: 'danger',
          message: window.t('createdAuthor')
        });
      }
    },
    [blockAuthor, unblockAuthor, channel, senderNumber, author, addSnack]
  );

  const link = useMemo(() => {
    const availableChannels = [
      'whatsapp',
      'viber',
      'telegram',
      'telegrambot',
      'vk',
      'vkcomm',
      'vkgroup',
      'vkcomments',
      'ok',
      'okgroup',
      'sms',
      'smsMoney',
      'live',
      'livechat',
      'tgcomm'
    ];
    if (!availableChannels.includes(routeInfo.channel)) return;
    switch (routeInfo.channel) {
      case 'viber':
        return `viber://chat?number=${routeInfo.senderNumber}`;
      case 'live':
      case 'livechat':
        return `https://direct.lc.chat/${routeInfo.senderNumber}`;
      case 'yt_live':
        return `https://www.youtube.com/c/${userName || routeInfo.senderNumber}`;
      case 'ok':
      case 'okgroup':
        return `https://ok.ru/messages/${routeInfo.senderNumber}`;
      case 'telegram':
      case 'tgcomm':
      case 'telegrambot':
        return userName && `https://t.me/${userName || routeInfo.senderNumber}`;
      case 'vk':
      case 'vkcomm':
      case 'vkcomments':
      case 'vkgroup':
        return `https://vk.com/write${routeInfo.senderNumber}`;
      case 'fb':
      case 'facebook':
      case 'fb-messenger':
        return `https://m.me/${userName || routeInfo.senderNumber}`;
      case 'whatsapp':
        return `https://web.whatsapp.com/send?phone=${routeInfo.senderNumber}&amp;text=&amp;source=&amp;data=`;
      case 'sms':
      case 'smsMoney':
      case 'smsrub':
      case 'smsRub':
        return `sms:${routeInfo.senderNumber}`;
      case 'instagram':
      case 'instagramcomments':
      case 'fbinstagram':
        return `https://www.instagram.com/${userName || routeInfo.senderNumber}`;
      default:
        return null;
    }
  }, [routeInfo, userName]);

  const handleClick = useCallback(
    (event) => {
      if (author) {
        if (link) return;
        event.preventDefault();
        addSnack({
          type: 'danger',
          message: window.t('unavailableUserName')
        });
      } else {
        event.preventDefault();
        addSnack({
          type: 'danger',
          message: window.t('createdAuthor')
        });
      }
    },
    [author, addSnack, link]
  );

  return (
    <ul className="d-flex ml-auto">
      {routeInfo.senderNumber && isLoading && (
        <>
          <Skeleton style={{ marginRight: 20 }} width={110} height={23} borderRadius={12} />
          <Skeleton width={140} height={23} borderRadius={12} />
        </>
      )}
      {routeInfo.senderNumber && !isLoading && (
        <>
          <a
            href={link}
            {...(!routeInfo.channel.includes('sms') && {
              target: '_blank',
              rel: 'noreferrer'
            })}>
            <Button
              startIcon={<Icon size={16} fontName="fa fa-comment" />}
              clickHandler={handleClick}
              variant="icon"
              color={link && author ? '#5882d4' : '#aaaaaa'}
              // isDisabled={!link}
              size="large"
              style={{ padding: '6px 8px', opacity: 1 }}>
              {window.t('write')}
            </Button>
          </a>
          {blockedId ? (
            <Button
              startIcon={<Icon size={16} fontName="fa fa-user-plus" />}
              variant="icon"
              color={author ? '#2e7d32' : '#aaaaaa'}
              clickHandler={authorActionHandler('unblock')}
              size="large"
              style={{ padding: '6px 8px', opacity: 1 }}>
              {window.t('unblock')}
            </Button>
          ) : (
            <Button
              startIcon={<Icon size={16} fontName="fa fa-user-times" />}
              variant="icon"
              color={author ? '#b71c1c' : '#aaaaaa'}
              size="large"
              clickHandler={authorActionHandler('block')}
              style={{ padding: '6px 8px', opacity: 1 }}>
              {window.t('block')}
            </Button>
          )}
        </>
      )}
    </ul>
  );
}

export default ToolbarControls;

ToolbarControls.propTypes = propTypes;
ToolbarControls.defaultProps = defaultProps;
