import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { Card } from 'components';

import { ContentFields } from './components/ContentFields/ContentFields';
import { Header } from './components/Header/Header';

import styles from './NewsBlock.module.css';

const propTypes = {
  index: PropTypes.number,
  blocks: PropTypes.array,
  removeBlock: PropTypes.func,
  moveUp: PropTypes.func,
  moveDown: PropTypes.func,
  block: PropTypes.object
};

export const NewsBlock = memo(
  ({
    order,
    contentId,
    isFirst,
    isLast,
    removeBlock,
    moveUp,
    moveDown,
    block,
    setStepIndex,
    newsBlockRef,
    textToSpeechRef,
    blockTypes,
    content_rule,
    initialAudioFiles,
    blocks,
    activeBlocksLength
  }) => {
    const { isMinimized, isActive } = block;
    const blockMinimizedPreviewRef = useRef(null);
    const [minCardHeight, setMinCardHeight] = useState(62);

    useEffect(() => {
      setMinCardHeight(blockMinimizedPreviewRef.current.offsetHeight + 16 * 2);
    }, [isMinimized, block?.caption?.value, block?.text?.value]);

    return (
      <div ref={newsBlockRef}>
        <AnimateHeight duration={600} height={isMinimized ? minCardHeight : 'auto'}>
          <Card
            className={cn(styles['news-card'], {
              disabled: !isActive
            })}
            style={{ borderRadius: 2 }}>
            <Header
              order={order}
              blockMinimizedPreviewRef={blockMinimizedPreviewRef}
              block={block}
              isFirst={isFirst}
              isLast={isLast}
              removeBlock={removeBlock}
              moveDown={moveDown}
              moveUp={moveUp}
            />
            <ContentFields
              contentId={contentId}
              content_rule={content_rule}
              block={block}
              setStepIndex={setStepIndex}
              blockTypes={blockTypes}
              initialAudioFiles={initialAudioFiles}
              textToSpeechRef={textToSpeechRef}
              activeBlocksLength={activeBlocksLength}
              blocks={blocks}
            />
          </Card>
        </AnimateHeight>
      </div>
    );
  }
);

NewsBlock.propTypes = propTypes;
