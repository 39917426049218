import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularLoader,
  Input,
  RoundCheckbox,
  Text,
  Title
} from 'components';

import useActions from 'hooks/useActions';
import useFetch from 'hooks/useFetch';

import { LocaleContext } from 'providers/LocaleProvider';

import { getUIDateFromDBDate } from 'helpers/dateTime';
import { getImageByChannel } from 'helpers/image';

import styles from './SocialLinksModal.module.css';

const propTypes = {
  activeChannel: PropTypes.string
};

const option = { dataKey: null };

function SocialLinksModal({ activeChannel }) {
  const {
    setPosts,
    togglePost,
    toggleAllPosts,
    filterBySearchText,
    resetSocialsState,
    makeChannelActive,
    exportCommentsToBroadcast
  } = useActions();
  const {
    id: idInteractive,
    broadcastId: idOpenBroadcast,
    type: typeInteractive
  } = useSelector((state) => state.isettings.info);
  const { socials, posts, filteredPosts, searchText } = useSelector((state) => state.socials);
  const [isAuthed, setIsAuthed] = useState(true);
  const [additionComments, setAdditionComments] = useState([]);
  const [{ response, error, isLoading }, doFetch] = useFetch('api_get_socials_posts', option);
  // change lang
  const [localeState] = useContext(LocaleContext);

  const isActiveChannel = socials.find((el) => el.isActive)?.channel;
  const activeTab =
    activeChannel || (isActiveChannel === 'facebookcomments' ? 'fbcomm' : isActiveChannel);

  useEffect(() => {
    if (activeTab)
      doFetch({
        method: 'POST',
        postBody: {
          actionName: 'getPosts',
          param1: activeTab
        }
      });
  }, [socials, activeTab]);

  useEffect(() => {
    if (error) {
      return;
    }

    if (!response) return;

    if (response === 'not_auth') {
      setIsAuthed(false);
      return;
    }
    setPosts(response.Posts);
  }, [response, error, setPosts]);

  const getCheckedPosts = () => posts.map((el) => el.checked);
  const isSomeUncheckedPost = () =>
    posts.filter((post) => post.channel === activeTab).some((el) => el.checked === false);

  const changeSearchText = (event) => filterBySearchText(event.target.value);
  const toggleAll = () => toggleAllPosts(!!isSomeUncheckedPost(), activeTab);

  const getButtonClasses = (isActive) => {
    return classNames([styles['channel-mode-btn']], {
      [styles['selected']]: isActive
    });
  };

  const clickHandler = (channel) => {
    makeChannelActive(channel);
  };

  const sosialBodyClick = (e) => {
    if (e.target.name !== 'exportComments' && e.target.parentNode.name !== 'exportComments') {
      return;
    }

    let mainId = '';
    let channel = '';
    if (e.target.name === 'exportComments') {
      (mainId = e.target.dataset.id), (channel = e.target.dataset.channel);
    } else if (e.target.parentNode.name === 'exportComments') {
      (mainId = e.target.parentNode.dataset.id), (channel = e.target.parentNode.dataset.channel);
    }
    const broadcastId = idOpenBroadcast || idInteractive;
    setAdditionComments((prev) => [...prev, mainId]);
    exportCommentsToBroadcast(mainId, channel, broadcastId, setAdditionComments);
  };

  return (
    <div id="social-links" className={styles['social-conent']}>
      <div className={styles['social-head']}>
        <Title size={18} weight="300" color="#333">
          {activeChannel === 'yt_live'
            ? window.t('youtubeChatsFilter')
            : window.t('socialBookmarks')}
        </Title>
        <div className="d-flex justify-center">
          {activeChannel !== 'yt_live'
            ? socials &&
              socials.length !== 0 &&
              socials
                .filter((el) => el.channel !== 'yt_live')
                .map(({ channel, isActive, title }, i) => (
                  <Button
                    clickHandler={() => {
                      clickHandler(channel);
                    }}
                    className={`${getButtonClasses(channel === activeTab)}`}
                    title={window.t(title)}
                    color="#00000026"
                    textColor="#ffffff"
                    variant="text"
                    style={{
                      padding: 0,
                      minWidth: 0,
                      borderRadius: '50%',
                      margin: '0 2px 7px'
                    }}
                    key={i}>
                    <Avatar src={getImageByChannel(channel)} size={32} />
                  </Button>
                ))
            : null}
        </div>
        <Input
          type="text"
          value={searchText}
          placeholder={window.t('searchByPosts')}
          changeHandler={changeSearchText}
          className="mt-4 mb-4"
          inputName="searchByPosts"
        />
        <div style={{ borderBottom: '1px solid #c7c7c7' }}>
          {getCheckedPosts().length > 0 && (
            <RoundCheckbox
              label={window.t('selectAll')}
              isChecked={!isSomeUncheckedPost()}
              changeHandler={toggleAll}
            />
          )}
        </div>
      </div>
      <hr style={{ clear: 'both' }} />
      {isLoading ? (
        <CircularLoader />
      ) : !isAuthed ? (
        <h3>Вы еще не авторизованы</h3>
      ) : error ? (
        <h3>Произошла ошибка при загрузке постов</h3>
      ) : posts.length > 0 ? (
        <div className={styles['social-body']} style={{ padding: 0 }} onClick={sosialBodyClick}>
          {filteredPosts
            .filter((post) => post.channel === activeTab)
            .map(({ author, channel, checked, content, date, href, id, title, postId }) => {
              return (
                <Card
                  key={id + channel}
                  style={{
                    boxShadow: 'rgb(0 0 0 / 15%) 0px 2px 5px',
                    padding: 9
                  }}>
                  <CardContent className="overflow-hidden">
                    <RoundCheckbox
                      isChecked={checked}
                      changeHandler={() => togglePost(id, channel, !checked, href)}
                      style={{ float: 'left' }}
                    />
                    <Text className={styles['selector-group-time']} tag="span">
                      {getUIDateFromDBDate(date) !== 'Invalid date'
                        ? getUIDateFromDBDate(date)
                        : null}
                    </Text>
                    <Text className={styles['post-body']}>{content || title}</Text>
                  </CardContent>
                  <CardActions className="justify-space-between">
                    <Button
                      variant="text"
                      size="small"
                      color="#385898"
                      style={{
                        marginLeft: 28,
                        fontSize: 13
                      }}
                      clickHandler={() => {
                        window.open(href, '_blank');
                      }}>
                      {window.t('open')}
                    </Button>
                    <Button
                      isLoading={additionComments.includes(postId)}
                      title={window.t(
                        additionComments.includes(postId)
                          ? 'commentsAreBeingAdded'
                          : idInteractive && ['broadcast', 'vote', 'quiz'].includes(typeInteractive)
                          ? 'addCommentsThisPost'
                          : 'openAnExistingInteractive'
                      )}
                      isDisabled={
                        !idInteractive ||
                        !['broadcast', 'vote', 'quiz'].includes(typeInteractive) ||
                        additionComments.includes(postId)
                      }
                      dataset={{
                        'data-id': postId,
                        'data-channel': channel
                      }}
                      size="small"
                      name="exportComments"
                      style={{
                        fontSize: 13
                      }}>
                      {window.t('commentsToTheBroadcast')}
                    </Button>
                  </CardActions>
                </Card>
              );
            })}
        </div>
      ) : (
        <Title size={18} weight="300" color="#333">
          {window.t('noPosts')} ...
        </Title>
      )}
    </div>
  );
}

export default memo(SocialLinksModal);

SocialLinksModal.propTypes = propTypes;
