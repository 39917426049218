import classNames from 'classnames';

import { FeedDate, Statistics } from 'components';

import { getUILocaleDateFromJSDate } from 'helpers/dateTime';

import styles from './DateDivider.module.css';

export function DateDivider({ authorKey, dateKey, currentDate, item, items }) {
  return (
    <div className={styles.info}>
      <Statistics
        className={classNames(styles.statistics, styles.statistics_left)}
        color="#666"
        date={currentDate}
        messages={items}
        dateKey={dateKey}
        authorKey={authorKey}
        isAuthors
      />
      <FeedDate className={styles.date}>
        {(!isNaN(Date.parse(item[dateKey])) &&
          getUILocaleDateFromJSDate(new Date(Date.parse(`${item[dateKey]}+0300`)))) ||
          window.t('invalidDate')}
      </FeedDate>
      <Statistics
        className={classNames(styles.statistics, styles.statistics_right)}
        color="#666"
        date={currentDate}
        messages={items}
        authorKey={authorKey}
        dateKey={dateKey}
        isMessages
      />
    </div>
    // <Statistics
    //   className={classNames(styles.statistics, styles.statistics_left)}
    //   color="#666"
    //   date={currentDate}
    //   messages={items}
    //   isAuthors
    //   authorField={item.rss_name ? "rss_name" : item.user ? "user" : "author"}
    // />
    // <FeedDate className={styles.date}>
    //   {(!isNaN(Date.parse(item.date || item.pubDate)) &&
    //     getUILocaleDateFromJSDate(
    //       new Date(Date.parse(item.date || item.pubDate))
    //     )) ||
    //     window.t("invalidDate")}
    // </FeedDate>
    // <Statistics
    //   className={classNames(styles.statistics, styles.statistics_right)}
    //   color="#666"
    //   date={currentDate}
    //   messages={items}
    //   isMessages
    // />
    // </div>
  );
}
