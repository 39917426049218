import React from 'react';

import VideoPlayer from 'components/VideoPlayer/VideoPlayer';

import styles from './MediaVideo.module.css';

function MediaVideo({ video, openMedia, isBroadcastMsg }) {
  return (
    <div className={`${styles['media-video']} ${isBroadcastMsg && styles['broadcast']}`}>
      <VideoPlayer
        height={isBroadcastMsg && '100%'}
        src={video.url}
        isExpanded={false}
        expandHandler={() => {
          openMedia(video.url);
        }}
      />
    </div>
  );
}

export default MediaVideo;
