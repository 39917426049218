import { memo, useContext } from 'react';

import { Input, Select, Text } from 'components';

import { LocaleContext } from 'providers/LocaleProvider';

import s from './FieldsFormCard.module.css';

export const FieldsFormCard = memo(
  ({ config = [], card_id, editParticipantAuthorData, author_data, edit }) => {
    // ширина полей ????
    const widthCurrent = (name) => {
      if (name === 'city') {
        return '1';
      } else if (name === 'job') {
        return '3';
      } else if (name === 'position') {
        return '2';
      } else if (name === 'speciality') {
        return '3';
      } else if (name === 'email') {
        return '3';
      }
    };

    const widthMin = (name) => {
      if (name === 'city') {
        return '150px';
      } else if (name === 'job') {
        return '400px';
      } else if (name === 'position') {
        return '230px';
      } else if (name === 'speciality') {
        return '400px';
      } else if (name === 'email') {
        return '300px';
      }
    };

    const [locale] = useContext(LocaleContext);

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'flex-end' }}>
        {config?.length !== 0 &&
          config?.map((field) => {
            if (field.type === 'datalist') {
              return (
                <div className={s.selectBlock} key={field.name} style={{}}>
                  <span className={s.labelSelect}>{window.t(field.name)}</span>
                  <Select
                    className={s.selectEl}
                    style={{
                      pointerEvents: !edit ? 'none' : 'auto',
                      border: '1px solid #cdcdcd',
                      padding: '10px',
                      opacity: 1,
                      fontWeight: 700,
                      outline: 'none',
                      borderRadius: '4px',
                      fontSize: 14,
                      height: '39px',
                      minWidth: '400px',
                      flex: `${widthCurrent(field.name)} 1 ${widthMin(field.name)}`
                    }}
                    label={window.t(field.name)}
                    options={field?.options?.map((el) => {
                      return {
                        value: el,
                        label: el
                      };
                    })}
                    listClassName={s.listClassName}
                    selectedOption={{
                      value: author_data[field.name],
                      label: author_data[field.name]
                    }}
                    setSelectedOption={(option) => {
                      editParticipantAuthorData({
                        card_id,
                        field: field.name,
                        value: option.value
                      });
                    }}
                    withChevronDown={edit}
                    // isSelectDisabled={isTypeSelectDisabled}
                    // disabledMessage={window.t('contentClearBlocksToChangeType')}
                    locale={locale}
                  />
                </div>
              );
            } else
              return (
                <Input
                  key={field.name}
                  type="text" // field.type ??
                  label={window.t(field.name)}
                  value={author_data[field.name]}
                  placeholder={window.t('enterValue')}
                  style={{
                    color: '#808080',
                    fontWeight: 500,
                    flex: `${widthCurrent(field.name)} 1 ${widthMin(field.name)}`
                  }}
                  inpStyle={{
                    fontWeight: 700,
                    pointerEvents: !edit ? 'none' : 'auto'
                  }}
                  changeHandler={(e) => {
                    editParticipantAuthorData({
                      card_id,
                      field: field.name,
                      value: e.target.value
                    });
                  }}
                />
              );
          })}
      </div>
    );
  }
);
