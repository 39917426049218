import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Avatar, Button, Title } from 'components';

import { getImageByChannel } from 'helpers/image';

import styles from './ChannelPicker.module.css';

function ChannelPicker({
  title,
  titleClassName,
  wrapperClassName,
  selectedChannel,
  clickHandler,
  imageSize,
  className,
  activeClass
}) {
  const channels = [
    {
      type: 'facebook',
      title: window.t('facebookMessage')
    },
    {
      type: 'telegram',
      title: window.t('telegramMessage')
    },
    {
      type: 'tgcomm',
      title: window.t('tgComment')
    },
    {
      type: 'vkgroup',
      title: window.t('vkMessage')
    },
    {
      type: 'sms',
      title: window.t('smsMessage')
    },
    {
      type: 'viber',
      title: window.t('viberMessage')
    },
    {
      type: 'livechat',
      title: window.t('liveChatMessage')
    },
    {
      type: 'whatsapp',
      title: window.t('whatsAppMessage')
    },
    {
      type: 'smsMoney',
      title: window.t('paidMessages')
    },
    {
      type: 'fbinstagram',
      title: window.t('instagramComment')
    },
    {
      type: 'vkcomm',
      title: window.t('vkComment')
    },
    {
      type: 'facebookcomments',
      title: window.t('facebookComment')
    },
    {
      type: 'okgroup',
      title: window.t('okMessage')
    }
  ];

  // const setChannelType = (value) => {
  //   const newVal = (channel === value) ? null : value;
  //   setSearchConditions('channel', newVal);
  // }

  const getButtonClasses = (isActive) => {
    return classNames([styles['channel-mode-btn']], {
      [activeClass]: isActive
    });
  };

  return (
    <div className="pl-5 pt-5">
      <Title className={titleClassName}>{title}</Title>
      <div className={`d-flex pt-4 pb-4 ${wrapperClassName}`}>
        {channels.map(({ type, title }, i) => {
          return (
            <Button
              clickHandler={() => {
                clickHandler(type);
              }}
              className={`${getButtonClasses(type === selectedChannel)} ${className}`}
              title={title}
              color="#00000026"
              textColor="#ffffff"
              variant="text"
              style={{
                padding: 0,
                minWidth: 0,
                borderRadius: '50%',
                margin: '0 2px 7px'
              }}
              key={i}>
              <Avatar src={getImageByChannel(type)} size={imageSize} />
            </Button>
          );
        })}
      </div>
    </div>
  );
}

export default ChannelPicker;

ChannelPicker.propTypes = {
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  selectedChannel: PropTypes.string,
  clickHandler: PropTypes.func,
  imageSize: PropTypes.number,
  className: PropTypes.string,
  activeClass: PropTypes.string
};
