export const types = [
  {
    value: 'news',
    title: 'Новости'
  },
  {
    value: 'weather',
    title: 'Погода'
  },
  {
    value: 'avito',
    title: 'Объявление'
  },
  {
    value: 'congratulations',
    title: 'Поздравления'
  }
];
