import { useCallback, useEffect, useRef, useState } from 'react';

import { autoHyphens, getFormattedHtml } from 'components/TextEditor/helpers';

import { useActions, useFetch } from 'hooks';
import { useAudioMetaData } from 'hooks/useAudioMetaData';

import { deleteVideo } from 'api/dataLayer';

import { objectFromRules } from 'helpers/content';

export const useNewsCard = ({ contentId, block, setStepIndex, content_rule }) => {
  const [textFromSpeech, setTextFromSpeech] = useState(null);
  const { addSnack, updateContentBlockField, updateContentBlock } = useActions();
  const audioMetaData = useAudioMetaData({
    src: block.text_to_speech?.src
  });
  const inputRef = useRef(null);
  const [uploadBlockData, setUploadBlockData] = useState(null);
  const [{ response, isLoading, error }, doFetch] = useFetch('api_add_content_attachment');
  const [
    { response: videoResponse, isLoading: isVideoUploading, error: videoError },
    doFetchVideo
  ] = useFetch('api_upload_video');

  const [
    { response: speechConvertResponse, isLoading: isSpeechConverting, error: speechConvertError },
    doFetchSpeechConvert
  ] = useFetch('api_speech_convert');

  const [
    { response: responseSynthesis, isLoading: isLoadingSynthesis, error: errorSynthesis },
    doFetchSynthesis
  ] = useFetch('api_synthesis_speech');

  const { id: blockId } = block;

  const synthesisSpeech = useCallback(
    (blockId, field) => {
      let text = block.text_to_speech.value;
      const { voice, emotion, speed } = block.text_to_speech;
      if (text.length === 0) {
        text = `${block.caption.value} ${block.text.value}`;
        updateContentBlockField(blockId, 'text_to_speech', text);
      }

      let voicesSber = [
        'Nec_24000',
        'Bys_24000',
        'May_24000',
        'Tur_24000',
        'Ost_24000',
        'Pon_24000'
      ];

      const typeGenerateSpeech = voicesSber.includes(voice) ? 'Sber' : 'Yandex';

      const parameters =
        typeGenerateSpeech === 'Yandex'
          ? JSON.stringify([{ voice }, { emotion }, { speed }])
          : JSON.stringify([{ voice }]);

      doFetchSynthesis({
        method: 'POST',
        postBody: {
          contentId,
          blockId,
          text,
          typeGenerateSpeech,
          parameters
        }
      });
      setUploadBlockData({
        blockId,
        field
      });
    },
    [contentId, block, updateContentBlockField]
  );

  useEffect(() => {
    if (!videoResponse || videoError || !uploadBlockData) return;

    const { blockId, field } = uploadBlockData;
    updateContentBlockField(blockId, field, videoResponse);
    setUploadBlockData(null);
    // setTimeout(() => {
    //   setStepIndex((v) => ++v);
    // }, 300);
  }, [videoResponse, updateContentBlockField, videoError]);

  useEffect(() => {
    if (!speechConvertResponse || speechConvertError || !uploadBlockData) return;
    const { blockId, field, previousValue } = uploadBlockData;
    const formattedText = getFormattedHtml(previousValue);
    const responseText = `<font color="#4d4d4d" id="temp-font">${speechConvertResponse.text}</font>`;

    const value = block[field];
    const isAutoHyphens = value?.isAutoHyphens;
    const newText = (formattedText || '') + speechConvertResponse.text;

    let resText =
      previousValue?.textHyphens !== undefined
        ? {
            ...previousValue,
            textHyphens: newText,
            text: isAutoHyphens ? autoHyphens(newText, value, 0.45) : newText
          }
        : (formattedText || '') + responseText;

    setTextFromSpeech(resText);
    updateContentBlockField(blockId, field, resText);
    setUploadBlockData(null);
  }, [speechConvertResponse, updateContentBlockField, speechConvertError]);

  useEffect(() => {
    if (!responseSynthesis) return;

    const { blockId, field } = uploadBlockData;
    updateContentBlockField(blockId, field, responseSynthesis, 'src');
    setUploadBlockData(null);
    setTimeout(() => {
      setStepIndex((v) => ++v);
    }, 300);
  }, [responseSynthesis, updateContentBlockField]);

  const pictureChangeHandler = useCallback(
    (evt, blockId, field) => {
      if (evt === null) {
        updateContentBlockField(blockId, field, '');
      } else {
        const file = evt.target.files[0];
        if (!file) return;

        setUploadBlockData({
          blockId,
          field
        });
        file.isBinary = true;
        doFetch({
          method: 'POST',
          postBody: {
            contentId,
            attachment: file
          }
        });
      }
    },
    [contentId, updateContentBlockField]
  );

  useEffect(() => {
    if (!response) return;

    if (response === 'File so large') {
      addSnack({
        type: 'danger',
        message: window.t('imgFileIsTooLarge')
      });
      return;
    }
    if (!response.includes('http')) {
      addSnack({
        type: 'danger',
        message: window.t('invalidFileFormatImg')
      });
      return;
    }

    const { blockId, field } = uploadBlockData;
    updateContentBlockField(blockId, field, response);
  }, [response, updateContentBlockField]);

  useEffect(() => {
    updateContentBlockField(blockId, 'text_to_speech', audioMetaData.duration, 'duration');
  }, [audioMetaData, updateContentBlockField]);

  const insertTags = useCallback(
    (tags) => {
      const e = inputRef.current;
      let cursorPosition = e.selectionStart;
      let textBeforeCursorPosition = e.value.substring(0, cursorPosition);
      let textAfterCursorPosition = e.value.substring(cursorPosition, e.value.length);
      updateContentBlockField(
        blockId,
        'text_to_speech',
        textBeforeCursorPosition + tags + textAfterCursorPosition
      );
    },
    [inputRef.current, updateContentBlockField]
  );

  const uploadVideo = (blockId, field) => async (file, url) => {
    if (!file) {
      updateContentBlockField(blockId, field, file);
      await deleteVideo(url);
      return;
    }
    setUploadBlockData({
      blockId,
      field
    });
    doFetchVideo({
      method: 'POST',
      postBody: {
        file
      }
    });
  };

  const convertSpeech = (blockId, field, previousValue) => (video) => {
    setUploadBlockData({
      blockId,
      field,
      previousValue
    });

    if (!video.urlAudio) {
      addSnack({
        type: 'danger',
        message: window.t('audioFileNotFound')
      });
      return;
    }

    doFetchSpeechConvert({
      method: 'POST',
      postBody: {
        audio: video.urlAudio
      }
    });
  };

  const updateBlockType = async (blockId, titreType, block) => {
    const newBlock = objectFromRules(content_rule['content#'][titreType.value]?.fields);

    const newValue = {
      ...newBlock
    };

    Object.entries(block).forEach(([key, value]) => {
      if (newValue.hasOwnProperty(key)) {
        newValue[key] =
          value?.order !== newValue[key]?.order ? { ...value, order: newValue[key]?.order } : value;
        if (newValue[key]['type#'] === 'stringWithPreviewElement') {
          newValue[key] = {
            ...newBlock[key],
            value: value.value || '',
            isAutoHyphens: value.isAutoHyphens !== undefined ? value.isAutoHyphens : true
          };
        } else if (newValue[key]['type#'] === 'speech') {
          newValue[key] = {
            ...newBlock[key],
            value: value.value || '',
            src: value.src || '',
            duration: value.duration || 0,
            voice: value.voice,
            speed: value.speed,
            emotion: value.emotion
          };
        } else {
          newValue[key] = {
            ...newBlock[key],
            value: value.value || ''
          };
        }
      }
    });

    newValue.id = blockId;
    newValue.titreType = titreType;
    newValue.isMinimized = block.isMinimized;
    newValue.isActive = block.isActive;
    newValue.isTouched = block.isTouched;

    updateContentBlock(blockId, newValue);

    if (block.titreType.value !== titreType.value && block.video) {
      if (block.video.value?.urlVideo) {
        await deleteVideo(block.video.value.urlVideo);
      }
    }
  };

  return {
    updateBlockType,
    textFromSpeech,
    convertSpeech,
    isSpeechConverting,
    uploadVideo,
    isVideoUploading,
    inputRef,
    insertTags,
    updateBlockField: updateContentBlockField,
    pictureChangeHandler,
    isImageUploading: isLoading,
    synthesisSpeech,
    isLoadingSynthesis,
    audioMetaData
  };
};
