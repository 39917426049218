import {
  CLEAR_SESSION_TEMP_SUCCESS,
  CREATE_SESSION_ERROR,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_TEMP_SUCCESS,
  FETCH_SESSIONS_ERROR,
  FETCH_SESSIONS_START,
  FETCH_SESSIONS_SUCCESS,
  REMOVE_SESSIONS,
  SELECT_SESSION,
  SET_ENABLE_INTERACTIVES,
  SET_FILTERED_SESSIONS,
  SET_IS_LOADING_SESSIONS,
  SET_IS_SESSION_CREATED,
  SET_IS_SET_SESSIONS,
  SET_SCROLL_POSITION,
  SET_SESSIONS,
  SET_SESSION_ACTIVITY,
  UPDATE_SESSION_INFO
} from './actionTypes';

export function fetchSessionsStart() {
  return {
    type: FETCH_SESSIONS_START
  };
}

export function fetchSessionsSuccess(sessions) {
  return {
    type: FETCH_SESSIONS_SUCCESS,
    sessions
  };
}

export function fetchSessionsError(ex) {
  return {
    type: FETCH_SESSIONS_ERROR,
    error: ex
  };
}

export function selectSession(sessionId, isMultiple) {
  return {
    type: SELECT_SESSION,
    sessionId,
    isMultiple
  };
}

export function createSessionSuccess(session) {
  return {
    type: CREATE_SESSION_SUCCESS,
    session
  };
}

export function createSessionTempSuccess(session) {
  return {
    type: CREATE_SESSION_TEMP_SUCCESS,
    session
  };
}

export function clearSessionTempSuccess(session) {
  return {
    type: CLEAR_SESSION_TEMP_SUCCESS,
    session
  };
}

export function createSessionError(ex) {
  return {
    type: CREATE_SESSION_ERROR,
    error: ex
  };
}

export function updateSessionInfo(info) {
  return {
    type: UPDATE_SESSION_INFO,
    info
  };
}

export function setSessions(sessions) {
  return {
    type: SET_SESSIONS,
    sessions
  };
}

export function setIsLoadingSessions(isLoading) {
  return {
    type: SET_IS_LOADING_SESSIONS,
    isLoading
  };
}

export function setIsSessionCreated(value) {
  return {
    type: SET_IS_SESSION_CREATED,
    value
  };
}

export function removeSessions(toDel) {
  return {
    type: REMOVE_SESSIONS,
    toDel
  };
}

export function setFilteredSessions(toShow, interactives) {
  return {
    type: SET_FILTERED_SESSIONS,
    toShow,
    interactives
  };
}

export function setEnableInteractives(enableInteractives) {
  return {
    type: SET_ENABLE_INTERACTIVES,
    enableInteractives
  };
}

export function setSessionActivity(interactiveId, activity) {
  return {
    type: SET_SESSION_ACTIVITY,
    interactiveId,
    activity
  };
}

export function setScrollPosition(scrollPosition) {
  return {
    type: SET_SCROLL_POSITION,
    scrollPosition
  };
}

export function setIsSetSessions(isSetSessions) {
  return {
    type: SET_IS_SET_SESSIONS,
    isSetSessions
  };
}
