import ProfileInfoWrapper from './ProfileInfoWrapper/ProfileInfoWrapper';
import ToolbarControls from './ToolbarControls/ToolbarControls';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Avatar, DotsLoader, FeedDate, Message, SessionModuleView, Toolbar } from 'components';
import Media from 'components/Media/Media';

import useActions from 'hooks/useActions';
import useFetch from 'hooks/useFetch';
import useLocalStorage from 'hooks/useLocalStorage';

import { getUILocaleDateFromJSDate } from 'helpers/dateTime';
import { getImageByChannel } from 'helpers/image';
import { getRouteInfo } from 'helpers/profile';

import styles from './ProfileCard.module.css';

const option = { dataKey: null };

export function ProfileCard({ match }) {
  const { fetchUserMessagesSuccess, setFavState } = useActions();
  const [profileMessage] = useLocalStorage('profileMessage', null);

  // const [messages, setMessages] = useState([]);
  const messages = useSelector((state) => {
    return state.profileCard.messages;
  });
  const [userInfo, setUserInfo] = useState({});
  const [routeInfo, setRouteInfo] = useState({});
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  // const { hasMoreMessages, isMessagesLoading } = useSelector(state => state.profileCard);
  const [
    { response: responseUserInfo, isLoading: isLoadingUserInfo, error: errorUserInfo },
    doFetchUserInfo
  ] = useFetch('api_get_profile_user_info', option);
  const [
    { response: responseMessages, isLoading: isLoadingMessages, error: errorMessages },
    doFetchMessages
  ] = useFetch('api_get_profile_messages', option);

  useEffect(() => {
    setRouteInfo(getRouteInfo(match.params.id));
    fetchUserMessagesSuccess([]);
    if (profileMessage.isCreated === '1') {
      fetchUserMessagesSuccess([profileMessage]);
      setHasMoreMessages(false);
      const { region, date } = profileMessage;

      setUserInfo((userInfo) => ({
        ...userInfo,
        region,
        firstMessage: date,
        lastMessage: date
      }));
      return;
    }

    const { senderNumber, channel } = getRouteInfo(match.params.id);
    doFetchUserInfo({
      method: 'POST',
      postBody: {
        action: 'getUserInfo',
        identifier: senderNumber,
        channel
      }
    });
    // dispatch(fetchMessages(senderNumber, channel));
    doFetchMessages({
      method: 'POST',
      postBody: {
        action: 'getMessages',
        identifier: senderNumber,
        channel
      }
    });
    return () => {
      fetchUserMessagesSuccess([]);
      setUserInfo({});
      setHasMoreMessages(true);
      // setMessages([]);
    };
  }, [doFetchMessages, doFetchUserInfo, match.params.id, fetchUserMessagesSuccess]);

  useEffect(() => {
    if (!responseUserInfo) return;

    setUserInfo((info) => ({ ...info, ...responseUserInfo }));
  }, [responseUserInfo]);

  useEffect(() => {
    if (errorMessages || responseMessages === 'no messages') {
      setHasMoreMessages(false);
      return;
    }

    if (!responseMessages) return;
    // setMessages(messages => [...messages, ...responseMessages]);
    if (!Array.isArray(responseMessages)) return;

    fetchUserMessagesSuccess([
      ...messages,
      ...responseMessages.map((msg) => ({
        ...msg,
        favorite: msg.favorite === 'true' ? '1' : '0'
      }))
    ]);
    if (!userInfo.author) setUserInfo((info) => ({ ...info, author: responseMessages[0].author }));
  }, [responseMessages, errorMessages, fetchUserMessagesSuccess]);

  const onScroll = (event) => {
    const target = event.target;
    if (
      hasMoreMessages &&
      !isLoadingMessages &&
      target.offsetHeight + target.scrollTop >= target.scrollHeight
    ) {
      const { senderNumber, channel } = getRouteInfo(match.params.id);
      // dispatch(fetchMoreMessages(senderNumber, channel));
      doFetchMessages({
        method: 'POST',
        postBody: {
          action: 'getMessages',
          identifier: senderNumber,
          channel,
          offset: messages.length,
          limit: 10
        }
      });
    }
  };

  function NoMoreMessages() {
    const classes = classNames(styles['profile-card__messages-nomessage'], {
      hidden: hasMoreMessages
    });
    return <p className={classes}>{window.t('hasNoMoreMessages')}</p>;
  }

  const answer = useSelector((state) => state.settings.messageFeedConfig.answer);

  const messageActionsConfig = {
    copy: true,
    exportToBroadcast: false,
    minimize: false,
    settings: false,
    favorite: true
  };

  return (
    <SessionModuleView scrollHandler={onScroll} styles={{ backgroundColor: '#f7f7f7' }}>
      <Toolbar title={window.t('authorCard')}>
        <ToolbarControls
          isLoading={isLoadingMessages}
          routeInfo={routeInfo}
          author={userInfo.author}
          userName={userInfo.userName}
        />
      </Toolbar>
      <div className="h-full">
        {errorUserInfo && (
          <div className={styles['profile-card__failed-load']}>
            {window.t('profileFailedToAccess')}
          </div>
        )}
        <div className={styles['profile-card__wrapper']}>
          <div className={styles['profile-card__img-wrapper']}>
            <Avatar src={getImageByChannel(routeInfo.channel, userInfo.image)} size={120} />
          </div>
          <ProfileInfoWrapper
            isLoadingUserInfo={isLoadingUserInfo}
            isLoadingMessages={isLoadingMessages}
            routeInfo={routeInfo}
            userInfo={userInfo}
            messages={messages}
          />
        </div>
        <div className={styles['profile-card__media-wrapper']}>
          <Media userInfo={userInfo} routeId={match.params.id} channel={userInfo.channel} />
        </div>
        <div className={styles['profile-card__messages-wrapper']}>
          <div className={styles['profile-card__messages']}>
            {/* <div className={styles['profile-card__messages-header']}>
              <Title>{window.t('messages')}</Title>
            </div> */}
            <ul>
              {messages.map((el, i) => {
                let curr = new Date(`${el.date}+0300`);
                curr = [curr.getFullYear(), curr.getMonth(), curr.getDate()];
                let prev = new Date(`${messages[i - 1]?.date}+0300`);
                prev = [prev.getFullYear(), prev.getMonth(), prev.getDate()];

                const isTheSameDay = i && curr.every((_, i) => curr[i] === prev[i]);

                return (
                  <Fragment key={el.id}>
                    {!isTheSameDay && (
                      <div className={styles.info}>
                        <FeedDate className={styles.date}>
                          {getUILocaleDateFromJSDate(new Date(`${el.date}+0300`))}
                        </FeedDate>
                      </div>
                    )}
                    <div data-items="true">
                      <Message
                        quickReplies={answer}
                        type="profileCard"
                        data={el}
                        key={el.id}
                        isSelected={false}
                        selectMessage={() => {}}
                        messageActionsConfig={messageActionsConfig}
                        toggleFavorite={setFavState}
                      />
                    </div>
                  </Fragment>
                );
              })}
            </ul>
            <DotsLoader isActive={isLoadingMessages} />
            <NoMoreMessages hasMoreMessages={hasMoreMessages} />
          </div>
        </div>
      </div>
    </SessionModuleView>
  );
}

ProfileCard.propTypes = {
  match: PropTypes.object
};
