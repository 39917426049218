import { ClearTitreMenuContainer } from 'containers';

import { Button, Icon, Menu, SvgCheckbox } from 'components';

export const useControls = ({
  voteProps,
  broadcastProps,
  settingsClickHandler,
  reload,
  clearVotesHandler,
  visibility,
  changeVisibility,
  clearBtn,
  toggleClearMenu,
  isMenuActive,
  clearMenuPosition,
  broadcastSettings,
  broadcastId,
  toggleFavoriteHandle,
  statusFavorite
}) => {
  // const [controls, setControls] = useState([]);
  // setControls()

  const controls = [
    <Button
      onlyIcon
      variant="icon"
      key="startStop"
      style={{ padding: '6px 8px' }}
      {...voteProps}
    />,
    <Button
      startIcon={<Icon size={15} color="rgba(0, 0, 0, 0.54)" fontName="fal fa-tv-alt" />}
      onlyIcon
      variant="icon"
      key="startStopBroadcast"
      style={{ padding: '6px 8px' }}
      {...broadcastProps}
    />,
    <Button
      startIcon={<Icon size={20} fontName="fal fa-cog" />}
      onlyIcon
      title={window.t('settings')}
      variant="icon"
      key="settings"
      color="#6f6f6f"
      clickHandler={settingsClickHandler}
      style={{ padding: '6px 8px' }}
    />,
    <Button
      startIcon={<Icon size={16} fontName="far fa-sync-alt" />}
      onlyIcon
      variant="icon"
      key="refresh"
      color="#57adf2"
      clickHandler={reload}
      title={window.t('refresh')}
      style={{ padding: '6px 8px' }}
    />,

    <Menu
      activator={clearBtn}
      activatorHandler={toggleClearMenu}
      isActive={isMenuActive}
      left={clearMenuPosition ? clearMenuPosition.left : 0}
      top={clearMenuPosition ? clearMenuPosition.top : 0}
      key="menu">
      <ClearTitreMenuContainer
        broadcastId={broadcastId}
        exportButtons={broadcastSettings}
        toggleClearMenu={toggleClearMenu}
      />
    </Menu>,
    <SvgCheckbox
      isChecked={visibility}
      changeHandler={changeVisibility}
      key="visibility"
      style={{
        color: '#6f6f6f',
        fontSize: 16,
        opacity: 0.75,
        marginLeft: 10
      }}>
      {window.t('titerVisibility')}
    </SvgCheckbox>,
    <Button
      key="favoriteMsg"
      startIcon={
        <Icon
          size={18}
          fontName={`${statusFavorite && statusFavorite.isFavorite ? 'fa' : 'fal'} fa-star`}
        />
      }
      onlyIcon
      variant="message-action"
      clickHandler={toggleFavoriteHandle}
      title={window.t(statusFavorite && statusFavorite.isFavorite ? 'allMessages' : 'favoriteMsg')}
      style={{ opacity: statusFavorite && statusFavorite.isFavorite && 1 }}
    />,
    <Button
      startIcon={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon size={20} fontName="fas fa-broom" />
          <Icon style={{ marginLeft: 5 }} size={20} fontName="fal fa-times" />
        </div>
      }
      onlyIcon
      variant="icon"
      color="#ff0033"
      key="clear"
      clickHandler={clearVotesHandler}
      title={window.t('clearAll')}
      style={{ marginLeft: 'auto', padding: '6px 8px' }}
    />
  ];

  return {
    controls
  };
};
