import {
  autoHyphens,
  breakTagsAndInsertText,
  getFormattedHtml,
  getTextAndEffects,
  selectText
} from './helpers';
import classNames from 'classnames';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';

import { checkIsValid } from 'containers/ContentContainer/components/NewsBlock/components/ContentFields/helpers/validationFields';

import { Select, SvgCheckbox } from 'components';

import styles from './TextEditor.module.css';

export const TextEditor = forwardRef(
  (
    {
      locale,
      textFromSpeech,
      text,
      setText,
      send,
      blurHandler,
      focusHandler,
      changeHandler,
      style,
      focusable,
      onKeyDown,
      placeholder,
      label,
      children,
      controls,
      inputRef,
      afterLabel,
      textEffects,
      className,
      block,
      updateContentBlockField = () => {},
      isValidPreview,
      elementKey,
      value = {},
      isTextHyphens,
      isAutoHyphens,
      isValidCountLines
    },
    ref
  ) => {
    const innerRef = useRef(null);
    const [html, setHtml] = useState(null);
    const localRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [validWidth, setValidWidth] = useState(true);

    const isValid = useMemo(
      () =>
        checkIsValid(
          elementKey,
          block,
          block?.isTouched,
          null,
          updateContentBlockField,
          isAutoHyphens ? isValidPreview : validWidth
        ),
      [
        isTextHyphens ? text?.textHyphens : text.text,
        block?.isTouched,
        isValidPreview,
        validWidth,
        isValidCountLines
      ]
    );

    const classes = classNames(className, styles['main-chat-msg-input'], {
      [styles['focusable']]: focusable,
      [styles['invalid']]: !isValid || !validWidth
    });
    const hasBeenInitiallySet = useRef(false);

    useEffect(() => {
      // вылидация по ширине текстового поля, при вкл/выкл автоматического переноса (isAutoHyphens)
      if (innerRef.current) {
        let element = innerRef.current;
        if (element.scrollWidth > element.offsetWidth) {
          setValidWidth(false);
        } else {
          setValidWidth(true);
        }
      }

      // при отключенном автоматическом переносе (isAutoHyphens), очистка текста от автоматических переносов
      if (!isAutoHyphens) {
        setText({ ...text, text: text.textHyphens });
      }
      // при включенном автоматическом переносе (isAutoHyphens), добавление в текст автоматических переносов
      else if (isAutoHyphens) {
        let newText = autoHyphens(
          sanitizeHtml(text.textHyphens, {
            allowedTags: [],
            allowedAttributes: {}
          }),
          value,
          zoomPreview
        );
        setText({ ...text, text: newText });
      }

      resizeHeight();
    }, [isAutoHyphens, innerRef.current, value.textWidth]);

    useEffect(() => {
      if (hasBeenInitiallySet.current) return;
      hasBeenInitiallySet.current = true;
      let result = getFormattedHtml(text);

      setHtml(
        sanitizeHtml(result, {
          allowedTags: ['br', 'font'],
          allowedAttributes: { font: ['color'] }
        })
      );
    }, [text]);

    useEffect(() => {
      if (textFromSpeech === null) return;

      // распознанный текст из видео только в поле текст вставляется
      if (elementKey !== 'text') return;

      changeHandler({ target: { value: textFromSpeech } });
      const result = getFormattedHtml(textFromSpeech);

      setHtml(
        sanitizeHtml(result, {
          allowedTags: ['br', 'font'],
          allowedAttributes: { font: ['color'] }
        })
      );
      // resizeHeight();
    }, [textFromSpeech]);

    useEffect(() => {
      const node = selectText('temp-font');
      if (node) {
        document.execCommand('removeFormat', false, 'foreColor');
      }
    }, [html]);

    useEffect(() => {
      if (html === null) return;

      resizeHeight();

      // вылидация по ширине текстового поля при отключенном автоматическом переносе (isAutoHyphens)
      if (innerRef.current) {
        let element = innerRef.current;
        if (element.scrollWidth > element.offsetWidth) {
          setValidWidth(false);
        } else {
          setValidWidth(true);
        }
      }

      const result = getTextAndEffects(innerRef.current, isTextHyphens);

      if (isTextHyphens) {
        setText({ ...result, text: text.text });
        changeHandler({ target: { value: { ...result, text: text.text } } });
      } else {
        setText(result);
        changeHandler({ target: { value: result } });
      }
    }, [html]);

    const focus = () => {
      if (innerRef) innerRef.current.focus();
      else if (inputRef) inputRef.current.focus();
      else localRef.current.focus();
    };

    const resizeHeight = () => {
      const target = innerRef.current;
      if (!target) return;
      // смена overflow на auto для определения scrollHeight
      target.style.setProperty('overflow-y', 'auto');
      target.style.height = 'inherit';
      target.style.height = `${target.scrollHeight}px`;
      // смена overflow на hidden чтобы при вставке текста не было полосы прокрутки
      target.style.setProperty('overflow-y', 'hidden');
    };

    const handleChange = (event) => {
      let text = event.target.value;

      setHtml(
        sanitizeHtml(text, {
          allowedTags: ['br', 'font'],
          allowedAttributes: { font: ['color', 'id'] }
        })
      );
      changeHandler(event);
      // resizeHeight();
    };

    const { fontFamily, textWidth, fontSize, fontWeight, isUpperCase, fontStyle } = value;
    const zoomPreview = 0.45;

    const objStyleFromJson = useMemo(
      () => ({
        fontWeight,
        fontFamily: `${fontFamily}, sans-serif`,
        width: `${textWidth * zoomPreview}px`,
        fontSize: `${fontSize * zoomPreview}px`,
        textTransform: `${isUpperCase ? 'uppercase' : 'none'}`,
        fontStyle: `${fontStyle || 'normal'}`,
        lineHeight: `${fontSize * zoomPreview * 1.33}px`,
        overflowY: 'hidden',
        whiteSpace: isAutoHyphens ? 'pre-wrap' : 'pre',
        borderRight: '0.5px dashed #D0D0D0',
        paddingBottom: '20px',
        overflowX: isAutoHyphens ? 'hidden' : 'scroll'
      }),
      [
        fontFamily,
        textWidth,
        fontSize,
        fontWeight,
        isUpperCase,
        fontStyle,
        isAutoHyphens,
        validWidth
      ]
    );

    const optionsTextEditing = [
      { value: 'transfers', label: { en: 'Transfers', ru: 'Переносы' } },
      // { value: 'speechMarkup', label: { en: 'Speech markup', ru: 'Разметка речи' } },
      { value: 'eraseEverything', label: { en: 'Erase everything', ru: 'Стереть все' } }
    ];

    const selectTextEditingHandler = (val) => {
      switch (val['value']) {
        case 'transfers':
          let htmlNoTransfer = html.replaceAll('\n', '');
          setHtml(
            sanitizeHtml(htmlNoTransfer, {
              allowedTags: ['br', 'font'],
              allowedAttributes: { font: ['color', 'id'] }
            })
          );
          if (isAutoHyphens) {
            let newText = autoHyphens(
              sanitizeHtml(htmlNoTransfer, {
                allowedTags: [],
                allowedAttributes: {}
              }),
              value,
              zoomPreview
            );
            setText({ ...text, text: newText });
          } else {
            setText({ ...text, text: htmlNoTransfer });
          }

          break;
        case 'eraseEverything':
          setHtml('');
          setText({
            ...text,
            text: '',
            textHyphens: ''
          });
          setValidWidth(true);
          break;
        default:
          return null;
      }
    };

    return (
      <div ref={ref} className={classes} style={style}>
        <div className="d-flex mb-2">
          {label && (
            <span
              onClick={focus}
              className={classNames(styles['label'], {
                [styles['label-focused']]: isFocused
              })}>
              {label}
              {afterLabel}
            </span>
          )}
          <div className="d-flex ml-auto" style={{ alignItems: 'center', gap: 30 }}>
            {/* костыль - первая кнопка воспринимается как label для textarea */}
            <button
              style={{
                outline: 'none',
                background: 'transparent'
              }}
            />
            {isTextHyphens && (
              <div className={styles.blockControlsText}>
                <SvgCheckbox
                  isChecked={isAutoHyphens}
                  changeHandler={(val) =>
                    updateContentBlockField(block?.id, elementKey, val, 'isAutoHyphens')
                  }
                  style={{ alignSelf: 'flex-start', marginTop: '5px', fontSize: '16px' }}>
                  {window.t('autotransfer')}
                </SvgCheckbox>
                <Select
                  locale={locale}
                  options={optionsTextEditing}
                  label={window.t('clear')}
                  setSelectedOption={selectTextEditingHandler}
                />
              </div>
            )}

            {!!textEffects?.length && (
              <div className={styles.colors}>
                <EditButton
                  className={classNames(styles.colors__button, styles.colors__button_clear)}
                  children={<i className="fas fa-plus" />}
                  cmd="removeFormat"
                  arg="foreColor"
                />
                {textEffects
                  .filter((el) => el.type === 'color')
                  .map((el) => (
                    <EditButton
                      className={styles.colors__button}
                      key={el.id}
                      cmd="foreColor"
                      arg={el.value}
                    />
                  ))}
              </div>
            )}
            {controls}
          </div>
        </div>
        <div className={styles['main-chat-msg-input-wrapper']}>
          {(text?.text ?? text).length <= 0 && (
            <div className={styles['main-chat-msg-input-wrapper_placeholder']}>{placeholder}</div>
          )}

          {html !== null && (
            <ContentEditable
              style={isTextHyphens ? objStyleFromJson : {}}
              innerRef={innerRef}
              className={styles['main-chat-msg-input-wrapper_text']}
              html={html} // innerHTML of the editable div
              onFocus={(event) => {
                resizeHeight(event.target);
                setIsFocused(true);
                focusHandler(event);
              }}
              onBlur={(event) => {
                if (isTextHyphens) {
                  let newText = '';
                  const result = getTextAndEffects(innerRef.current, isTextHyphens);
                  if (isAutoHyphens) {
                    newText = autoHyphens(
                      sanitizeHtml(event.target.innerHTML, {
                        allowedTags: [],
                        allowedAttributes: {}
                      }),
                      value,
                      zoomPreview
                    );
                    setText({ ...result, text: newText });
                  } else {
                    setText({ ...result, text: result.textHyphens });
                  }
                }
                resizeHeight(event.target);
                if (blurHandler) blurHandler();
                setIsFocused(false);
              }}
              onKeyDown={(event) => {
                const key = event.key;
                if (
                  key === 'Meta' &&
                  !event.altKey &&
                  !event.ctrlKey &&
                  (isFinite(parseInt(key)) || key.length === 1)
                ) {
                  breakTagsAndInsertText(key);
                }

                event.target.style.height = 'inherit';
                event.target.style.height = `${event.target.scrollHeight}px`;

                onKeyDown?.();
                if (event.key === 'Enter') {
                  event.preventDefault();
                  document.execCommand('insertLineBreak');
                } else if (event.key === 'Enter' && event.shiftKey) {
                  document.execCommand('insertLineBreak');
                  event.preventDefault();
                }
              }}
              onChange={handleChange}
            />
          )}
        </div>
        {children}
      </div>
    );
  }
);

function EditButton(props) {
  return (
    <button
      className={props.className}
      style={props.style}
      {...(props.cmd === 'foreColor' &&
        props.arg && {
          style: {
            ...props.style,
            background: props.arg
          }
        })}
      key={props.cmd}
      onMouseDown={(evt) => {
        evt.preventDefault(); // Avoids loosing focus from the editable area
        document.execCommand(props.cmd, false, props.arg); // Send the command to the browser
        const selection = window.getSelection();
        selection.removeAllRanges();
      }}>
      {props.children}
    </button>
  );
}
