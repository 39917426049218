export const breakTagsAndInsertText = (key) => {
  let html = '';
  try {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const temp = document.createElement('div');
    const insertion = document.createDocumentFragment();

    const parent = getSelectionParentElement();

    if (parent.localName === 'font') {
      // html = `</font>${key}<font color=${parent.color}>`;
      html = `<font color="#4d4d4d" id="temp-font">${key}</font>`;
    }

    temp.innerHTML = html;

    while (temp.firstChild) {
      insertion.appendChild(temp.firstChild);
    }

    range.deleteContents();
    range.insertNode(insertion);
    selectText('temp-font');
    document.execCommand('removeFormat', false, 'foreColor');
  } catch (error) {
    try {
      document.selection.createRange().pasteHTML(html);
    } catch (error) {}
  }
};

export const getTextAndEffects = (parent, isHyphensText) => {
  let text = '';
  let effects = {};
  const nodesArray = [...parent.childNodes];
  // счетчик вручную выставленных переносов
  let counter = 0;

  let totalLength = 0;
  nodesArray.forEach((el) => {
    const text = el.textContent || el.outerText;

    if (text) {
      // добавление вручную выставленных переносов,
      // для коректного отображения  выделенного текста в AE
      counter += text.split('\n').length - 1;
    }

    if (el.color) {
      effects[Object.keys(effects).length] = {
        type: 'color',
        value: el.color,
        textOffset: totalLength,
        textLength: text.length,
        counter
      };
    }

    totalLength += text?.length || 0;
  });

  const textArray = nodesArray.map((el) => {
    const text = el.textContent || el.outerText;
    return text;
  });

  text = textArray.join('');

  if (isHyphensText) {
    return {
      textHyphens: text,
      effects: new Array(...[...Object.values(effects)])
    };
  } else {
    return {
      text,
      effects: new Array(...[...Object.values(effects)])
    };
  }
};

export const getFormattedHtml = (text) => {
  let isTextHyphens = text?.textHyphens !== undefined;
  if (!text?.effects?.length) {
    return isTextHyphens ? text.textHyphens : text?.text ?? text;
  }
  let result = '';
  let effectsToApply = [...text.effects];
  let sourceText = text.textHyphens ? text.textHyphens : text.text;
  for (let i = 0; i < sourceText.length; i++) {
    const char = sourceText[i];
    if (!effectsToApply.length) {
      result += char;
    } else {
      const length = effectsToApply[0]?.textLength;
      const offset = effectsToApply[0]?.textOffset;
      const type = effectsToApply[0]?.type;
      const value = effectsToApply[0]?.value;

      if (i < offset) result += char;
      else if (i === offset) result += `<font ${type}="${value}">${char}`;
      else if (i > offset && i < offset + length) result += char;
      else if (i === offset + length) {
        result += `</font>`;
        effectsToApply.shift();
        const offset = effectsToApply[0]?.textOffset;
        const type = effectsToApply[0]?.type;
        const value = effectsToApply[0]?.value;
        if (i === offset) result += `<font ${type}="${value}">${char}`;
        else result += char;
      }

      if (i === offset + length) {
        if (offset + length === sourceText.length) result += '</font>';
      }
    }
  }
  return result;
};

export function selectText(nodeId) {
  const node = document.getElementById(nodeId);

  if (!node) return node;

  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    console.warn('Could not select text in node: Unsupported browser.');
  }

  return node;
}

export function getSelectionParentElement() {
  var parentEl = null,
    sel;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.rangeCount) {
      parentEl = sel.getRangeAt(0).commonAncestorContainer;
      if (parentEl.nodeType != 1) {
        parentEl = parentEl.parentNode;
      }
    }
  } else if ((sel = document.selection) && sel.type != 'Control') {
    parentEl = sel.createRange().parentElement();
  }
  return parentEl;
}

export const autoHyphens = (str, value = {}, zoomPreview) => {
  if (!str) {
    return '';
  }

  const { fontFamily, textWidth, fontSize, fontWeight, isUpperCase, fontStyle } = value;

  let myStyle = `
  font-weight: ${fontWeight};
  font-family: ${fontFamily}, sans-serif;
  width: ${textWidth * zoomPreview}px;
  font-size: ${fontSize * zoomPreview}px;
  text-transform: ${isUpperCase ? 'uppercase' : 'none'};
  font-style: ${fontStyle || 'normal'};
  visibility: hidden;
  border-right: 0.5px dashed #D0D0D0;
  padding-right: 2px
`;
  // последние два правила как и у ContentEditable в TextEditor

  let heightPrev = 0;
  let heightCurrent = 0;
  let posHyphens = 0; // позиция ближайшего места переноса
  let charHyphens = ''; // символ ближайшего места переноса (пробел, дефис и т.д.)
  let hyphensCount = 0; // кол-во автоматически вставленных переносов строк, для корректной позиции ближайшего места переноса
  let prevChar = ''; // предыдущий символ, если \n - то ненужно вставлять автоматический перенос при изменении высоты

  let resultText = '';

  let div = document.createElement('div');
  div.style.cssText = myStyle;
  document.body.appendChild(div);

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    div.innerText = str.slice(0, i + 1);

    // TODO: сделать регуляркой, для всех типов переносов
    if (char === '-' || char === ' ') {
      posHyphens = i + hyphensCount;
      charHyphens = char;
    }

    if (i === 0) {
      heightPrev = div.offsetHeight;
    }

    heightCurrent = div.offsetHeight;

    // если подрят идет перенос строки и пробел, то высота div не увеличивается,
    // чтобы лишний перенос не вставлялся перед буквой
    if (str[i - 2] === '\n' && prevChar === ' ') {
      resultText = `${resultText}${char}`;
    } else if (heightPrev < heightCurrent && prevChar !== '\n') {
      let posEnd = posHyphens + 1;
      let posStart = posHyphens + 1;

      resultText = `${resultText.slice(0, posEnd)}\n${resultText.slice(
        posStart,
        resultText.length + 1
      )}${char}`;

      hyphensCount++;
    } else {
      resultText = `${resultText}${char}`;
    }

    heightPrev = heightCurrent;
    prevChar = char;

    if (i === str.length - 1) {
      div.remove();
    }
  }
  return resultText;
};
