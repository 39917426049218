import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';

export const useBroadcastMessages = ({ broadcastId }) => {
  const { setFavoriteState, fetchBroadcastMessages, fetchBroadcastMessagesSuccess } = useActions();
  const { messages, lastExportedMessages } = useSelector((state) => state.broadcast);
  const [loadingMessages, setLoadingMessages] = useState(false);

  const toggleFavorite = useCallback(
    (id, value) => setFavoriteState(id, value),
    [setFavoriteState]
  );

  useEffect(() => {
    async function fetchMessages() {
      setLoadingMessages(true);
      await fetchBroadcastMessages(broadcastId);
      setLoadingMessages(false);
    }

    fetchMessages();

    return () => {
      fetchBroadcastMessagesSuccess({ messages: [] });
    };
  }, [broadcastId, fetchBroadcastMessages, fetchBroadcastMessagesSuccess]);

  return {
    messages,
    lastExportedMessages,
    toggleFavorite,
    loadingMessages
  };
};
