import {
  ADD_EXPORT_CONFIG,
  ADD_MESSAGES_TO_PINNED_AUTHOR,
  DELETE_EXPORT_CONFIG,
  REMOVE_MESSAGE_FROM_PINNED_AUTHOR,
  SET_AUTHORS,
  SET_AVATAR_FORM,
  SET_BROADCAST_CONFIG,
  SET_CURRENT_TAB,
  SET_FEEDBACK_ACTIONS,
  SET_MESSAGE_ACTIONS_CONFIG,
  SET_MESSAGE_FEED_CONFIG,
  SET_MESSAGE_VIEW_CONFIG,
  SET_PINNED_INTERACTIVES,
  SET_PINNED_MESSAGES_AS_READ,
  SET_PROFILE_INTERACTIVE,
  SET_SETTINGS,
  SET_SETTINGS_ARRAY,
  SET_TOTAL_NOTE_COUNT,
  TOGGLE_PINNED_AUTHOR,
  UPDATE_APP_SETTINGS,
  UPDATE_BROADCAST_CONFIG,
  UPDATE_EXPORT_CONFIG,
  UPDATE_FEEDBACK_ACTIONS,
  UPDATE_MESSAGE_ACTIONS_CONFIG,
  UPDATE_MESSAGE_FEED_CONFIG,
  UPDATE_MESSAGE_VIEW_CONFIG,
  UPDATE_SETTINGS_ARRAY
} from './actionTypes';

export function setSettingsAC(settings) {
  return {
    type: SET_SETTINGS,
    payload: settings
  };
}

export function updateAppSettingsAC(field, value) {
  return {
    type: UPDATE_APP_SETTINGS,
    field,
    value
  };
}

export function updateSettingsArrayAC(field, value) {
  return {
    type: UPDATE_SETTINGS_ARRAY,
    field,
    value
  };
}

export function updateMessageActionsConfigAC(field, value) {
  return {
    type: UPDATE_MESSAGE_ACTIONS_CONFIG,
    field,
    value
  };
}

export function updateMessageViewConfigAC(field, value) {
  return {
    type: UPDATE_MESSAGE_VIEW_CONFIG,
    field,
    value
  };
}

export function updateMessageFeedConfigAC(field, value) {
  return {
    type: UPDATE_MESSAGE_FEED_CONFIG,
    field,
    value
  };
}

export function updateBroadcastConfigAC(field, value) {
  return {
    type: UPDATE_BROADCAST_CONFIG,
    field,
    value
  };
}

export function updateFeedbackActionsConfigAC(field, value) {
  return {
    type: UPDATE_FEEDBACK_ACTIONS,
    field,
    value
  };
}

export function setSettingsArrayAC(settingsArray) {
  return {
    type: SET_SETTINGS_ARRAY,
    settingsArray
  };
}

export function setMessageActionsConfigAC(messageActionsConfig) {
  return {
    type: SET_MESSAGE_ACTIONS_CONFIG,
    messageActionsConfig
  };
}

export function setMessageViewConfigAC(messageViewConfig) {
  return {
    type: SET_MESSAGE_VIEW_CONFIG,
    messageViewConfig
  };
}

export function setMessageFeedConfigAC(messageFeedConfig) {
  return {
    type: SET_MESSAGE_FEED_CONFIG,
    messageFeedConfig
  };
}

export function setBroadcastConfigAC(broadcastConfig) {
  return {
    type: SET_BROADCAST_CONFIG,
    broadcastConfig
  };
}

export function setFeedbackActionsConfigAC(feedbackActionsConfig) {
  return {
    type: SET_FEEDBACK_ACTIONS,
    feedbackActionsConfig
  };
}

export function setProfileInteractiveAC(profileInteractive) {
  return {
    type: SET_PROFILE_INTERACTIVE,
    profileInteractive
  };
}

export function setTotalNoteCountAC(count) {
  return {
    type: SET_TOTAL_NOTE_COUNT,
    count
  };
}

export function addExportConfigAC(type, newValue) {
  return {
    type: ADD_EXPORT_CONFIG,
    payload: { type, newValue }
  };
}

export function deleteExportConfigAC(type, index) {
  return {
    type: DELETE_EXPORT_CONFIG,
    payload: { type, index }
  };
}

export function updateExportConfigAC(type, index, value) {
  return {
    type: UPDATE_EXPORT_CONFIG,
    payload: {
      type,
      index,
      value
    }
  };
}

export function togglePinnedAuthor(message, messageFeed) {
  return {
    type: TOGGLE_PINNED_AUTHOR,
    message,
    messageFeed
  };
}

export function setPinnedMessagesAsRead(senderNumber, lastReadMessageId) {
  return {
    type: SET_PINNED_MESSAGES_AS_READ,
    senderNumber,
    lastReadMessageId
  };
}

export function setAuthors(authors, updatedFromSocket = false) {
  return {
    type: SET_AUTHORS,
    authors,
    updatedFromSocket
  };
}

export function setAvatarFormAC(avatarForm) {
  return {
    type: SET_AVATAR_FORM,
    avatarForm
  };
}
export function setPinnedInteractivesAC(pinnedInteractives) {
  return {
    type: SET_PINNED_INTERACTIVES,
    pinnedInteractives
  };
}
