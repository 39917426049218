import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';

export const isValdidPhone = (phone) => {
  const countries = ['RU', 'TJ', 'AE', 'BY', 'MD'];
  const phoneNumber = parsePhoneNumberFromString(`+${phone}`);
  return phoneNumber?.number && countries.includes(phoneNumber.country) ? phoneNumber.number : null;
};
export const parse = (phone = '') => {
  phone = phone.toString();
  let authorValue = phone.trim();
  let newAuthor = '';

  if (authorValue !== '') {
    newAuthor = authorValue;

    if (authorValue[0] !== '+') {
      newAuthor = `+${newAuthor}`;
    }

    const phoneNumber = parsePhoneNumberFromString(newAuthor);
    if (phoneNumber.number) {
      newAuthor = AsYouType().input(phoneNumber.number);
    } else {
      newAuthor = authorValue;
    }
  }

  return newAuthor;
};
