import React, { forwardRef, memo } from 'react';

import { PictureLoaderContainer } from 'containers';

import { Textarea } from 'components';

import { PICTURE_LOADER_TYPES } from 'store/constants';

import style from './VoteQuestionView.module.css';

export const VoteQuestionView = memo(
  forwardRef(
    (
      {
        title,
        titleChangeHandler,
        changeSessionInfo,
        pictureChangeHandler,
        deletePicture,
        question_img_url,
        isLoading
      },
      ref
    ) => {
      return (
        <div ref={ref} className={style.questionWrapper}>
          <div className={style.imageAndQuestionBlock}>
            <PictureLoaderContainer
              type={PICTURE_LOADER_TYPES.showModal}
              style={{
                width: 60,
                height: 66,
                marginRight: 10,
                marginTop: 5,
                backgroundPosition: '50% 50%'
              }}
              changeHandler={pictureChangeHandler}
              deleteHandler={deletePicture}
              picture={question_img_url}
              isImageUploading={isLoading}
              name="QuestionImg"
            />
            <Textarea
              text={title}
              changeHandler={(event) => titleChangeHandler(event.target.value)}
              blurHandler={(event) => changeSessionInfo('title', event.target.value)}
              label={window.t('titleLabel')}
              className={style.question}
              focusable={false}
              name="Question"
            />
          </div>
        </div>
      );
    }
  )
);
