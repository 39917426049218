import classNames from 'classnames';
import React, { useState } from 'react';

import loader from 'assets/images/icons/loader.gif';

import styles from './MediaImg.module.css';

function MediaImg({ image, className, openMedia }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [maxWidth, setMaxWidth] = useState(146);

  return (
    <>
      <div
        className={classNames(styles['media-content'], className)}
        style={{
          maxWidth,
          height: 146
        }}>
        {!isLoaded && <img src={loader} className={styles.loader} alt="" height={146} />}
        <img
          style={{ height: !isLoaded ? 0 : 146 }}
          src={image.url}
          onClick={() => openMedia(image.url)}
          onLoad={(evt) => {
            const maxWidth = (146 * evt.target.naturalWidth) / evt.target.naturalHeight;
            setMaxWidth(maxWidth);
            setIsLoaded(true);
          }}
          alt=""
        />
      </div>
    </>
  );
}

export default MediaImg;
