import FeedbackInput from '../List/FeedbackInput/FeedbackInput';
import BroadcastActions from './BroadcastActions/BroadcastActions';
import Content from './Content/Content';
import DateTag from './DateTag/DateTag';
import FeedBroadcastAction from './FeedBroadcastActions/FeedBroadcastActions';
import FeedbackActions from './FeedbackActions/FeedbackActions';
import Media from './Media/Media';
import MessageActions from './MessageActions/MessageActions';
import ResponseMessage from './ResponseMessage/ResponseMessage';
import animateScrollTo from 'animated-scroll-to';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Icon } from 'components';
import Avatar from 'components/Avatar/Avatar';

import useActions from 'hooks/useActions';
import useLocalStorage from 'hooks/useLocalStorage';

import { getUIDateFromJSDate, getUITimeFromJSDate } from 'helpers/dateTime';
import { getExternalLink } from 'helpers/externalLinks';
import { hidePhoneNumber } from 'helpers/hidePhoneNumber';
import { getImageByChannel } from 'helpers/image';
import { goTo } from 'helpers/routing';
import { getFormattedContent } from 'helpers/text';

import styles from './Message.module.css';

const propTypes = {
  withFeedback: PropTypes.bool,
  data: PropTypes.object.isRequired,
  order: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isOrderActive: PropTypes.bool,
  isBroadcastMsg: PropTypes.bool.isRequired,
  style: PropTypes.object,
  isDraggable: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  selectMessage: PropTypes.func.isRequired,
  messageActionsConfig: PropTypes.object.isRequired,
  settingsMenu: PropTypes.element,
  activeBroadcastData: PropTypes.object,
  canPin: PropTypes.bool
};

const defaultProps = {
  withFeedback: true,
  data: {},
  isBroadcastMsg: false,
  style: {},
  isDraggable: false,
  isSelected: false,
  selectMessage: () => {},
  messageActionsConfig: {}
};

const inputToggleDuration = 700;

function Message({
  canHide = true,
  type,
  data,
  style,
  order,
  isSelected,
  isDraggable,
  withFeedback,
  quickReplies,
  settingsMenu,
  isOrderActive,
  selectMessage,
  toggleFavorite,
  isBroadcastMsg,
  activeBroadcastData,
  messageActionsConfig,
  scrollBoxRef,
  isAuthorPinned,
  isLast,
  userInformation,
  togglePinnedAuthor,
  canPin,
  control,
  isPinnedMessage,
  tab,
  isCheckedPost = true,
  countCharsOneLine
}) {
  const { setDraggingFeedItemId, readMessage } = useActions();

  const history = useHistory();
  const location = useLocation();
  const [_, setProfileMessage] = useLocalStorage('profileMessage', null);

  const { avatarForm } = useSelector((state) => state.settings);

  const messageRef = useRef(null);
  const actionsRef = useRef(null);
  const [isMsgHover, setIsMsgHover] = useState(false);
  const [isFeedbackActive, setIsFeedbackActive] = useState(false);

  const onAnimationStart = useCallback(
    ({ newHeight }) => {
      let rect = messageRef.current.getBoundingClientRect();
      if (rect.top < 0) return;
      if (!(rect.top >= 0 && rect.bottom + newHeight <= window.innerHeight)) {
        if (isLast) {
          scrollBoxRef.current.style.paddingBottom = `${newHeight}px`;
        }
        animateScrollTo([null, scrollBoxRef.current.scrollTop + newHeight], {
          elementToScroll: scrollBoxRef.current,
          minDuration: inputToggleDuration,
          maxDuration: inputToggleDuration
        }).then(() => {
          if (isLast) {
            scrollBoxRef.current.style.paddingBottom = 0;
          }
        });
      }
    },
    [scrollBoxRef]
  );

  const {
    id,
    msg_id,
    image,
    channel,
    status,
    isHidden,
    content,
    author,
    senderNumber,
    region,
    date,
    lastDate,
    socialData,
    social_href,
    attachments,
    titr_tag,
    isLike,
    before,
    after,
    labels
  } = data;

  const isFeedbackPossible = useMemo(() => {
    return (
      withFeedback &&
      type === 'messageFeed' &&
      userInformation?.messagers?.some((messager) => {
        const [channelName, isActive] = Object.entries(messager)[0];
        return isActive && channelName === channel;
      })
    );
  }, [withFeedback, userInformation, type, channel]);

  const iconClickHandler = () => {
    // localStorage.setItem('profileMessage', JSON.stringify(data));
    setProfileMessage(data);
    if (isFeedbackPossible) {
      goTo(history, `/chat/${channel}_${senderNumber}`, null, location);
    } else {
      goTo(history, `/profile/${channel}_${senderNumber}`, null, location);
    }
  };

  const dragStart = () => {
    setDraggingFeedItemId(id);
  };

  const dragEnd = () => {
    setDraggingFeedItemId(null);
  };

  const isMinimized = status && status.includes('minimized');
  const classes = classNames(styles.msg, styles[`chan-${channel?.trim()}`], {
    [styles.selected]: isSelected,
    // [styles.minimized]: isMinimized,
    hidden: isHidden || !isCheckedPost
  });

  const handleReadMessage = useCallback(() => {
    readMessage(senderNumber, id);
  }, [senderNumber, id, readMessage]);

  const formattedContent = isBroadcastMsg
    ? content?.toString()
    : getFormattedContent(content?.toString());
  const externalLink = getExternalLink(author, senderNumber, channel);
  const formattedDate =
    isBroadcastMsg || isPinnedMessage ? getUIDateFromJSDate(date) : getUITimeFromJSDate(date);

  return (
    <AnimateHeight duration={600} height={!isHidden && isMinimized ? 48 : 'auto'}>
      <div
        ref={messageRef}
        draggable={isDraggable}
        style={style}
        onMouseOver={() => {
          if (isMsgHover === false && !isBroadcastMsg) {
            setIsMsgHover(true);
          }
        }}
        onMouseLeave={() => {
          !isBroadcastMsg && setIsMsgHover(false);
        }}
        onDragStart={dragStart}
        onDragEnd={dragEnd}
        className={classes}
        onClick={() => {
          selectMessage(id);
        }}
        data-message="true"
        data-id={id}>
        <div className="d-flex flex-column">
          <div className={styles['icon-wrapper']}>
            <Avatar
              src={getImageByChannel(channel === 'rssNews' ? 'rss' : channel, image)}
              size={isMinimized ? 30 : 36}
              className={styles['icon']}
              clickHandler={iconClickHandler}
              rounded={avatarForm ? avatarForm === 'circle' : true}
            />
          </div>
          {order && (
            <div
              className={classNames(styles['order'], {
                [styles['order--active']]: isOrderActive
              })}>
              {order}
            </div>
          )}
          <div
            className={classNames(styles.dateTime, {
              [styles['info-minimized']]: isMinimized
            })}>
            {formattedDate}
          </div>
        </div>
        <div className={styles['msg-body']}>
          {/* {
                    labels && labels.length > 0 && <Markers
                        labels={labels}
                    />
                } */}
          <div className={styles['msg-header']}>
            <div className={styles['msg-user-info']}>
              <div className={classNames('d-flex align-baseline', styles.contactInfo)}>
                <div
                  onClick={iconClickHandler}
                  className={classNames(styles.author, {
                    [styles['info-minimized']]: isMinimized
                  })}>
                  {externalLink.name}
                </div>
                {canPin && (
                  <Button
                    title={window.t(isAuthorPinned ? 'detach' : 'attach')}
                    onlyIcon
                    startIcon={
                      <Icon
                        size={16}
                        style={{
                          transform: isAuthorPinned ? 'rotateZ(45deg)' : ''
                        }}
                        fontName="fad fa-thumbtack"
                      />
                    }
                    variant="icon"
                    color={isAuthorPinned ? 'primary' : isMsgHover ? '#ccc' : 'transparent'}
                    clickHandler={togglePinnedAuthor}
                  />
                )}
                <div
                  className={classNames(styles.region, {
                    hidden: isHidden
                  })}>
                  {region === '0' ? '' : region}
                </div>
              </div>
              {socialData && (
                <a
                  href={social_href}
                  target="_blank"
                  className={styles['social-data']}
                  rel="noreferrer">
                  {socialData}
                </a>
              )}
              {['whatsapp', 'viber', 'sms', 'smsmoney', 'smsMoney', 'smsrub', 'smsRub'].includes(
                channel
              ) && <p className={styles['social-data']}>{hidePhoneNumber(senderNumber)}</p>}
            </div>
            <div className={styles['msg-controls']}>
              <MessageActions
                type={tab}
                canHide={canHide}
                message={data}
                control={control}
                isMinimized={Boolean(+isMinimized)}
                isMsgHover={isMsgHover || isBroadcastMsg}
                messageActionsConfig={messageActionsConfig}
                settingsMenu={settingsMenu}
                toggleFavorite={toggleFavorite}
              />
              {(type === 'messageFeed' || type === 'broadcast') && (
                <DateTag isLast={isLast} date={lastDate} clickHandler={handleReadMessage} />
              )}
              {isBroadcastMsg && (
                <BroadcastActions
                  id={id}
                  messageFeedId={msg_id}
                  contentLength={formattedContent.length}
                  titr_tag={titr_tag}
                />
              )}
            </div>
            {!isBroadcastMsg && (
              <div className={styles['msg-actions']}>
                <FeedBroadcastAction messageData={data} />
              </div>
            )}
          </div>
          {isFeedbackPossible &&
            !!before?.filter((el) => el.message_type === 'message')?.length && (
              <div className={styles['responses']}>
                {before?.map(({ id, isNew, out_message, attachment }) => (
                  <Fragment key={id}>
                    {(out_message || attachment?.length > 0) && (
                      <ResponseMessage style={{ opacity: 0.7 }} isNew={isNew} channel={channel}>
                        {out_message} {attachment?.length > 0 && <i className="fas fa-file" />}
                      </ResponseMessage>
                    )}
                  </Fragment>
                ))}
              </div>
            )}
          <div className={styles['msg-content']}>
            <Content
              isHidden={isMinimized}
              isBroadcastMsg={isBroadcastMsg}
              content={formattedContent}
              id={id}
              countCharsOneLine={countCharsOneLine}
            />
          </div>
          {attachments && attachments.length !== 0 && (
            <Media
              tab={tab}
              type={type}
              isHidden={Boolean(+isMinimized)}
              id={id}
              attachments={attachments}
              messageInfo={data}
              isBroadcastMsg={isBroadcastMsg}
            />
          )}
          {isFeedbackPossible && !!after?.filter((el) => el.message_type === 'message')?.length && (
            <div className={styles['responses']}>
              {after?.map(({ id, isNew, out_message, attachment }) => (
                <Fragment key={id}>
                  {(out_message || attachment?.length > 0) && (
                    <ResponseMessage style={{ opacity: 0.7 }} isNew={isNew} channel={channel}>
                      {out_message} {attachment?.length > 0 && <i className="fas fa-file" />}
                    </ResponseMessage>
                  )}
                </Fragment>
              ))}
            </div>
          )}
          {isFeedbackPossible && (
            <FeedbackInput
              onAnimationStart={onAnimationStart}
              duration={inputToggleDuration}
              id={id}
              senderNumber={senderNumber}
              channel={channel}
              quickReplies={quickReplies}
              isActive={isFeedbackActive}
              setIsActive={setIsFeedbackActive}
            />
          )}
        </div>
        {(isFeedbackPossible || !!labels?.length) && (
          <div className={styles['msg-footer']}>
            <div className="d-flex align-center">
              {labels?.map(
                (label) =>
                  label && (
                    <span key={label} className={`${styles['hashtag']} mr-2`}>
                      #{label}
                    </span>
                  )
              )}
            </div>
            <div className="ml-auto">
              <FeedbackActions
                actionsRef={actionsRef}
                id={id}
                senderNumber={senderNumber}
                channel={channel}
                isActive={isFeedbackActive}
                setIsActive={setIsFeedbackActive}
                likeText={isLike}
              />
            </div>
          </div>
        )}
      </div>
    </AnimateHeight>
  );
}

Message.propTypes = propTypes;
Message.defaultProps = defaultProps;

export default React.memo(Message, (prev, next) => {
  // return true;
  const boolean =
    prev.isAuthorPinned === next.isAuthorPinned &&
    prev.userInformation === next.userInformation &&
    prev.quickReplies === next.quickReplies &&
    prev.withFeedback === next.withFeedback &&
    prev.locale === next.locale &&
    prev.activeBroadcastData === next.activeBroadcastData &&
    prev.data === next.data &&
    prev.isDraggable === next.isDraggable &&
    prev.isSelected === next.isSelected &&
    prev.messageActionsConfig === next.messageActionsConfig &&
    prev.selectMessage === next.selectMessage &&
    prev.toggleFavorite === next.toggleFavorite &&
    prev.type === next.type &&
    prev.data.content === next.data.content &&
    prev.isCheckedPost === next.isCheckedPost;
  return boolean;
});
