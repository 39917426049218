export const objectFromRules = (rules) => {
  const object = {};
  rules?.forEach((rule) => {
    const ruleObject = {
      value: '',
      isOptional: rule['isOptional'],
      isValid: true,
      'type#': rule['type#'],
      'max_length#': rule['max_length#'],
      order: rule['order'],
      ...(rule['label'] && { label: rule['label'] })
    };

    if (rule['name#'] === 'text_to_speech') {
      ruleObject.emotion = '';
      ruleObject.speed = rule['speed'] || '1';
      ruleObject.voice = 'filipp';
      ruleObject.noPlayerSpeed = rule['noPlayerSpeed'] !== undefined ? rule['noPlayerSpeed'] : true;
    }

    if (rule['type#'] === 'select') {
      ruleObject.options = rule['options'];
    }

    if (rule['type#'] === 'video') {
      ruleObject.maxSizeInMB = rule['maxSizeInMB'];
    }

    if (rule['type#'] === 'checkCorrectElement') {
      ruleObject.checkDate = '';
      ruleObject.isCheck = false;
    }

    if (rule['type#'] === 'audio') {
      ruleObject.value = rule['value'];
    }

    if (rule['type#'] === 'stringWithPreviewElement') {
      ruleObject.fontFamily = rule['fontFamily'];
      ruleObject.fontStyle = rule['fontStyle'];
      ruleObject.fontSize = rule['fontSize'];
      ruleObject.fontWeight = rule['fontWeight'];
      ruleObject.textWidth = rule['textWidth'];
      ruleObject.isUpperCase = rule['isUpperCase'];
      if (rule['durationAnimate'] !== undefined) {
        ruleObject.durationAnimate = rule['durationAnimate'];
      }
      if (rule['maxCountLines']) {
        ruleObject.maxCountLines = rule['maxCountLines'];
      }
      ruleObject.isAutoHyphens = rule['isAutoHyphens'] !== undefined ? rule['isAutoHyphens'] : true;
      ruleObject.value = { textHyphens: '', text: '' };
      ruleObject.text_effects = rule['text_effects'] !== undefined ? rule['text_effects'] : [];
    }

    object[rule['name#']] = ruleObject;
  });

  return object;
};

export const orderedFields = (fields) => {
  const res = Object.entries(fields).sort(function ([keyA, valueA], [keyB, valueB]) {
    const orderA = valueA?.order ? +valueA.order : 0;
    const orderB = valueB?.order ? +valueB.order : 0;
    return orderA > orderB ? 1 : orderA < orderB ? -1 : 0;
  });

  return res;
};

export const formatTreeSelectData = (arr, preKey = '') => {
  const res = [];

  arr.forEach((item, index) => {
    const newKey = preKey ? `${preKey}-${index}` : `${index}`;
    if (!item.children) {
      res.push({ ...item, key: newKey });
    } else {
      res.push({
        ...item,
        key: newKey,
        isLeaf: false,
        children: formatTreeSelectData(item.children, `${index}`)
      });
    }
  });

  return res;
};

export const isLeaf = (value, options) => {
  if (!value) {
    return false;
  }
  let queues = [...options];
  while (queues.length) {
    // BFS
    const item = queues.shift();
    if (item.value === value) {
      if (!item.children) {
        return true;
      }
      return false;
    }
    if (item.children) {
      queues = queues.concat(item.children);
    }
  }
  return false;
};
