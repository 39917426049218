import { BroadcastMessagesView } from './BroadcastMessagesView';
import { messageActionsConfig, messageStyle } from './constants';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import { useLocalStorage } from 'hooks';

import { useBroadcastMessages } from './hooks';

const propTypes = {
  isBroadcastStateFixed: PropTypes.bool,
  broadcastId: PropTypes.string
};

export const BroadcastMessagesContainer = memo(
  ({ isBroadcastStateFixed, broadcastId, children }) => {
    const { messages, lastExportedMessages, toggleFavorite, loadingMessages } =
      useBroadcastMessages({
        broadcastId
      });

    const [favoriteMsg, setFavoriteMsg] = useLocalStorage('favoriteMsg', []);

    const status = useMemo(() => {
      return favoriteMsg.find((el) => el.id === broadcastId && el.type === 'broadcast');
    }, [favoriteMsg]);

    return (
      <BroadcastMessagesView
        messages={messages}
        isBroadcastStateFixed={isBroadcastStateFixed}
        messageStyle={messageStyle}
        messageActionsConfig={messageActionsConfig}
        lastExportedMessages={lastExportedMessages}
        toggleFavorite={toggleFavorite}
        status={status}
        loadingMessages={loadingMessages}>
        {children}
      </BroadcastMessagesView>
    );
  }
);

BroadcastMessagesContainer.propTypes = propTypes;
