import React, { memo, useCallback, useMemo, useRef } from 'react';

import { Message, RowVirtualizerDynamic } from 'components';

import { getFormattedContent } from 'helpers/text';

import { BroadcastSettingsMenu } from './components/BroadcastSettingsMenu';

export const BroadcastMessagesView = memo(
  ({
    messages,
    isBroadcastStateFixed,
    messageStyle,
    messageActionsConfig,
    lastExportedMessages,
    toggleFavorite,
    status,
    children,
    loadingMessages
  }) => {
    const parentRef = useRef(null);

    const dayBeforeYesterday = Date.now() - 2 * 24 * 60 * 60 * 1000;

    const data = useMemo(() => {
      return [...messages]
        .reverse()
        .filter((msg) => (status && status.isFavorite ? [true, '1'].includes(msg.favorite) : true));
    }, [messages, status?.isFavorite]);

    const countCharsOneLine = useMemo(() => {
      if (parentRef?.current) {
        // widthTextMessages = widthContainer - scrollWidth - paddingX - avatarWidth - paddingLeftText - paddingXText
        const widthTextMessages =
          parentRef?.current.getBoundingClientRect().width - 16 - 20 - 40 - 10 - 14;
        // 119 - кол-во символов в одной строке при ширине текста карточки сообщения в 904px
        return Math.round((widthTextMessages / 904) * 119);
      }
      return 0;
    }, [parentRef?.current]);

    const attachmentCount = useCallback(
      (atch, type) => atch?.reduce((acc, item) => acc + !!item?.type?.includes(type), 0),
      []
    );

    const virtualOptions = useMemo(
      () => ({
        estimateSize: (index) => {
          const imageAttCount = attachmentCount(data[index]?.attachments) || 0;
          const audioAttCount = attachmentCount(data[index]?.attachments) || 0;
          const videoAttCount = attachmentCount(data[index]?.attachments) || 0;

          const isMedia = imageAttCount || audioAttCount || videoAttCount;

          let lineCount = getFormattedContent(data[index]?.content)
            ?.split('\n')
            ?.reduce((acc, line) => acc + Math.ceil(line.length / countCharsOneLine), 0);

          const oneLineHeight = lineCount >= 3 ? 19.333 : 22;
          const mediaOffset = isMedia ? 10.5 + 1 + 7 : 0;
          const bottomTextOffset =
            isMedia || lineCount > 1
              ? 0
              : new Date(data[index].date || data[index].pubDate).getTime() > dayBeforeYesterday
              ? 0
              : 10.76;
          const moreTextHeight = lineCount >= 3 ? 26.09 : 0;

          lineCount = lineCount >= 3 ? 3 : lineCount;

          const contentHeight =
            25.8 + // name
            7 + // marginTop
            14 + // paddingY
            Number((oneLineHeight * (lineCount || 1)).toFixed(2)) +
            bottomTextOffset +
            moreTextHeight;

          let result =
            contentHeight +
            mediaOffset +
            198 * videoAttCount +
            (146 + 10) * imageAttCount +
            42.09 * audioAttCount +
            20 + // paddingX
            1; // height hr
          return result;
        }
      }),
      [data]
    );

    return (
      <RowVirtualizerDynamic
        loadingMessages={loadingMessages}
        parentRef={parentRef}
        data={data}
        virtualOptions={virtualOptions}
        renderRow={(el) => {
          return (
            <>
              <Message
                countCharsOneLine={countCharsOneLine || 119}
                type="broadcast"
                data={el}
                isSelected={false}
                messageActionsConfig={messageActionsConfig}
                style={messageStyle}
                isBroadcastMsg
                lastExportedMessages={lastExportedMessages}
                settingsMenu={<BroadcastSettingsMenu messageId={el.id} />}
                toggleFavorite={toggleFavorite}
              />
              <hr
                style={{
                  height: 1,
                  background: '#ccc'
                }}
              />
            </>
          );
        }}>
        {children}
      </RowVirtualizerDynamic>
    );
  }
);
