import { GET, POST, requestApi } from './apiCore';

const VALUE_PATTERN = /\[.:\w+\]/g;
const PARAM_PATTERN_NAME = /:\w+/g;

export async function requestApiGet(
  routeID,
  getParams = {},
  signal,
  successCallback,
  errorCallback
) {
  return requestApi({
    method: GET,
    signal,
    url: await getRoute(routeID),
    getParams,
    successCallback,
    errorCallback
  });
}

export async function requestApiPost(
  routeID,
  postBody,
  getParams = {},
  signal,
  fileKey,
  successCallback,
  errorCallback
) {
  return requestApi({
    fileKey,
    method: POST,
    signal,
    url: await getRoute(routeID),
    getParams,
    postBody,
    successCallback,
    errorCallback
  });
}

async function loadRoutes() {
  return {
    api_test: 'http://f0665380.xsph.ru/',
    api_get_doman_info: '/api/domain/info?domain=[*:domain]',
    api_get_token: '/api/getToken',

    // upload
    api_upload_audio: '/api/upload/audio',
    api_upload_video: '/api/upload/video',

    // feed
    api_get_feed: '/dbController.php',
    // message feed
    api_set_feed_message_favorite_state: '/dbController.php',
    api_set_feed_message_minimized_state: '/dbController.php',
    api_toggle_feed_message_label: '/dbController.php',
    api_clear_feed_message_labels: '/dbController.php',
    api_delete_feed_message: '/api/message/delete',
    api_recovery_feed_message: '/api/message/recovery',
    api_edit_feed_message: '/api/message/edit',
    api_export_message: '/api/export/message',

    // search statistics
    api_get_search_statistics: '/api/export/statistic',

    // template messages
    api_get_templates: '/api/message/qanswers',
    api_delete_template: 'message/qanswers/delete',
    api_add_template: 'message/qanswers/create',
    api_update_template: 'message/qanswers/update',

    // interactives
    api_get_interactives: '/api/iactives',
    api_get_autoreply: '/api/iactive/autoreply?id=[*:id]',
    api_get_broadcast: '/api/iactive/broadcast?id=[*:id]',
    api_get_vote: '/api/iactive/vote?id=[*:id]',
    api_get_registration: '/api/iactive/registration?id=[*:id]',
    api_add_interactive: '/api/iactives/create',
    api_old_add_interactive: '/iactivesDBcontroller.php',
    api_delete_interactive: '/api/iactives/delete',
    api_old_delete_interactive: '/iactivesDBcontroller.php',
    api_update_interactive_title: '/api/iactives/edit',
    api_copy_interactive: '/api/iactives/copy',

    // autoreply
    api_start_autoreply: '/api/autoreply/start',
    api_stop_autoreply: '/api/autoreply/stop',
    api_update_autoreply: '/api/autoreply/edit',

    // vote
    api_start_vote: '/api/vote/start',
    api_stop_vote: '/api/vote/stop',
    api_update_vote: '/api/vote/edit',
    api_get_vote_variants: '/api/vote/getVars?id=[*:id]',
    api_add_vote_variant: '/api/vote/vars/create',
    api_delete_vote_variant: '/api/vote/vars/delete',
    api_update_vote_variant: '/api/vote/vars/update',
    api_upload_vote_image: '/api/vote/vars/loadimg',
    api_delete_vote_image: '/api/vote/vars/deleteimg',
    api_clear_vote_stats: '/api/vote/cancel',
    api_addvote_to_variant: '/api/vote/addvote',
    api_edit_range_numbers: '/api/iactives/editRangeNumbers',
    api_get_winner: '/api/iactives/getWinner',
    api_set_vote_result: '/api/vote/setResult',
    api_get_vote_range: '/api/vote/getRange?id=[*:id]',
    api_get_vote_winners: '/api/iactives/getWinner',
    api_get_social_polls: '/api/getSocialPolls',
    api_bind_social_poll: '/api/bindPoll',
    api_unbind_social_poll: '/api/unbindPoll',
    api_refresh_social_var: '/api/refreshVarCount',
    api_create_poll: '/api/createPoll',
    api_get_vk_votes: '/api/getVkVotes',
    api_get_ok_votes: '/api/getOkVotes',
    api_add_ok_poll: '/api/addOkPoll',

    // broadcast
    api_get_broadcast_messages: '/api/broadcasts/messages?id=[*:id]',
    api_get_broadcast_new_messages: '/api/broadcasts/messages?id=[*:id]&lastMsgId=[*:lastMsgId]',
    api_update_broadcast: '/api/broadcasts/edit',
    api_add_broadcast_message: '/api/broadcasts/messages/add',
    api_delete_broadcast_message: '/api/broadcasts/messages/delete',
    api_delete_all_broadcast_messages: '/api/broadcasts/messages/delete/all',
    api_update_broadcast_message: '/api/broadcasts/messages/edit',
    api_restore_broadcast_message: '/api/broadcasts/messages/restore',
    api_toggle_broadcast_message_favorite_state: '/api/broadcasts/messages/favorite',
    api_get_broadcast_message_info: '/api/broadcasts/messages/get?id=[*:id]',
    api_add_message_to_titre: '/api/broadcasts/titre/add',
    api_delete_message_from_titre: '/api/broadcasts/titre/remove',
    api_clear_titre: '/api/broadcasts/titre/clear',
    api_start_broadcast: '/api/broadcasts/start',
    api_stop_broadcast: '/api/broadcasts/stop',
    api_get_active_broadcast: '/api/broadcasts/active',
    api_upload_excel: 'http://wh781.iactive.pro/api/getter/excel',

    // pulse
    api_get_pulse_data: '/pulseController.php',
    api_get_pulse_chart_data: '/pulse.php',
    api_update_pulse_data: '/iactivesDBcontroller.php',

    // quiz
    api_get_quiz_data: '/pulseController.php',
    api_update_quiz: '/iactivesDBcontroller.php',
    api_update_quiz_data: '/iactivesDBcontroller.php',
    api_add_quiz_message: '/iactivesDBcontroller.php',

    // chat
    api_send_chat_message: '/api/chat/message/send',

    // messenger
    api_send_message_to_messenger: '/api/messager/send',
    api_get_chat_messages: '/api/messager/chat?authorId=[*:id]',

    // content
    api_get_content_news: '/api/news?id=[*:id]',
    api_get_slots: '/api/content/slots',
    api_edit_content: '/api/content/edit',
    api_set_content_slot: '/api/content/set/slot',
    api_set_content_type: '/api/content/set/type',
    api_publish_content: '/api/content/publish',
    api_add_content_attachment: '/api/content/attachment/add',
    api_synthesis_speech: '/api/speech/synthesis',
    api_speech_convert: '/api/speech/convert',
    api_video_delete: '/api/content/video/delete',
    api_get_background_content_audios: '/api/content/bgaudio',
    api_get_audio_catalog: '/api/catalog/audio',

    // registration
    api_update_registration: '/api/registration/edit',
    api_start_registration: '/api/registration/start',
    api_stop_registration: '/api/registration/stop',
    api_get_registration_cards: '/api/registration/cards?id=[*:id]',
    api_delete_registration_card: '/api/registration/card/delete',
    api_get_registration_oneCard: '/api/registration/card/get?id=[*:id]',
    api_edit_author_card: '/api/registration/card/edit',
    api_upload_registration_image: '/api/registration/attachment/add',
    api_delete_registration_image: '/api/registration/attachment/delete',

    // profile card
    api_get_profile_user_info: '/authorCard/',
    api_get_profile_media: '/authorCard/',
    api_get_profile_messages: '/authorCard/',
    api_ban_profile: '/dbController.php',
    api_unban_profile: '/dbController.php',
    api_get_profile_banned: '/dbController.php',

    // instagram
    api_get_instagram_connected_users: '/api/instagram/connected',
    api_get_instagram_users: '/api/instagram/search',
    api_track_instagram_user: '/api/instagram/addToTrack',
    api_untrack_instagram_user: '/api/instagram/addToTrack',

    // vk
    api_get_vk_posts: '/socialController.php',
    api_get_vk_groups: '/api/vk-groups',
    api_delete_vk_group: '/api/deleteVkGroup',

    // youtube
    api_get_youtube_streams: '/socialController.php',
    api_logout_youtube: 'http://googleauth.iactive.pro/youtubeLive/auth/out.php?user=[*:phone]',
    api_get_youtube_groups: '/api/yt-groups',
    api_delete_youtube_group: '/api/deleteYtGroup',

    // ok
    api_get_ok_groups: '/api/ok-groups',
    api_toggle_ok_subscribe: '/api/okToggleGroupSubscribe',

    // viber
    api_get_viber_groups: '/api/viber-groups',
    api_toggle_viber_subscribe: '/api/viberToggleGroupSubscribe',

    // telegram
    api_post_telegram_confirm: '/api/telegram/client/confirm',
    api_get_telegram_clients: '/api/telegram/client/list',
    api_logout_telegram_client: '/api/telegram/client/logout',
    api_connect_telegram_client: '/api/telegram/client/connect',
    api_get_telegram_client_status: '/api/telegram/client/status?connectId=[*:connectId]',
    api_connect_telegram_bot: '/api/telegram/bot/connect',
    api_logout_telegram_bot: '/api/telegram/bot/delete',

    // whatsapp
    api_get_whatsapp_clients: '/api/whatsapp-groups',
    api_post_whatsapp_qr: '/api/whatsapp/connect/apimessager',
    api_get_whatsapp_status: '/api/whatsapp/status/apimessager',
    api_post_whatsapp_logout: '/api/whatsapp/logout/apimessager',

    // facebook
    api_get_fb_groups: '/api/fb-groups',

    // socials
    api_get_socials: '/socialController.php',
    api_get_socials_posts: '/socialController.php',
    api_set_socials_post_checked_state: '/socialController.php',
    api_set_socials_posts_checked_state: '/socialController.php',
    api_add_comments_to_broadcast: '/api/export/broadcast/comments',

    // socials-settings
    api_set_social_settings: '/api/social-settings',

    // profile settings
    api_get_profiles: '/api/profiles',
    api_create_profile: '/api/profile/create',
    api_edit_profile: '/api/profile/edit',
    api_delete_profile: '/api/profile/delete',

    // interactions
    api_interaction_status: '/api/interaction/status',

    // rss
    api_rss_subscribe: '/api/rss/add',
    api_rss_unsubscribe: '/api/rss/unsub',
    api_rss_delete: '/api/rss/delete?rssId=[*:rssId]',
    api_rss_get: '/api/rss',

    // weather
    api_weather_get_by_city: '/api/weather'
  };
}

function getRouteDataFromPattern(pattern) {
  const valueRegexpStrings = pattern.match(VALUE_PATTERN);
  const valuesNames = pattern.match(PARAM_PATTERN_NAME);

  let params = {};

  if (!valueRegexpStrings || !valuesNames) return params;

  valuesNames.forEach((name, i) => {
    params[name.slice(1)] = valueRegexpStrings[i];
  });

  return params;
}

export function generateUrlWithGetParams(pattern, getParams) {
  const patterData = getRouteDataFromPattern(pattern);
  let url = pattern;

  Object.keys(getParams).forEach((paramName) => {
    if (patterData[paramName]) {
      url = url.replace(patterData[paramName], getParams[paramName]);
      delete getParams[paramName];
    }
  });

  const query = serializeParams(getParams);

  return query ? `${url}/${query}` : url;
}

function serializeParams(params, prefix) {
  const result = [];

  Object.keys(params).forEach((key) => {
    const arg = prefix ? `${prefix}[${key}]` : key;

    const v = params[key];
    const isObject = v !== null && typeof v === 'object';
    result.push(
      isObject ? serializeParams(v, arg) : `${encodeURIComponent(arg)}=${encodeURIComponent(v)}`
    );
  });

  return result.join('&');
}

export async function getRoute(routeID) {
  return (await loadRoutes())[routeID];
}
