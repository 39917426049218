import { addSnack } from './snackBox';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { TABS } from 'containers/Feed/constants';

import { MEDIA_TYPES } from 'store/constants';
import { contentActions } from 'store/slices/contentSlice';

import { editContent } from 'api/dataLayer';

import { moveDown, moveUp } from 'helpers/array';
import { generateUUID } from 'helpers/common';
import { objectFromRules } from 'helpers/content';
import { clearText } from 'helpers/text';

const debouncedChangeBlocksRequest = debounce(changeContentRequest, 2000);

export function addMessageToContent(message, type) {
  return (dispatch, getState) => {
    const { blocks } = getState().content;
    const info = getState().isettings.info;
    const { id, content_rule } = info;

    if (blocks.length >= +content_rule['blocksCount#'] && +content_rule['blocksCount#'] !== 0) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errContentMaximusBlocks')
        })
      );
      return;
    }

    // если не выбран слот новостей, то content_rule пустой массив
    if (!content_rule || isEmpty(content_rule)) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('Не выбран слот для новостей!')
        })
      );
      return;
    }

    const contentType = content_rule['content#'] ? Object.keys(content_rule['content#'])[0] : null;

    const block = contentType
      ? objectFromRules(content_rule['content#'][contentType]?.fields)
      : objectFromRules(content_rule.content);
    const { content, attachments, title, description } = message;

    switch (type) {
      case TABS.messages:
        if (content && content?.length > 0) {
          const sentences = content.split('\n');

          if (sentences.length > 1) {
            block.caption.value = sentences[0]?.trim();
            const mainText = sentences.slice(1).join('\n');
            block.text_content.value = mainText?.trim();
            block.text_to_speech.value = mainText?.trim();
          } else {
            if (block.text_content) {
              block.text_content.value = content.trim();
            }
            block.text_to_speech.value = content?.trim();
          }
        }
        break;
      case TABS.news:
        if (block.caption && title && title.length !== 0) {
          block.caption.value = clearText(title);
        }
        if (block.text_content && description && description.length !== 0) {
          block.text_content.value = clearText(description);
        }
        if (block.text && description && description.length !== 0) {
          if (contentType) {
            block.text.value = { text: '', textHyphens: '', effects: [] };
            block.text.value.text = clearText(description);
            block.text.value.textHyphens = clearText(description);
          } else {
            block.text.value = { text: '', textHyphens: '', effects: [] };
            block.text.value.text = clearText(description);
            block.text.value.textHyphens = clearText(description);
          }
        }
        if (block.text_to_speech && description && description.length !== 0) {
          block.text_to_speech.value = clearText(description);
        }
        break;
      default:
        break;
    }

    if (content && content?.length > 0) {
      const sentences = content.split('\n');

      if (sentences.length > 1) {
        block.caption.value = sentences[0];
        const mainText = sentences.slice(1).join('\n');
        block.text_content.value = mainText;
        block.text_to_speech.value = `${sentences[0]}       ${sentences.slice(1).join('\n')}`;
      } else {
        if (block.text_content) {
          block.text_content.value = content;
        }
      }
    }

    if (Array.isArray(attachments) && attachments.length > 0)
      attachments.forEach((media) => {
        const contentField = (
          contentType ? content_rule['content#'][contentType]?.fields : content_rule.content
        ).find((el) => media.type.includes(el['type#']));

        if (contentField) {
          block[contentField['name#']].value =
            media.type === MEDIA_TYPES.audio_msg ? media.ogg : media.url;
          block[contentField['name#']].isExported = true;
        }
      });

    block.id = generateUUID();

    // типы блоков в новостях
    const blockTypes =
      content_rule?.['content#'] &&
      Object.entries(content_rule['content#'])
        .map(([key, { label, canSelect, isAutoCreate }]) => ({
          label,
          canSelect,
          isAutoCreate,
          value: key
        }))
        .filter((type) => type.canSelect);
    // тип текущего созданного блока( первый элемент в content# )
    const currentType = contentType ? blockTypes.find((el) => el.value === contentType) : type;

    dispatch(addContentBlock({ id, block, type: currentType }));
  };
}

export function addContentBlock({ id, block, type }) {
  return async (dispatch, getState) => {
    block.isMinimized = false;
    block.isActive = true;
    block.isTouched = false;
    const blocks = [...getState().content.blocks];

    const newBlocks = [...blocks, { ...block, ...(type && { titreType: type }) }];
    dispatch(contentActions.setContentBlocks({ blocks: newBlocks }));

    try {
      await changeContentRequest(id, newBlocks);
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errContentEditing')
        })
      );
      dispatch(contentActions.setContentBlocks({ blocks }));
    }
  };
}

export function removeContentBlock({ id, blockId }) {
  return async (dispatch, getState) => {
    const blocks = [...getState().content.blocks];
    const newBlocks = blocks.filter((el) => el.id !== blockId);
    dispatch(contentActions.setContentBlocks({ blocks: newBlocks }));

    try {
      await changeContentRequest(id, newBlocks);
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errContentEditing')
        })
      );
      dispatch(contentActions.setContentBlocks({ blocks }));
    }
  };
}

export function moveBlockUp({ id, block }) {
  return async (dispatch, getState) => {
    const blocks = [...getState().content.blocks];
    const newBlocks = moveUp(blocks, block);
    dispatch(contentActions.setContentBlocks({ blocks: newBlocks }));

    try {
      await changeContentRequest(id, newBlocks);
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errContentEditing')
        })
      );
      dispatch(contentActions.setContentBlocks({ blocks }));
    }
  };
}

export function moveBlockDown({ id, block }) {
  return async (dispatch, getState) => {
    const blocks = [...getState().content.blocks];
    const newBlocks = moveDown(blocks, block);
    dispatch(contentActions.setContentBlocks({ blocks: newBlocks }));

    try {
      await changeContentRequest(id, newBlocks);
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errContentEditing')
        })
      );
      dispatch(contentActions.setContentBlocks({ blocks }));
    }
  };
}

export function updateContentBlock(blockId, newValue) {
  return async (dispatch, getState) => {
    const { id } = getState().isettings.info;
    let blocks = [...getState().content.blocks];
    const block = blocks.find((el) => el.id === blockId);

    let oldValue = block;

    blocks = blocks.map((el) => (el.id === blockId ? newValue : el));
    dispatch(contentActions.setContentBlocks({ blocks }));

    try {
      await debouncedChangeBlocksRequest(id, blocks, () => {
        dispatch(
          addSnack({
            type: 'success',
            message: window.t('sccChangesSaved')
          })
        );
      });
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errContentEditiblocksng')
        })
      );

      blocks = blocks.map((el) => (el.id === blockId ? oldValue : el));
      dispatch(contentActions.setContentBlocks({ blocks }));
    }
  };
}

export function updateContentBlockField(blockId, field, value, key = 'value') {
  return async (dispatch, getState) => {
    const { id } = getState().isettings.info;
    const blocks = getState().content.blocks;

    let newBlocks = blocks.map((block) => {
      if (block.id === blockId) {
        if (key) {
          return {
            ...block,
            [field]: {
              ...block[field],
              [key]: value
            }
          };
        }
        return {
          ...block,
          [field]: value
        };
      }
      return block;
    });

    dispatch(contentActions.setContentBlocks({ blocks: newBlocks }));

    try {
      await debouncedChangeBlocksRequest(id, newBlocks, () => {
        dispatch(
          addSnack({
            type: 'success',
            message: window.t('sccChangesSaved')
          })
        );
      });
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errContentEditing')
        })
      );

      dispatch(contentActions.setContentBlocks({ blocks }));
    }
  };
}
export async function changeBlocksRequest(id, blocks, successCallback) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await editContent({
        id,
        content_json: JSON.stringify(blocks)
      });

      if (res.status === 'error') reject();

      if (successCallback) successCallback();

      resolve();
    } catch (ex) {
      reject();
    }
  });
}

export async function changeContentRequest(id, data, successCallback) {
  try {
    const res = await editContent({
      id,
      content_json: JSON.stringify(data)
    });

    if (res.status === 'error') throw new Error(res);

    successCallback?.();
  } catch (ex) {
    throw new Error(ex);
  }
}
