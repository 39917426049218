import * as ChatCreators from './chat';
import * as FeedCreators from './feed.js';
import * as ISettingsCreators from './isettings';
import * as ModalImageCreators from './modalImage';
import * as PinnedAuthorsCreators from './pinnedAuthors';
import * as ProfileCreators from './profile';
import * as ProfileCardCreators from './profileCard';
import * as PulseCreators from './pulse.js';
import * as SessionsCreators from './sessions';
import * as SettingsCreators from './settings';
import * as SnackBoxCreators from './snackBox';

import { autoreplyActions } from 'store/slices/autoreplySlice';
import { bannedActions } from 'store/slices/bannedSlice';
import { broadcastActions } from 'store/slices/broadcastSlice';
import { contentActions } from 'store/slices/contentSlice';
import { messageCreatorActions } from 'store/slices/messageCreatorSlice';
import { messageFiltersActions } from 'store/slices/messageFiltersSlice';
import { notificationActions } from 'store/slices/notificationSlice';
import { pinnedInteractivesActions } from 'store/slices/pinnedInteractivesSlice';
import { registrationActions } from 'store/slices/registrationSlise';
import { resizeActions } from 'store/slices/resizeSlice';
import { scrollBoxActions } from 'store/slices/scrollBoxSlice';
import { searchStatisticsActions } from 'store/slices/searchStatisticsSlice';
import { sessionsFilterActions } from 'store/slices/sessionsFilterSlice';
import { socialsActions } from 'store/slices/socialsSlice';
import { voteActions } from 'store/slices/voteSlice';

export default {
  ...broadcastActions,
  ...autoreplyActions,
  ...bannedActions,
  ...contentActions,
  ...messageCreatorActions,
  ...messageFiltersActions,
  ...scrollBoxActions,
  ...voteActions,
  ...socialsActions,
  ...sessionsFilterActions,
  ...resizeActions,
  ...ChatCreators,
  ...ISettingsCreators,
  ...ModalImageCreators,
  ...ProfileCardCreators,
  ...SessionsCreators,
  ...SettingsCreators,
  ...SnackBoxCreators,
  ...PulseCreators,
  ...ProfileCreators,
  ...FeedCreators,
  ...PinnedAuthorsCreators,
  ...pinnedInteractivesActions,
  ...registrationActions,
  ...notificationActions,
  ...searchStatisticsActions
};
