import { fetchBroadcastMessages } from './broadcast';
import { addSnack } from './snackBox';

import { fetchFeedItemsSuccess } from 'store/actions/feed';
import { socialsActions } from 'store/slices/socialsSlice';

import {
  addCommentsToBroadcastApi,
  getSocialsPosts,
  logoutYoutube,
  setSocialsPostCheckedState,
  setSocialsPostsCheckedState
} from 'api/dataLayer';

export function resetSocialsState() {
  return (dispatch) => {
    dispatch(socialsActions.fetchPostsSuccess({ posts: [] }));
    dispatch(socialsActions.setSocialsSearchText({ searchText: '' }));
    dispatch(socialsActions.setAuthState({ isAuthenticated: true }));
  };
}

export function setSocials(socials) {
  return async (dispatch) => {
    let res;
    try {
      // res = await getSocials();
      const filtered = socials?.map((el, i, arr) => {
        el.isActive =
          (i === 0 && el.channel !== 'yt_live') || (i === 1 && arr[i - 1].channel === 'yt_live');
        el.channel =
          el.channel === 'vkcomments'
            ? 'vkcomm'
            : el.channel === 'tgcomments'
            ? 'tgcomm'
            : el.channel === 'okcomments'
            ? 'okcomm'
            : el.channel;
        el.title = el.channel;

        return el;
      }); // .filter(el => el.channel !== 'yt_live');
      dispatch(socialsActions.fetchSocialsSuccess({ socials: filtered }));

      const socialsNames =
        filtered.filter((el) => el.channel !== 'yt_live').map((el) => el.channel) || [];
      socialsNames.forEach((channel) =>
        dispatch(fetchPosts(channel === 'facebookcomments' ? 'fbcomm' : channel))
      );
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errLoadingListSocialNetworks')
        })
      );
    }
  };
}

export function setPosts(posts) {
  return async (dispatch) => {
    try {
      if (!posts || posts.length === 0) {
        return;
      }
      if (typeof posts === 'object' && Array.isArray(posts))
        posts = posts
          .map((post) => {
            post.checked = Boolean(+post.checked);
            return post;
          })
          .reverse();

      let channel = posts[0]?.channel;
      dispatch(socialsActions.fetchPostsSuccess({ posts, channel }));
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errLoadingPosts')
        })
      );
    }
  };
}

export function fetchPosts(channel) {
  return async (dispatch) => {
    try {
      const res = await getSocialsPosts(channel);
      if (res === 'not_auth') {
        return;
      }
      dispatch(setPosts(res.Posts));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function logoutYtube() {
  return async (dispatch) => {
    try {
      await logoutYoutube();
      dispatch(socialsActions.setAuthState({ isAuthenticated: false }));
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errLogoutYoutube')
        })
      );
    }
  };
}

export function makeChannelActive(channel) {
  return async (dispatch) => {
    try {
      dispatch(socialsActions.makeChannelActiveSuccess({ channel }));
      // dispatch(fetchPosts(channel));
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errRequestingServer')
        })
      );
    }
  };
}

export function togglePost(id, channel, checked, href) {
  return async (dispatch, getState) => {
    const feed = getState().feed;
    const tabs = feed.tabs || [];
    const messages = feed['messages']?.items || [];
    try {
      await setSocialsPostCheckedState({ id, channel, isChecked: +checked });
      dispatch(socialsActions.togglePostSuccess({ id, checked, channel }));

      if (tabs.includes('messages')) {
        const filteredMessages = messages.map((msg) => {
          if (msg.social_href === href) {
            msg.isCheckedPost = checked;
          }
          return msg;
        });

        dispatch(fetchFeedItemsSuccess(filteredMessages, 'messages'));
      }
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errRequestingServer')
        })
      );
    }
  };
}

export function toggleAllPosts(isChecked, activeTab) {
  return async (dispatch, getState) => {
    const feed = getState().feed;
    const tabs = feed.tabs || [];
    const messages = feed['messages']?.items || [];
    try {
      const socialsState = getState().socials;

      const ids = socialsState.posts
        .filter((post) => post.channel === activeTab)
        .map((el) => el.id);

      await setSocialsPostsCheckedState({
        channel: activeTab,
        isChecked,
        ids
      });

      if (tabs.includes('messages')) {
        const filteredMessages = messages.map((msg) => {
          if (msg.channel === activeTab) {
            msg.isCheckedPost = isChecked;
          }
          return msg;
        });
        dispatch(fetchFeedItemsSuccess(filteredMessages, 'messages'));
      }
      dispatch(
        socialsActions.toggleAllPostsSuccess({
          checked: isChecked,
          channel: activeTab
        })
      );
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errRequestingServer')
        })
      );
    }
  };
}

export function filterBySearchText(searchText) {
  return (dispatch, getState) => {
    dispatch(socialsActions.setSocialsSearchText({ searchText }));
    const posts = getState().socials.posts;
    const regPhrase = new RegExp(searchText, 'i');
    const filtered = posts.filter((el) => regPhrase.test(el.content));
    dispatch(socialsActions.setFilteredPosts({ posts: filtered }));
  };
}

export function exportCommentsToBroadcast(mainId, channel, broadcastId, setAdditionComments) {
  return async (dispatch) => {
    try {
      const res = await addCommentsToBroadcastApi({ mainId, channel, broadcastId });
      const body = res?.body;
      await dispatch(fetchBroadcastMessages(broadcastId));
      setAdditionComments((prev) => prev?.filter((el) => el !== mainId));
      dispatch(
        addSnack({
          type: body?.messageCount ? 'success' : 'info',
          message: body?.messageCount
            ? `${window.t('sccCommentsAddedToBroadcast')} - ${body?.messageCount} ${window.t(
                'piecesShort'
              )}`
            : window.t('infoNoCommentsOnThisPost')
        })
      );
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errAddingCommentsToBroadcast')
        })
      );
    }
  };
}
